import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import instance from "../api/api";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/20/solid";

import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AddDomain({
  open,
  setOpen,
  isSend,
  setIsSend,
  i18n,
  active,
}) {
  const cancelButtonRef = useRef(null);
  const [isError, setIsError] = useState(false);
  const [handleError, setHandleError] = useState("");
  const [newOject, setNewObject] = useState({
    url: "",
  });

  const options1 = {
    method: "GET",
    url: "https://domain-checker7.p.rapidapi.com/whois",
    params: {
      domain: newOject.url,
    },
    headers: {
      "content-type": "application/octet-stream",
      "X-RapidAPI-Key": "7adf0de432msh2e262c681675117p183c42jsn0218ac6773fe",
      "X-RapidAPI-Host": "domain-checker7.p.rapidapi.com",
    },
  };

  const options2 = {
    method: "GET",
    url: "https://domain-checker7.p.rapidapi.com/dns",
    params: {
      domain: newOject.url,
    },
    headers: {
      "content-type": "application/octet-stream",
      "X-RapidAPI-Key": "7adf0de432msh2e262c681675117p183c42jsn0218ac6773fe",
      "X-RapidAPI-Host": "domain-checker7.p.rapidapi.com",
    },
  };

  const [dataDomain, setDataDomain] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getDataDomain = async () => {
    setIsSearch(true);
    try {
      const response1 = await axios.request(options1);
      const response2 = await axios.request(options2);
      setDataDomain({ whois: response1.data, dns: response2.data });
      console.log("whois: ", response1.data, "dns: ", response2.data);
      setIsSearch(false);
      setIsLoading(true);
    } catch (error) {
      setIsSearch(false);
      setHandleError(error.message);
    }
  };

  //   useEffect(() => {
  //     getDataDomain();
  //   }, []);

  const handleFields = (e) => {
    // console.log("jj :", i18n.dir());
    setHandleError("");
    setNewObject({ ...newOject, [e.target.name]: e.target.value });
  };

  const [people, setPeople] = useState([
    { domainName: "Amira Alexander", purchasePrice: 22.22 },
  ]);

  //   const searchFunc = async () => {
  //     setIsLoading(true);
  //     try {
  //       const d = await instance.post("/push/search", {
  //         search: newOject.url,
  //       });
  //       console.log("data: ", d.data.results);
  //       setPeople(d.data.results);
  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(false);
  //       console.log("error: ", error.message);
  //     }
  //   };

  const isValidUrl = (urlString) => {
    var a = document.createElement("a");
    a.href = urlString;
    return a.host && a.host != window.location.host;
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setIsLoading(false);
          setOpen(false);
          setNewObject({ url: "" });
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-3000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="my-20 flex min-h-full items-start justify-center p-3 text-center sm:items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              //   enter="ease-out duration-300"
              //   enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              //   enterTo="opacity-100 translate-y-0 sm:scale-100"
              //   leave="ease-in duration-200"
              //   leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              //   leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter="transform ease-out duration-500 transition"
              enterFrom="translate-y-0 opacity-100 sm:translate-x-0"
              enterTo="opacity-100 translate-y-2 translate-x-0"
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-0 pb-4 pt-0 shadow-xl transition-all ltr:text-left rtl:text-right sm:my-8  sm:max-w-2xl sm:p-6">
                <div>
                  <div className="mt-0 text-center sm:mt-2">
                    <div className="mb-4 mt-0 w-full text-right">
                      <div>
                        <div className="flex w-full flex-row justify-between bg-green-200 sm:mx-auto sm:rounded-md">
                          <p
                            htmlFor="url"
                            className="block px-2 py-2 text-sm font-medium  leading-6 text-gray-600"
                          >
                            ادخل اسم النطاق الخاص فيك
                          </p>
                          <XMarkIcon
                            onClick={() => {
                              setIsLoading(false);
                              setOpen(false);
                              setNewObject({ url: "" });
                            }}
                            className=" mx-4 mt-2.5 h-5 w-5 text-gray-600"
                            aria-hidden="true"
                          />
                        </div>

                        {active === false && (
                          <div className="my-3 border-yellow-400 bg-yellow-50 p-4 ltr:border-l-4 rtl:border-r-4">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <ExclamationTriangleIcon
                                  className="h-5 w-5 text-yellow-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="md:text-md flex w-full flex-row justify-between text-xs ltr:ml-3 rtl:mr-3">
                                <p className=" text-yellow-700">
                                  هذه الخدمة متوفرة على باقة الإنطلاقة
                                </p>
                                <a
                                  href="/pricing"
                                  className="font-medium text-yellow-700 underline outline-none hover:text-yellow-600"
                                >
                                  ترقية الباقة
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="mt-2 flex flex-row gap-2 px-1 sm:px-0">
                          <button
                            disabled={
                              isValidUrl(newOject.url) === false ||
                              newOject.url.length < 12 ||
                              active === false
                            }
                            onClick={getDataDomain}
                            className={classNames(
                              isValidUrl(newOject.url) &&
                                newOject.url.length > 12 &&
                                active === true
                                ? "bg-blue-500 hover:bg-blue-700"
                                : "bg-gray-500",
                              "  h-9 w-20 rounded-md p-1 text-sm text-white",
                            )}

                            // isValidUrl(newOject.url) && newOject.url.length > 16
                          >
                            {isSearch ? (
                              <div className="flex w-full">
                                <svg
                                  aria-hidden="true"
                                  role="status"
                                  className="inline h-5 w-full animate-spin text-white "
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#E5E7EB"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </div>
                            ) : (
                              "بحث"
                            )}
                          </button>

                          <div className="relative w-full  rounded-md ">
                            <input
                              id="url"
                              name="url"
                              type="text"
                              value={newOject.url}
                              onChange={(e) => handleFields(e)}
                              autoComplete="current-url"
                              placeholder="https://domain.com"
                              required
                              className="block h-9 w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-3 text-sm text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 rtl:text-left sm:text-sm"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <MagnifyingGlassIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {handleError?.length > 0 && (
                        <span className="mt-5 flex items-center  text-xs font-medium tracking-wide text-red-500 ltr:ml-1 rtl:mr-1 ">
                          {handleError}
                        </span>
                      )}
                      {isLoading ? (
                        <div className="px-2">
                          <p className="mt-5 text-xs text-red-500">
                            ملاحظة: تواصل مع المزود للنطاق الذي تملكه وقم بتغيير
                            بيانات الخادم{" "}
                            <a href="/" className=" text-blue-600 underline">
                              إذا واجهتك مشكلة تواصل معنا
                            </a>
                          </p>
                          <p className="mt-3 text-xs text-gray-500">المزود:</p>
                          <p className="mt-2 w-40 rounded-sm  bg-gray-200 text-xs text-gray-400 ltr:text-left rtl:text-right">
                            {dataDomain?.whois.registrar}
                          </p>
                          <p className="text-xs text-gray-500">
                            تاريخ انتهاء الصلاحية:
                          </p>
                          <p className="mt-2 w-40 rounded-sm  bg-gray-200 text-xs text-gray-400 ltr:text-left rtl:text-right">
                            {new Date(
                              dataDomain?.whois.expires_at,
                            ).toLocaleDateString()}
                          </p>
                          <p className="mt-2 text-xs text-gray-500">
                            خوادم الأسماء الحالية:
                          </p>
                          {dataDomain?.dns.NS.map((one) => (
                            <p className="mt-2 w-40 rounded-sm  bg-gray-200 text-xs text-gray-400 ltr:text-left rtl:text-right">
                              {one}
                            </p>
                          ))}
                          <p className="mt-2 text-xs text-gray-500">
                            خوادم الأسماء المطلوبة :
                          </p>
                          <p className="mt-2 w-40 rounded-sm  bg-gray-200 text-xs text-gray-400 ltr:text-left rtl:text-right">
                            ns1.dns-parking.com
                          </p>
                          <p className="mt-2 w-40 rounded-sm  bg-gray-200 text-xs text-gray-400 ltr:text-left rtl:text-right">
                            ns2.dns-parking.com
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
