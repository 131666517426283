import React, { createContext, useState } from "react";
// import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import instance, { useAxios } from "../api/api";
import axios from "axios";
import {
  ArchiveBoxArrowDownIcon,
  ClipboardIcon,
  FolderIcon,
} from "@heroicons/react/20/solid";
// import { useNavigate } from "react-router-dom";

export const Context = createContext({});
export const ContextProvider = ({ children }) => {
  const [cartOrdersId, setCartOrdersId] = useState("");
  const publishingOptions = [
    {
      id: 0,
      title: "منتج شحن",
      description: "أي منتج قابل للشحن",
      current: true,
      icon: (
        <ArchiveBoxArrowDownIcon
          className="h-5 w-5 text-gray-500"
          aria-hidden="true"
        />
      ),
    },
    {
      id: 1,
      title: "منتج رقمي",
      description: "مثل الملفات والكتب الإلكترونية والدورات",
      current: false,
      icon: <FolderIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />,
    },
    {
      id: 2,
      title: "بطاقة رقمية",
      description: "مثل حسابات للبيع وبطاقة شحن",
      current: false,
      icon: (
        <ClipboardIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
      ),
    },
  ];

  const [roomId, setRoomId] = useState("");
  const [displayNameUser, setDisplayNameUser] = useState("Customer");
  // let navigate = useNavigate();

  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [isAdmin, setIsAdmin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDream, setIsShowDream] = useState(true);
  const [comments, setComments] = useState([]);
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [user, setUser] = useState(null);
  const [items, setItems] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [expoTokenAdmin, setExpoTokenAdmin] = useState([]);
  const [newList, setNewList] = React.useState([]);
  const [token, setToken] = React.useState(null);
  const [isActive, setIsActive] = React.useState(false);
  const [show, setShow] = useState(false);
  // const [loading, setLoading] = React.useState(true);
  const [company, setCompany] = React.useState(null);
  const [select, setSelect] = useState(null);
  const [selectedBranded, setSelectedBranded] = useState(0);

  const [selected, setSelected] = useState(publishingOptions[0]);

  const [values, setValues] = React.useState({
    country: "",
    email: "",
    // jwt_decode(localStorage.getItem("Token")).user === undefined
    //     ? ""
    //     : jwt_decode(localStorage.getItem("Token")).user.email,
    displayName: "",
    // jwt_decode(localStorage.getItem("Token")).displayName === undefined
    //   ? ""
    //   : jwt_decode(localStorage.getItem("Token")).user.displayName,
    phone: "",
    // jwt_decode(localStorage.getItem("Token")).phone === undefined
    //   ? ""
    //   : jwt_decode(localStorage.getItem("Token")).user.phone,
    city: "",
    // jwt_decode(localStorage.getItem("Token")).city === undefined
    //   ? ""
    //   : jwt_decode(localStorage.getItem("Token")).user.city,
    address: "",
    // jwt_decode(localStorage.getItem("Token")).address === undefined
    //   ? ""
    //   : jwt_decode(localStorage.getItem("Token")).user.address,
    neighborhood: "",
    // jwt_decode(localStorage.getItem("Token")).neighborhood === undefined
    //   ? ""
    //   : jwt_decode(localStorage.getItem("Token")).user.neighborhood,
    kindPaymentField: "",
  });

  // let navigate = useNavigate();

  //   const allCategories = [
  //   "all",
  //   ...new Set(items.map((item) => item.category)),
  // ];

  // const allCategories = [
  //   "all",
  //   "جديدنا",
  //   "الأكثر مبيعا",
  //   "عروض بسعر اقل من 99 ريال",
  //   "عطور نيش فاخرة",
  //   "عطور الشعر والجسم",
  //   "عروض مميزة",
  //   "عطور نسائية",
  //   "عطور رجالية",
  //   "عطور للجنسين",
  // ];
  const [categories, setCategories] = useState([]);

  const [cartItmes, setCartItems] = React.useState([]);

  const createOrder = async () => {
    try {
      await instance
        .post(`/orders/create`, {
          orders: [],
        })
        .then(async ({ data }) => {
          // console.log("d: ",data);
          localStorage.setItem("OrderId", data._id);
          // try {
          //   await axios
          //     .post(`https://fatora-sa.herokuapp.com/api/v1/carts/creat-web`, {
          //       email: jwt_decode(localStorage.getItem("TokenNAEM")).user.email,
          //       displayName: jwt_decode(localStorage.getItem("TokenNAEM")).user
          //         .displayName,
          //       phone:
          //         jwt_decode(localStorage.getItem("TokenNAEM")).user.phone
          //           .length === 0
          //           ? "0"
          //           : jwt_decode(localStorage.getItem("TokenNAEM")).user.phone,
          //       displayCompany: company.companyName,
          //       orders: data._id,
          //       company_id: company._id,
          //     })
          //     .then(() => {})
          //     .catch((e) => console.log("error: ", e.message));
          //   // .then(() => localStorage.setItem("OrderId", data._id));
          // } catch (error) {
          //   console.log("error: ", error.message);
          // }

          // .catch((e) => console.log("error: ", e.message));
        })
        .catch((e) => console.log("error: ", e.message));
    } catch (error) {
      console.log("error: ", error.message);
    }
  };

  const handleAddProduct = (product) => {
    console.log("OrderId: ", localStorage.getItem("OrderId"));
    localStorage.getItem("OrderId") == null && createOrder();
    // cartItmes.length === 0 && createOrder();
    const ProductExist = cartItmes.find((item) => item._id === product._id);
    // console.log("ProductExist ...: ", ProductExist);
    if (ProductExist) {
      setCartItems(
        cartItmes.map((item) =>
          item._id === product._id
            ? { ...ProductExist, qty: ProductExist.qty + 1 }
            : item,
        ),
      );
      localStorage.setItem(
        "Order",
        JSON.stringify(
          cartItmes.map((item) =>
            item._id === product._id
              ? { ...ProductExist, qty: ProductExist.qty + 1 }
              : item,
          ),
        ),
      );
    } else {
      setCartItems([...cartItmes, { ...product, qty: 1 }]);
      localStorage.setItem(
        "Order",
        JSON.stringify([...cartItmes, { ...product, qty: 1 }]),
      );
    }

    // console.log("jfjafkjasl: ",[...cartItmes, { ...product, qty: 1 }]);
  };

  const handleRemoveProduct = (product) => {
    const ProductExist = cartItmes.find((item) => item._id === product._id);
    // console.log(
    //   "carItems: ",
    //   cartItmes.length === 1 && cartItmes.filter((item) => item.qty === 1)
    // );
    const lastProduct = cartItmes.filter((item) => item.qty === 1);
    if (cartItmes.length === 1 && lastProduct.length > 0) {
      localStorage.setItem("CountCart", 0);
      // navigate(`/home`)
    }

    if (ProductExist.qty === 1) {
      setCartItems(cartItmes.filter((item) => item._id !== product._id));
      localStorage.setItem(
        "Order",
        JSON.stringify(cartItmes.filter((item) => item._id !== product._id)),
      );
    } else {
      setCartItems(
        cartItmes.map((item) =>
          item._id === product._id
            ? { ...ProductExist, qty: ProductExist.qty - 1 }
            : item,
        ),
      );
      localStorage.setItem(
        "Order",
        JSON.stringify(
          cartItmes.map((item) =>
            item._id === product._id
              ? { ...ProductExist, qty: ProductExist.qty - 1 }
              : item,
          ),
        ),
      );
    }
    console.log("remove");
  };

  const totalPrice =
    localStorage.getItem("Order") != null &&
    JSON.parse(localStorage.getItem("Order")).reduce(
      (price, item) => price + item.qty * item.unit,
      0,
    );

  const numberInCart =
    localStorage.getItem("Order") != null &&
    JSON.parse(localStorage.getItem("Order")).reduce(
      (number, item) => number * item,
      0,
    );

  // setCompany(response)

  const value = {
    setOrders,
    items,
    setItems,
    orders,
    isAdmin,
    setIsAdmin,
    name,
    room,
    setName,
    setRoom,
    // users,
    // setUsers,
    roomId,
    setRoomId,
    messages,
    setMessages,
    isTyping,
    setIsTyping,
    comments,
    setComments,
    data,
    setData,
    user,
    setUser,
    isLoading,
    setIsLoading,
    displayNameUser,
    setDisplayNameUser,
    isShowDream,
    setIsShowDream,
    expoTokenAdmin,
    setExpoTokenAdmin,
    categories,
    setCategories,
    newList,
    setNewList,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    numberInCart,
    token,
    setToken,
    isActive,
    setIsActive,
    values,
    setValues,
    company,
    setCompany,
    show,
    setShow,
    cartOrdersId,
    setCartOrdersId,
    select,
    setSelect,
    selectedBranded,
    setSelectedBranded,
    publishingOptions,
    selected,
    setSelected,
    // allCategories
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
