import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HomeIcon } from "@heroicons/react/24/outline";
import instance from "../api/api";
import { XMarkIcon } from "@heroicons/react/20/solid";
import jwt_decode from "jwt-decode";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MerchantAuth({
  t,
  open,
  setOpen,
  isSend,
  setIsSend,
  i18n,
  active,
  response,
}) {
  const cancelButtonRef = useRef(null);

  const { company } = jwt_decode(localStorage.getItem("TokenNAEM"));

  const [newOject, setNewObject] = useState({
    storeManagerName: response != null ? response.storeManagerName : "",
    phone: response != null ? response.phone : "",
    city: response != null ? response.city : "",
    address: response != null ? response.address : "",
    neighborhood: response != null ? response.neighborhood : "",
    zipCode: response != null ? response.zipCode : "",
    email: company.email,
    _id: company._id,
  });

  // const options1 = {
  //   method: "GET",
  //   url: "https://domain-checker7.p.rapidapi.com/whois",
  //   params: {
  //     domain: newOject.url,
  //   },
  //   headers: {
  //     "content-type": "application/octet-stream",
  //     "X-RapidAPI-Key": "7adf0de432msh2e262c681675117p183c42jsn0218ac6773fe",
  //     "X-RapidAPI-Host": "domain-checker7.p.rapidapi.com",
  //   },
  // };

  // const options2 = {
  //   method: "GET",
  //   url: "https://domain-checker7.p.rapidapi.com/dns",
  //   params: {
  //     domain: newOject.url,
  //   },
  //   headers: {
  //     "content-type": "application/octet-stream",
  //     "X-RapidAPI-Key": "7adf0de432msh2e262c681675117p183c42jsn0218ac6773fe",
  //     "X-RapidAPI-Host": "domain-checker7.p.rapidapi.com",
  //   },
  // };

  const [isLoading, setIsLoading] = useState(false);
  // const getDataDomain = async () => {
  //   setIsSearch(true);
  //   try {
  //     const response1 = await axios.request(options1);
  //     const response2 = await axios.request(options2);
  //     setDataDomain({ whois: response1.data, dns: response2.data });
  //     console.log("whois: ", response1.data, "dns: ", response2.data);
  //     setIsSearch(false);
  //     setIsLoading(true);
  //   } catch (error) {
  //     setIsSearch(false);
  //     setHandleError(error.message);
  //   }
  // };

  const handleFields = (e) => {
    setNewObject({ ...newOject, [e.target.name]: e.target.value });
  };

  const addNewAddress = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    // console.log("data: ", newOject);
    // setIsFindAddress(true);

    if (newOject != null) {
      await instance
        .post("/companies/update-company-web", {
          storeManagerName: newOject.storeManagerName,
          phone: newOject.phone,
          city: newOject.city,
          address: newOject.address,
          neighborhood: newOject.neighborhood,
          zipCode: Number(newOject.zipCode),
          email: company.email,
          _id: company._id,
        })
        .then(() => {
          setIsLoading(false);
          window.open("?", "_self");
          // navigate('/profile',)
          // navigate("/profile", { replace: true });
        })
        .catch((error) => console.log("error: ", error.message));
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setIsLoading(false);
          setOpen(false);
          // setNewObject({
          //   storeManagerName: "",
          //   phone: "",
          //   city: "",
          //   address: "",
          //   neighborhood: "",
          //   zipCode: "",
          // });
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-3000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-start my-20 justify-center p-3 text-center sm:items-start ">
            <Transition.Child
              as={Fragment}
              enter="transform ease-out duration-500 transition"
              enterFrom="translate-y-0 opacity-100 sm:translate-x-0"
              enterTo="opacity-100 translate-y-2 translate-x-0"
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-0 pb-4 pt-0 rtl:text-right ltr:text-left shadow-xl transition-all sm:my-8 sm:max-w-2xl  sm:p-6 w-full">
                <div className="ltr:border-l-4 rtl:border-r-4 border-blue-600 bg-blue-50 p-4 my-0">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <HomeIcon
                        className="h-5 w-5 text-blue-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="text-xs md:text-md ltr:ml-3 rtl:mr-3 flex flex-row justify-between w-full">
                      <p className=" text-blue-600">
                        {t("addressInformation")}
                      </p>
                      <XMarkIcon
                        onClick={() => {
                          setIsLoading(false);
                          setOpen(false);
                          // setNewObject({
                          //   storeManagerName: "",
                          //   phone: "",
                          //   city: "",
                          //   address: "",
                          //   neighborhood: "",
                          //   zipCode: "",
                          // });
                        }}
                        className=" h-5 w-5 text-gray-600"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
                <form
                  className="mx-4 sm:mx-0"
                  action="#"
                  method="POST"
                  onSubmit={addNewAddress}
                >
                  <div className="mt-0 text-center sm:mt-2">
                    <div className="mt-0 mb-4 w-full text-right">
                      <div>
                        {/* <div className="my-4">
                          <label
                            htmlFor="address"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            الدولة
                          </label>
                          <div className="mt-2">
                            <input
                              type="address"
                              name="address"
                              id="address"
                              value={newOject.address}
                              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder=""
                              onChange={(e) => handleFields(e)}
                            />
                          </div>
                        </div> */}
                        <div className="my-4">
                          <label
                            htmlFor="city"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            المدينة
                          </label>
                          <div className="mt-2">
                            <input
                              type="city"
                              name="city"
                              id="city"
                              value={newOject.city}
                              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder=""
                              onChange={(e) => handleFields(e)}
                            />
                          </div>
                        </div>
                        <div className="my-4">
                          <label
                            htmlFor="neighborhood"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            الحي
                          </label>
                          <div className="mt-2">
                            <input
                              type="neighborhood"
                              name="neighborhood"
                              id="neighborhood"
                              value={newOject.neighborhood}
                              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder=""
                              onChange={(e) => handleFields(e)}
                            />
                          </div>
                        </div>
                        <div className="my-4">
                          <label
                            htmlFor="address"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            العنوان
                          </label>
                          <div className="mt-2">
                            <input
                              type="address"
                              name="address"
                              id="address"
                              value={newOject.address}
                              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder=""
                              onChange={(e) => handleFields(e)}
                            />
                          </div>
                        </div>
                        <div className="my-4">
                          <label
                            htmlFor="zipCode"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            الرمز البريدي
                          </label>
                          <div className="mt-2">
                            <input
                              type="zipCode"
                              name="zipCode"
                              id="zipCode"
                              value={newOject.zipCode
                                .toString()
                                .replace(/[٠-٩]/g, (d) =>
                                  "٠١٢٣٤٥٦٧٨٩".indexOf(d)
                                )}
                              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder=""
                              onChange={(e) => handleFields(e)}
                            />
                          </div>
                        </div>
                        <div className="my-4">
                          <label
                            htmlFor="storeManagerName"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            اسم المستلم
                          </label>
                          <div className="mt-2">
                            <input
                              type="storeManagerName"
                              name="storeManagerName"
                              id="storeManagerName"
                              value={newOject.storeManagerName}
                              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder=""
                              onChange={(e) => handleFields(e)}
                            />
                          </div>
                        </div>
                        <div className="my-4">
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            رقم الجوال
                          </label>
                          <div className="mt-2">
                            <input
                              type="phone"
                              name="phone"
                              id="phone"
                              value={newOject.phone.replace(/[٠-٩]/g, (d) =>
                                "٠١٢٣٤٥٦٧٨٩".indexOf(d)
                              )}
                              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder=""
                              onChange={(e) => handleFields(e)}
                            />
                          </div>
                        </div>

                        <div className="mt-1 sm:mt-3 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                          <button
                            type="submit"
                            className={classNames(
                              false
                                ? " bg-gray-300 "
                                : " bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600",
                              "inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  ltr:sm:col-start-2 rtl:sm:col-start-1"
                            )}
                            disabled={false}
                          >
                            {isLoading ? (
                              <>
                                <svg
                                  aria-hidden="true"
                                  role="status"
                                  className="inline w-4 h-4 text-white animate-spin rtl:ml-3 ltr:mr-3"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#E5E7EB"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </>
                            ) : response != null ? (
                              "تعديل"
                            ) : (
                              "إضافة"
                            )}
                          </button>

                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ltr:sm:col-start-1 rtl:col-start-2 sm:mt-0"
                            // href="/login"
                            onClick={() => setOpen(false)}
                          >
                            إلغاء
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
