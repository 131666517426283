import { XMarkIcon, CursorArrowRippleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
var isSafari = (navigator.vendor.match(/apple/i) || "").length > 0;

export default function Banner() {
  const [show, setShow] = useState(true);

  return show && isSafari && localStorage.getItem("isSafari") == null ? (
    <div className="flex items-center gap-x-6 bg-indigo-600 px-4 py-2.5 sm:px-3.5  ">
      <p className=" text-xs leading-6 text-white">
        <button
          onClick={() => {
            localStorage.setItem("isSafari", true);
            // setShow(false);
            window.location.replace(
              `https://naem.me/${localStorage.getItem("CN")}`
            );
          }}
        >
          <div className="flex flex-row">
            <strong className="mr-1 ml-1">تنبيه:</strong>
            للدفع عن طريق Apple Pay - اضغط هنا
            <span className="mr-1 ml-1" aria-hidden="true"></span>
            <CursorArrowRippleIcon
              className="h-5 w-5 mt-0.5 text-white"
              aria-hidden="true"
            />
          </div>
        </button>
      </p>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          onClick={() => setShow(false)}
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  ) : null;
}
