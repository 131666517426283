import { PaperClipIcon } from "@heroicons/react/20/solid";
import jwt_decode from "jwt-decode";
import { useEffect, useState, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAxios, useAxiosDublecate } from "../api/api";
import { Context } from "../context/ContextProvider";
import Pagination from "../components/Pagination";
import {
  ArrowUturnRightIcon,
  CheckIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  XMarkIcon as XMarkIconMini,
} from "@heroicons/react/20/solid";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Orders({ t }) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    company,
  } = useContext(Context);
  const navigate = useNavigate();

  // const { response, loading, error } = useAxios({
  //   method: "GET",
  //   url: `/companies/get-company-name/${
  //     localStorage.getItem("CN") !== null && localStorage.getItem("CN")
  //   }`,
  //   headers: {
  //     accept: "*/*",
  //   },
  // });

  // const { data, loading, error } = useAxiosDublecate(
  //   jwt_decode(localStorage.getItem("Token")).user._id,
  //   {
  //     method: "GET",
  //     url: `/companies/get-company-name/${
  //       localStorage.getItem("CN") !== null && localStorage.getItem("CN")
  //     }`,
  //     headers: {
  //       accept: "*/*",
  //     },
  //   },
  //   {
  //     method: "GET",
  //     url: "/invoices/get-casheir-invoices-for-user/",
  //     headers: {
  //       accept: "*/*",
  //     },
  //   }
  // );
  const { state } = useLocation();

  const { response, loading, error } = useAxios({
    method: "GET",
    url: `https://fatora-sa.herokuapp.com/api/v1/invoices/get-casheir-invoices/${
      state != null
        ? state._id
        : jwt_decode(localStorage.getItem("TokenNAEM")).company._id
    }`,
    headers: {
      accept: "*/*",
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(
    window.innerHeight < 700
      ? 5
      : window.innerHeight < 900
        ? 8
        : window.innerHeight < 1000
          ? 10
          : 15,
  );

  // Get current posts
  const indexOfLastPost = currentPage * ordersPerPage;
  const indexOfFirstPost = indexOfLastPost - ordersPerPage;
  const currentOrders = response?.slice(indexOfFirstPost, indexOfLastPost);

  // setTimeout(() => {
  //   console.log("response: ",currentOrders);
  // }, 3000);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const oneFetch = useRef(true);
  useEffect(() => {
    if (oneFetch.current) {
      oneFetch.current = false;
      setTimeout(() => {
        setIsLoading(false);
        // console.log("jj: ",orders);
      }, 1000);
    }
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex w-full items-center justify-center py-20">
          <div className="flex items-center justify-center space-x-1 text-sm text-gray-700 rtl:space-x-reverse">
            <svg
              fill="none"
              className="h-20 w-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : response?.length === 0 ? (
        <div className="container mx-auto mt-2">
          <div className="flex flex-row justify-between rounded bg-gray-200 p-3 px-5 ">
            <h1 className="text-1xl mt-0.5 font-bold tracking-tight text-gray-900 sm:text-xl">
              {t("notFindOrder")}
            </h1>
            <a
              href={
                state != null ? `/merchants` : `/${localStorage.getItem("CN")}`
              }
              className="flex flex-row rounded bg-gray-600 px-2 text-white "
            >
              <span className="text-1xl sm:text-1xl pb-1  pl-3 pr-3 font-bold tracking-tight sm:mt-0.5">
                {t("back")}
              </span>
              <ArrowUturnRightIcon
                className="mt-0.5 h-5 w-5 sm:mt-1.5"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
      ) : (
        <div>
          <div className="mx-4  my-5 flex flex-row">
            {/* <button
            type="button"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="flex  bg-indigo-500 hover:bg-indigo-700 w-10  h-10 rounded-md drop-shadow-lg  justify-center items-center text-white text-4xl hover:drop-shadow-2xl"
            id="btn-back-to-top"
            onClick={() => {
              navigate(-1);
              // scrollTop, clientHeight, scrollHeight
            }}
          >
            <ChevronRightIcon
              className="ltr:rotate-180  h-6 w-6 "
              aria-hidden="true"
            />
          </button> */}
            <p
              htmlFor="url"
              className="text-md mx-5 mt-2 block font-semibold leading-6 text-gray-900 sm:mt-1 sm:text-xl"
            >
              {t("orders")}
            </p>
          </div>
          <div className="m-5 overflow-hidden shadow sm:rounded-lg">
            <div className="mt-5 px-4 sm:px-6 lg:px-8">
              <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="mx-4 inline-block min-w-full py-2 align-middle sm:mx-0 sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300 ">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 text-sm font-semibold text-gray-900 ltr:pl-0 ltr:pr-3 ltr:text-left rtl:pl-3 rtl:pr-0 rtl:text-right sm:pl-0"
                          >
                            {t("itemNo")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-sm font-semibold text-gray-900 ltr:text-left rtl:text-right"
                          >
                            {t("dateOrder")}
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-sm font-semibold text-gray-900 ltr:text-left rtl:text-right"
                          >
                            {t("orderStatus")}
                          </th>
                          {/* <th
                      scope="col"
                      className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th> */}
                          <th
                            scope="col"
                            className="relative py-3.5 ltr:pl-3 ltr:pr-4  rtl:pl-4 rtl:pr-3 sm:pr-0"
                          >
                            <span className="sr-only">{t("details")}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {currentOrders.map((order, key) => (
                          <tr key={key}>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm ltr:pl-0 rtl:pr-0 sm:pl-0">
                              <div className="flex items-center">
                                {/* <div className="h-10 w-10 flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={order.image} alt="" />
                          </div> */}
                                <div className="ltr:ml-0 rtl:mr-0">
                                  <div className="font-medium text-gray-900">
                                    {order.tran_ref}
                                  </div>
                                  {/* <div className="text-gray-500">{order.email}</div> */}
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div className="text-gray-900">
                                {/* {new Date(order.created_at).getDate()}-
                              {new Date(order.created_at).getMonth()}-
                              {new Date(order.created_at).getFullYear()} */}
                                {order?.date}
                              </div>
                              <div className="text-gray-500">
                                {/* {order.create_At} */}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <span
                                className={classNames(
                                  order.isCancel
                                    ? "bg-red-100 text-red-800 "
                                    : order.isShipped &&
                                        order.isRefund === false &&
                                        order.isEndRefund === false
                                      ? "bg-green-100 text-green-800 "
                                      : order.isShipped &&
                                          order.isRefund &&
                                          order.isEndRefund === false
                                        ? "bg-orange-100 text-orange-500"
                                        : order.isEndRefund === true
                                          ? "bg-gray-100 text-gray-500"
                                          : "bg-yellow-100 text-yellow-500",
                                  "inline-flex rounded-full  px-2 text-xs font-semibold  leading-5 ",
                                )}
                              >
                                {order.isCancel
                                  ? `${t("cancelOrder")}`
                                  : order.isShipped &&
                                      order.isRefund === false &&
                                      order.isEndRefund === false
                                    ? `${t("shipped")}`
                                    : order.isShipped &&
                                        order.isRefund &&
                                        order.isEndRefund === false
                                      ? `${t("pending1")}`
                                      : order.isEndRefund === true
                                        ? `${t("refunded")}`
                                        : `${t("pending")}`}
                              </span>
                            </td>
                            <td className="relative whitespace-nowrap py-4 text-sm font-medium ltr:pl-3 ltr:pr-4 ltr:text-left rtl:pl-4 rtl:pr-3 rtl:text-right sm:pr-0">
                              <button
                                onClick={() =>
                                  jwt_decode(localStorage.getItem("TokenNAEM"))
                                    .company?.superAdmin &&
                                  state?.merchant_page === false
                                    ? navigate(
                                        `/order-merchant/${
                                          order.tran_ref
                                          // order.orderId !== undefined
                                          //   ? order.orderId
                                          //   : order.itemNo
                                        }`,
                                        {
                                          state: {
                                            invoice: order,
                                            merchant_page:
                                              state.merchant_page != null
                                                ? state.merchant_page
                                                : false,
                                            //...values
                                          },
                                        },
                                      )
                                    : navigate(
                                        `/order/${
                                          order.tran_ref
                                          // order.orderId !== undefined
                                          //   ? order.orderId
                                          //   : order.itemNo
                                        }`,
                                        {
                                          state: {
                                            invoice: order,
                                            //...values
                                          },
                                        },
                                      )
                                }
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                {t("details")}
                                <span className="sr-only">, {order.name}</span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Pagination
              ordersPerPage={ordersPerPage}
              totalOrders={response?.length}
              paginate={paginate}
              t={t}
            />
          </div>
        </div>
      )}
    </>
  );
}
