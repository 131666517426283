import { useState, useEffect, useContext } from "react";
import "../App.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ProductList from "./ProductList";
import DetailProduct from "./DetailProduct";
import NavDisClosure from "../components/NavDisClosure";
import Footer from "../components/Footer";
import Cart from "./Cart";
import Login from "./Login";
import Profile from "./Profile";
import Register from "./Register";
import Checkout from "./Checkout";
import ResetPassword from "./ResetPassword";
import Order from "./Order";
import Orders from "./Orders";
import VerifyOTP from "./VerifyOTP";
import MerchantId from "./MerchantId";
import EditeProduct from "./EditeProduct";
import TryEditor from "./TryEditor";
import Dashboard from "./Dashboard";
import jwt_decode from "jwt-decode";
import { Context } from "../context/ContextProvider";
import Settings from "./Settings";
import OrderMerchant from "./OrderMerchant";
import SearchDomain from "./SearchDomain";
import Domain from "./Domain";
import OrdersMerchant from "./OrdersMerchant";
import Pricing from "./Priceing";
import CheckPaymentClickPay from "./CheckPaymentClickPay";
import MerchantsList from "./MerchantsList";

function RouterBase() {
  const {
    amountOfItemsInCart,
    newList,
    cartItmes,
    numberInCart,
    company,
    setCompany,
    setOrders,
  } = useContext(Context);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    i18n.changeLanguage("ar");
    document.body.dir = i18n.dir();
  }, []);

  const lang = i18n.dir();
  const changeLanguage = (lng) => {
    if (lng === "rtl") {
      i18n.changeLanguage("en");
      document.body.dir = i18n.dir();
    } else {
      i18n.changeLanguage("ar");
      document.body.dir = i18n.dir();
    }
  };

  // useEffect(() => {
  //   console.log(
  //     "error: ",
  //     localStorage.getItem("TokenNAEM") != null,
  //     jwt_decode(localStorage.getItem("TokenNAEM"))?.company?.companyName ===
  //       localStorage.getItem("CN"),
  //     company?.companyName === localStorage.getItem("CN")
  //   );
  // }, []);

  // const jj = data.company.url.includes("naem.me")
  //   ? localStorage.setItem("CN", data.company.url.split(".me/")[1])
  //   : data.company.url.split(".").length > 2
  //   ? localStorage.setItem("CN", data.company.url.split(".")[1])
  //   : localStorage.setItem("CN", data.company.url.split(".")[0]);

  // localStorage.getItem("TokenNAEM") != null &&
  //   jwt_decode(localStorage.getItem("TokenNAEM"))?.company.url.includes("naem.me") ===
  //     localStorage.getItem("CN");
  //     ||
  // company?.companyName === localStorage.getItem("CN")

  // localStorage.getItem("TokenNAEM") != null &&
  //               jwt_decode(localStorage.getItem("TokenNAEM"))?.company
  //                 ?.companyName === localStorage.getItem("CN")

  const companyData =
    localStorage.getItem("TokenNAEM") != null
      ? jwt_decode(localStorage.getItem("TokenNAEM")).company.url
      : "";

  return (
    <BrowserRouter>
      <NavDisClosure lang={lang} changeLanguage={changeLanguage} t={t} />
      <div className="flex min-h-screen flex-col justify-between">
        <Routes>
          <Route
            // exact
            path={"/"}
            element={
              localStorage.getItem("TokenNAEM") == null ? (
                <Navigate to="/login" />
              ) : (
                <Dashboard t={t} />
              )
            }
          />
          <Route
            // exact
            path={"/:companyName"}
            element={
              (companyData?.includes("naem.me")
                ? companyData.split(".me/")[1] === localStorage.getItem("CN")
                : companyData.split(".").length > 2
                  ? companyData.split(".")[1] === localStorage.getItem("CN")
                  : companyData.split(".")[0] === localStorage.getItem("CN")) ||
              (company?.url.includes("naem.me")
                ? company?.url.split(".me/")[1] === localStorage.getItem("CN")
                : company?.url.split(".").length > 2
                  ? company?.url.split(".")[1] === localStorage.getItem("CN")
                  : company?.url.split(".")[0] ===
                    localStorage.getItem("CN")) ? (
                <ProductList
                  // lang={lang}
                  // open={open}
                  // setOpen={setOpen}
                  // changeLanguage={changeLanguage}
                  t={t}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route exact path="/detail/:_id" element={<DetailProduct t={t} />} />
          <Route
            exact
            path="/cart"
            element={
              localStorage.getItem("TokenNAEM") == null ? (
                <Navigate to="/login" />
              ) : (
                // ) : localStorage.getItem("Order") == null ? (
                //   <Navigate to={`/${localStorage.getItem("CN")}`} />
                <Cart t={t} i18n={i18n} />
              )
            }
          />
          <Route
            exact
            path="/profile"
            element={
              localStorage.getItem("TokenNAEM") == null ? (
                <Navigate to="/login" />
              ) : (
                <Profile t={t} i18n={i18n} />
              )
            }
          />
          <Route path={`/login`} element={<Login t={t} />} />
          <Route path={`/register`} element={<Register t={t} />} />
          <Route
            path={`/checkout`}
            element={
              // localStorage.getItem("Order") != null &&
              // JSON.parse(localStorage.getItem("Order")).length === 0 ? (
              //   <Navigate to={"/" + localStorage.getItem("CN")} />
              // ) :
              <Checkout t={t} />
            }
          />
          {/* <Route path={`/order/:orderId`} element={<Order t={t} />} /> */}
          <Route path={`/order/:tranRef`} element={<Order t={t} />} />

          <Route
            path={`/order-merchant/:tranRef`}
            element={<OrderMerchant t={t} />}
          />
          <Route
            path={`/edite-product`}
            element={
              (companyData.includes("naem.me")
                ? companyData.split(".me/")[1] === localStorage.getItem("CN")
                : companyData.split(".")?.length > 2
                  ? companyData.split(".")[1] === localStorage.getItem("CN")
                  : companyData.split(".")[0] === localStorage.getItem("CN")) ||
              (company?.url.includes("naem.me")
                ? company?.url.split(".me/")[1] === localStorage.getItem("CN")
                : company?.url.split(".")?.length > 2
                  ? company?.url.split(".")[1] === localStorage.getItem("CN")
                  : company?.url.split(".")[0] ===
                    localStorage.getItem("CN")) ? (
                <EditeProduct t={t} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          {/* <Route path={`/orders`} element={<Orders />} /> */}
          <Route path={`/try`} element={<TryEditor />} />
          <Route
            path={`/orders`}
            element={
              localStorage.getItem("TokenNAEM") == null ? (
                <Navigate to="/login" />
              ) : (
                <Orders t={t} />
              )
            }
          />
          <Route
            path={`/orders-merchant`}
            element={
              localStorage.getItem("TokenNAEM") == null ? (
                <Navigate to="/login" />
              ) : (
                <OrdersMerchant t={t} />
              )
            }
          />
          <Route
            path={`/merchants`}
            element={
              localStorage.getItem("TokenNAEM") == null ? (
                <Navigate to="/login" />
              ) : (
                <MerchantsList t={t} />
              )
            }
          />
          {/* <Route path={`/profile`} element={<Profile />} /> */}
          <Route
            path={`/reset-password/:token`}
            element={<ResetPassword t={t} />}
          />
          <Route
            path={`/verify-otp/:code/:phone`}
            element={<VerifyOTP t={t} i18n={i18n} />}
          />
          <Route
            path={`/merchant-id/:phone`}
            element={<MerchantId t={t} i18n={i18n} />}
          />
          <Route
            exact
            path={`/check-payment-card/:respStatus/:tranRef`}
            element={<CheckPaymentClickPay t={t} />}
          />
          <Route
            path={`/settings`}
            element={
              localStorage.getItem("TokenNAEM") == null ? (
                <Navigate to="/login" />
              ) : (
                <Settings t={t} i18n={i18n} />
              )
            }
          />
          <Route
            path={`/search-domain`}
            element={<SearchDomain t={t} i18n={i18n} />}
          />
          <Route path={`/domain`} element={<Domain t={t} i18n={i18n} />} />
          <Route path={`/pricing`} element={<Pricing t={t} i18n={i18n} />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default RouterBase;
