import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function AlertShare({
  alertShare,
  setAlertShare,
  i18n,
  shareLink,
}) {
  const cancelButtonRef = useRef(null);

  const projects = [
    {
      name: "FacebookIcon",
      icon: (
        <FacebookShareButton url={shareLink}>
          <FacebookIcon size={40} className="rounded" />
        </FacebookShareButton>
      ),
    },
    {
      name: "EmailIcon",
      icon: (
        <EmailShareButton url={shareLink}>
          <EmailIcon size={40} className="rounded" />
        </EmailShareButton>
      ),
    },
    {
      name: "TwitterIcon",
      icon: (
        <TwitterShareButton url={shareLink}>
          <TwitterIcon size={40} className="rounded" />
        </TwitterShareButton>
      ),
    },
    {
      name: "WhatsappIcon",
      icon: (
        <WhatsappShareButton url={shareLink}>
          <WhatsappIcon size={40} className="rounded" />
        </WhatsappShareButton>
      ),
    },
    {
      name: "RedditIcon",
      icon: (
        <RedditShareButton url={shareLink}>
          <RedditIcon size={40} className="rounded" />
        </RedditShareButton>
      ),
    },
    {
      name: "TelegramIcon",
      icon: (
        <TelegramShareButton url={shareLink}>
          <TelegramIcon size={40} className="rounded" />
        </TelegramShareButton>
      ),
    },
  ];

  return (
    <Transition.Root show={alertShare} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setAlertShare}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-3000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-start my-20 justify-center p-3 text-center sm:items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="transform ease-out duration-500 transition"
              enterFrom="translate-y-0 opacity-100 sm:translate-x-0"
              enterTo="opacity-100 translate-y-2 translate-x-0"
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-0 pb-4 pt-0 rtl:text-right ltr:text-left shadow-xl transition-all sm:my-8 sm:max-w-2xl  sm:p-6 w-full">
                <div>
                  <div className="mt-0 text-center sm:mt-2">
                    <div className="mt-0 mb-0 w-full text-right">
                      <div>
                        <div className="flex flex-row justify-between w-full bg-blue-200 sm:rounded-md sm:mx-auto">
                          <p
                            htmlFor="url"
                            className="block text-sm font-medium leading-6 text-gray-600  px-2 py-2"
                          >
                            شارك متجرك
                          </p>
                          <XMarkIcon
                            onClick={() => {
                              setAlertShare(false);
                            }}
                            className=" h-5 w-5 text-gray-600 mt-2.5 mx-4"
                            aria-hidden="true"
                          />
                        </div>
                        <ul className="mt-5 flex flex-row justify-between mx-6 sm:mx-0">
                          {projects.map((project) => (
                            <li key={project.name}>{project.icon}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
