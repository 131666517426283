import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import instance from "../api/api";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { PencilSquareIcon, PlusIcon } from "@heroicons/react/24/outline";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import ListProducts from "./ListProducts";
import { useNavigate } from "react-router-dom";
import { Context } from "../context/ContextProvider";
import exampleData from "../data/exampleData";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AlertEditProduct({
  alertEdit,
  setAlertEdit,
  response,
  loading,
  setNewObject,
  i18n,
  t,
  setData,
  data,
}) {
  const { select, setSelect, setSelectedBranded } = useContext(Context);
  const cancelButtonRef = useRef(null);
  const [open1, setOpen1] = useState(false);
  //   const [select, setSelect] = useState(response[0]);

  useEffect(() => {
    // setData("");
    // console.log("select: " + select);
    setSelect(response[0]);
  }, []);

  return (
    <Transition.Root show={alertEdit} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setSelect(null);
          setAlertEdit(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-3000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="my-20  flex items-start justify-center p-3 text-center sm:items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="transform ease-out duration-500 transition"
              enterFrom="translate-y-0 opacity-100 sm:translate-x-0"
              enterTo="opacity-100 translate-y-2 translate-x-0"
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg  bg-white   p-6 shadow-xl transition-all ltr:text-left rtl:text-right sm:my-8  sm:max-w-2xl sm:p-6">
                <div>
                  <div className="mt-0 text-center sm:mt-2">
                    <div className="mb-0 mt-0 w-full text-right">
                      <div>
                        <div
                          className={classNames(
                            open1 ? "mb-56 " : "mb-0",
                            "mt-0",
                          )}
                          //   className="mb-56 mt-5"
                        >
                          <ListProducts
                            setOpen1={setOpen1}
                            open1={open1}
                            select={select}
                            setSelect={setSelect}
                            response={response}
                            loading={loading}
                            t={t}
                            setData={setData}
                          />
                        </div>

                        <div className="mt-5 flex flex-row  gap-1">
                          <button
                            type="button"
                            className=" inline-flex items-center rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => {
                              if (select !== null) {
                                setNewObject(select);

                                // select?.description?.startsWith("{") ===
                                //   false && setData(JSON.stringify(exampleData));
                                // console.log("one");
                                setSelectedBranded(select.kind);
                              } else {
                                // console.log("two");
                                setNewObject(response[0]);
                                setSelectedBranded(response[0].kind);
                              }
                              setAlertEdit(false);
                            }}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            {t("sure")}
                          </button>
                          <button
                            type="button"
                            className=" mr-2 inline-flex items-center rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => {
                              setNewObject({
                                name: "",
                                marker: "",
                                code: "",
                                unit: "",
                                description: "",
                                category_id: 1,
                                after_vat: "",
                                basicPrice: "",
                                qty: 0,
                                color: null,
                                size: null, //state.product.size
                                digitalProductFile: null, //state.product.size
                                digitalProductFilePassword: null,
                                serialNumber: "",
                                category: "",
                                stock: "",
                                // stock: newOject?.stock && newOject.stock > 0 ? newOject.stock : 1000000,
                                image:
                                  "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",
                                imageLocaly: "",
                              });
                              setSelect(null);
                              setData(JSON.stringify(exampleData));
                              setAlertEdit(false);
                              setSelectedBranded(0);
                            }}
                          >
                            <PlusIcon className=" h-5 w-5" aria-hidden="true" />
                            منتج جديد
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
