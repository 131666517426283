import React, { useState, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import instance from "../api/api";
import jwt_decode from "jwt-decode";
import { useParams, useNavigate } from "react-router-dom";
const people = [
  { id: 1, name: "فرد" },
  { id: 2, name: "مؤسسة" },
  { id: 3, name: "شركة" },
  { id: 4, name: "مؤسسة خيرية" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MerchantId({ i18n, t }) {
  const navigate = useNavigate();
  const { phone } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(people[0]);

  const [newOject, setNewObject] = useState({
    companyUrl: "companyUrl",
    storeManagerName: "",
    phone: phone.length === 1 ? "" : phone,
    url: "",
    kindMerchant: "لم يحدد",
    city: "",
    neighborhood: "",
    address: "",
    zipCode: "",
    email: "لم يحدد",
    // email: "trtr@gmail.com",
  });
  const kindMerchant = ["فرد", "مؤسسة", "شركة", "مؤسسة خيرية"];

  const asArray = Object.entries(newOject);
  const filtered = asArray.filter(([key, value]) => value.length === 0);
  const handleFields = (e) => {
    // console.log("filtered: ", asArray);
    if (e.target.name === "url") {
      setNewObject({
        ...newOject,
        [e.target.name]: e.target.value
          .replace(/[^a-zA-Z]/gi, "")
          .toLowerCase(),
      });
    } else {
      setNewObject({ ...newOject, [e.target.name]: e.target.value });
      // setNewObject({ ...newOject,  });
    }
  };

  const addNewMerchant = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log("e: ", {
    //   ...newOject,
    //   companyUrl:newOject.url,
    //   url: `https://naem.me/${newOject.url}`,
    //   email: jwt_decode(localStorage.getItem("TokenNAEM")).company.email,
    //   kindMerchant: selected.name,
    // });

    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 3000);
    try {
      const req = await instance.post(
        `/companies/update-company-web`,
        {
          ...newOject,
          companyUrl: newOject.url,
          url: `https://naem.me/${newOject.url}`,
          email: jwt_decode(localStorage.getItem("TokenNAEM")).company.email,
          kindMerchant: selected.name,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("TokenNAEM"),
          },
        }
      );
      if (req) {
        setTimeout(() => {
          setIsLoading(false);
          navigate("/login");
        }, 1000);
        // console.log("data: ",req);
      }
    } catch (error) {
      console.log("error: ", error.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="mx-5 sm:container  sm:mx-auto my-5">
      <h2 className="my-6 rtl:text-right ltr:text-left text-2xl font-bold tracking-tight text-gray-900">
        معلومات المتجر
      </h2>
      <form onSubmit={addNewMerchant}>
        <div className="my-4">
          <label
            htmlFor="company-website"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            رابط المتجر
          </label>
          <div className="mt-2   mx-0 ">
            <div className=" flex rounded-md shadow-sm bg-gray-50 border-2 border-gray-50  outline-none">
              {i18n.dir() === "ltr" && (
                <span className="outline-none flex select-none mt-0.5 items-center pl-3 text-gray-600 sm:text-sm bg-gray-300 rounded-md rounded-r-none px-2 ">
                  https://naem.me/
                </span>
              )}
              <input
                type="text"
                name="url"
                id="url"
                value={newOject.url}
                onChange={(e) => handleFields(e)}
                placeholder="test"
                className="outline-none w-48 flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 text-left "
              />
              {i18n.dir() === "rtl" && (
                <span className="flex select-none  mt-0.5  items-center pl-3 text-gray-600 sm:text-sm bg-gray-300 rounded-md rounded-r-none px-2">
                  /https://naem.me
                </span>
              )}
            </div>
          </div>
        </div>

        <Listbox value={selected} onChange={setSelected}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                نوع المتجر
              </Listbox.Label>
              <div className="relative mt-2">
                <Listbox.Button
                  onChange={(e) => console.log("e: ", e.target.value)}
                  className="relative w-full cursor-default rounded-md bg-gray-50 py-1.5 ltr:pl-3 ltr:pr-10 rtl:pr-3 rtl:pl-10 rtl:text-right ltr:text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <span className="block truncate">{selected.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-2 rtl:pl-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {people.map((person) => (
                      <Listbox.Option
                        key={person.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 ltr:pl-3 ltr:pr-9 rtl:pr-3 rtl:pl-9"
                          )
                        }
                        value={person}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {person.name}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-4 rtl:pl-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        <div className="my-4">
          <label
            htmlFor="storeManagerName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            اسم مدير المتجر
          </label>
          <div className="mt-2">
            <input
              type="storeManagerName"
              name="storeManagerName"
              id="storeManagerName"
              value={newOject.storeManagerName}
              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              // placeholder="الرياض"
              onChange={(e) => handleFields(e)}
            />
          </div>
        </div>
        {phone.length === 1 ? (
          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              رقم الجوال
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 rtl:left-3  flex items-center rtl:pr-3 ltr:pl-3">
                {/* <span className="text-gray-500 sm:text-sm">SA</span> */}
                <img
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"
                  }
                  alt=""
                  className="block h-auto w-5 flex-shrink-0"
                />
              </div>
              <input
                id="phone"
                name="phone"
                type="phone"
                value={newOject.phone}
                onChange={(e) => handleFields(e)}
                placeholder="9665xxxxxxxx"
                className="rtl:text-left caret-inherit  p-12 rtl:pr-2 pt-2 block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-10 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                aria-describedby="price-currency"
              />
            </div>
            {phone.length === 1 && newOject.phone.length !== 12 && (
              <h2 className="text-red-400 text-xs">
                لابد أن يكون رقم الجوال بنفس الصيغة
                <strong> 9665xxxxxxxx </strong>
              </h2>
            )}
          </div>
        ) : null}
        <div className="my-4">
          <label
            htmlFor="city"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            المدينة
          </label>
          <div className="mt-2">
            <input
              type="city"
              name="city"
              id="city"
              value={newOject.city}
              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              // placeholder="الرياض"
              onChange={(e) => handleFields(e)}
            />
          </div>
        </div>
        <div className="my-4">
          <label
            htmlFor="neighborhood"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            الحي
          </label>
          <div className="mt-2">
            <input
              type="neighborhood"
              name="neighborhood"
              id="neighborhood"
              value={newOject.neighborhood}
              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              // placeholder="you@example.com"
              onChange={(e) => handleFields(e)}
            />
          </div>
        </div>
        <div className="my-4">
          <label
            htmlFor="address"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            العنوان
          </label>
          <div className="mt-2">
            <input
              type="address"
              name="address"
              id="address"
              value={newOject.address}
              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder=""
              onChange={(e) => handleFields(e)}
            />
          </div>
        </div>
        <div className="my-4">
          <label
            htmlFor="zipCode"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            الرمز البريدي
          </label>
          <div className="mt-2">
            <input
              type="number"
              name="zipCode"
              id="zipCode"
              value={newOject.zipCode}
              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="12345"
              onChange={(e) => handleFields(e)}
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            disabled={filtered.length !== 0 || newOject.phone.length !== 12}
            className={classNames(
              filtered.length !== 0 || newOject.phone.length !== 12
                ? "bg-gray-400  hover:bg-gray-400"
                : "focus:ring-indigo-600  bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600",
              "flex w-full border my-3 justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            )}
          >
            {isLoading ? (
              <>
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 text-white animate-spin rtl:ml-3 ltr:mr-3"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              </>
            ) : (
              `${t("continuation")}`
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default MerchantId;
