// const exampleData = {
//   time: 1678923456000,
//   blocks: [
//     {
//       type: "header",
//       data: {
//         text: " ساعة الـ QuantumGlow  ",
//         level: 2,
//       },
//     },
//     {
//       type: "paragraph",
//       data: {
//         text: "نقدم لكم ساعة QuantumGlow الذكية - حيث يلتقي الأناقة بالوظائف. هذه الساعة الأنيقة ليست مجرد ساعة لقياس الوقت؛ إنها مساعدك الشخصي ومتتبع لللياقة البدنية وبيان أناقتك في آن واحد.",
//       },
//     },
//     {
//       type: "list",
//       data: {
//         style: "unordered",
//         items: [
//           "تتبع خطواتك ومعدل ضربات قلبك وأنماط نومك بسهولة",
//           "تتبع خطواتك ومعدل ضربات قلبك وأنماط نومك بسهولة",
//           " استقبال المكالمات والرسائل وتنبيهات التطبيقات مباشرة على معصمك",
//         ],
//       },
//     },
//     {
//       type: "paragraph",
//       data: {
//         text: "ساعة QuantumGlow الذكية هي أكثر من مجرد ساعة؛ إنها ترقية لنمط حياتك. كن حسدًا لأصدقائك وابقَ على رأس الأمور مع هذا الإكسسوار الذكي.",
//       },
//     },
//   ],
//   version: "2.19.3",
// };
const exampleData = {
  time: 1645877658000,
  blocks: [
    {
      id: "a1b2c3d4",
      type: "header",
      data: {
        text: "iPhone 15 Pro: أحدث هاتف ذكي من Apple",
        level: 3,
      },
    },
    {
      id: "e5f6g7h8",
      type: "paragraph",
      data: {
        text: "iPhone 15 Pro هو أحدث موديل من هواتف Apple الذكية والأنيقة ، ويتميز بشريحة A17 Pro الجديدة ونظام كاميرا 48MP وتصميم تيتانيوم وزوم بصري طويل. تعرف على المزيد عن الميزات والأسعار وخيارات التجارة والعروض الناقلة لهذه الهواتف الجديدة.",
      },
    },
    {
      id: "i9j0k1l2",
      type: "list",
      data: {
        style: "unordered",
        items: [
          "زر Action جديد يمكنك من تعيينه لإجراء مخصص ، مثل فتح التطبيق أو التقاط صورة أو تشغيل الوضع الليلي.",
          "منفذ USB-C مع USB 3.5 يمنحك سرعة تصل إلى 10 جيجابت في الثانية ، بحيث يمكنك نقل مقاطع الفيديو الاحترافية الخاصة بك ، فقط هكذا.",
          "بطارية تدوم طويلاً وتدعم الشحن السريع واللاسلكي والعكسي ، بحيث لا تفوت أي لحظة مهمة.",
          "خصوصية قوية تحمي بياناتك وهويتك من التتبع والاختراق والإعلانات غير المرغوب فيها.",
          "استدامة عالية تقلل من البصمة الكربونية وتستخدم مواد معاد تدويرها ومتجددة في التصنيع والتغليف.",
        ],
      },
    },
    {
      id: "m3n4o5p6",
      type: "paragraph",
      data: {
        text: "لا تفوت فرصة امتلاك iPhone 15 Pro ، الهاتف الذكي الأكثر تقدمًا وجمالًا من Apple. يمكنك زيارة الموقع الرسمي للحصول على مزيد من المعلومات أو العثور على متجر قريب منك أو طلبه عبر الإنترنت. جرب iPhone 15 Pro اليوم واستمتع بتجربة لا مثيل لها.",
      },
    },
    {
      id: "q7r8s9t0",
      type: "paragraph",
      data: {
        text: 'iPhone 15 Pro هو أحدث موديل من هواتف Apple الذكية والأنيقة ، ويتميز بشريحة <mark class="cdx-marker">A17 Pro</mark> الجديدة ونظام كاميرا <mark class="cdx-marker">48MP</mark> وتصميم <mark class="cdx-marker">تيتانيوم</mark> وزوم بصري طويل. تعرف على المزيد عن الميزات والأسعار وخيارات التجارة والعروض الناقلة لهذه الهواتف الجديدة.',
      },
    },
  ],
};

// add descripton iphone 15 in 100 words by arabic language after that add in json blacks editor.js
// const exampleData = {
//   time: 1645734256000,
//   blocks: [
//     {
//       type: "header",
//       data: {
//         text: "وصف مميز لهاتف iPhone 15",
//         level: 1,
//       },
//     },
//     {
//       id: "a1b2c3d4",
//       type: "paragraph",
//       data: {
//         text: "iPhone 15 هو أحدث هاتف ذكي من شركة Apple. يتميز بشاشة Super Retina XDR OLED بحجم 6.1 بوصة ودقة 1179 × 2556 بكسل. يحتوي على كاميرا خلفية مزدوجة بدقة 48 ميجابكسل وكاميرا أمامية بدقة 12 ميجابكسل. يعمل بمعالج Apple A16 Bionic القوي وذاكرة وصول عشوائي 6 جيجابايت. يدعم الاتصال بشبكات الجيل الخامس ويأتي بثلاثة خيارات للتخزين: 128 أو 256 أو 512 جيجابايت. يتوفر بأربعة ألوان: أسود، أبيض، أزرق ووردي. يمتلك بطارية بسعة 3349 مللي أمبير في الساعة ويدعم الشحن السريع PD2.0 15 واط. يتمتع بتصميم متين ومقاوم للماء والغبار بمعيار IP68. يعتبر iPhone 15 هاتفًا ذكيًا متطورًا ومتعدد الاستخدامات يناسب جميع الاحتياجات والأذواق.",
//       },
//     },

//     {
//       type: "paragraph",
//       data: {
//         text: "هل تبحث عن هاتف ذكي جديد يلبي كل توقعاتك؟ إذا كان الأمر كذلك، فإن iPhone 15 هو الخيار المثالي لك. فهو يجمع بين التصميم الأنيق والأداء العالي والميزات المتقدمة التي تجعله يتفوق على منافسيه. فيما يلي بعض الأسباب التي تجعل iPhone 15 هاتفًا لا يقاوم.",
//       },
//     },
//     {
//       id: "xnPuiC9Z8M",
//       type: "list",
//       data: {
//         style: "unordered",
//         items: ["بسم الله الرحمن الرحيم", "السلام عليكم ورحمة الله وبركاته"],
//       },
//     },
//   ],
//   version: "2.19.0",
// };

// const exampleData = {
//   time: 1635603431943,
//   blocks: [
//     {
//       id: "sheNwCUP5A",
//       type: "header",
//       data: {
//         text: "Editor.js",
//         level: 2,
//       },
//     },
//     {
//       id: "12iM3lqzcm",
//       type: "paragraph",
//       data: {
//         text: "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text.",
//       },
//     },
//     {
//       id: "fvZGuFXHmK",
//       type: "header",
//       data: {
//         text: "Key features",
//         level: 3,
//       },
//     },
//     {
//       id: "xnPuiC9Z8M",
//       type: "list",
//       data: {
//         style: "unordered",
//         items: [
//           "It is a block-styled editor",
//           "It returns clean data output in JSON",
//           "Designed to be extendable and pluggable with a simple API",
//         ],
//       },
//     },
//     {
//       id: "-MhwnSs3Dw",
//       type: "header",
//       data: {
//         text: "What does it mean «block-styled editor»",
//         level: 3,
//       },
//     },
//     {
//       id: "Ptb9oEioJn",
//       type: "paragraph",
//       data: {
//         text: 'Workspace in classic editors is made of a single contenteditable element, used to create different HTML markups. Editor.js <mark class="cdx-marker">workspace consists of separate Blocks: paragraphs, headings, images, lists, quotes, etc</mark>. Each of them is an independent contenteditable element (or more complex structure) provided by Plugin and united by Editor\'s Core.',
//       },
//     },
//     {
//       id: "-J7nt-Ksnw",
//       type: "paragraph",
//       data: {
//         text: 'There are dozens of <a href="https://github.com/editor-js">ready-to-use Blocks</a> and the <a href="https://editorjs.io/creating-a-block-tool">simple API</a> for creation any Block you need. For example, you can implement Blocks for Tweets, Instagram posts, surveys and polls, CTA-buttons and even games.',
//       },
//     },
//     {
//       id: "SzwhuyoFq6",
//       type: "header",
//       data: {
//         text: "What does it mean clean data output",
//         level: 3,
//       },
//     },
//     {
//       id: "x_p-xddPzV",
//       type: "paragraph",
//       data: {
//         text: "Classic WYSIWYG-editors produce raw HTML-markup with both content data and content appearance. On the contrary, Editor.js outputs JSON object with data of each Block. You can see an example below",
//       },
//     },
//     {
//       id: "6W5e6lkub-",
//       type: "paragraph",
//       data: {
//         text: 'Given data can be used as you want: render with HTML for <code class="inline-code">Web clients</code>, render natively for <code class="inline-code">mobile apps</code>, create markup for <code class="inline-code">Facebook Instant Articles</code> or <code class="inline-code">Google AMP</code>, generate an <code class="inline-code">audio version</code> and so on.',
//       },
//     },
//     {
//       id: "eD2kuEfvgm",
//       type: "paragraph",
//       data: {
//         text: "Clean data is useful to sanitize, validate and process on the backend.",
//       },
//     },
//     {
//       id: "N8bOHTfUCN",
//       type: "delimiter",
//       data: {},
//     },
//     {
//       id: "IpKh1dMyC6",
//       type: "paragraph",
//       data: {
//         text: "We have been working on this project more than three years. Several large media projects help us to test and debug the Editor, to make it's core more stable. At the same time we significantly improved the API. Now, it can be used to create any plugin for any task. Hope you enjoy. 😏",
//       },
//     },
//   ],
// };
export default exampleData;
