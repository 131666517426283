import { Fragment, useState, useEffect, useContext, useRef } from "react";

import { Context } from "../context/ContextProvider";

import { Transition } from "@headlessui/react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/20/solid";
import i18n from "../i18n/i18n";

export default function Notifications({title}) {
  const { show, setShow } = useContext(Context);

  // On componentDidMount set the timer
  // useEffect(() => {
  //   const timeId = setTimeout(() => {
  //     // After 3 seconds set the show value to false
  //     setShow(false)
  //   }, 3000)

  //   return () => {
  //     clearTimeout(timeId)
  //   }
  // }, []);


  // if (!show) {
  //   return null;
  // }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-start mx-4  py-6 z-10 mt-12"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom={
              i18n.dir() === "rtl"
                ? "translate-y-0 opacity-100 sm:translate-x-0"
                : "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            }
            enterTo={
              i18n.dir() === "rtl"
                ? "opacity-100 translate-y-0 translate-x-1"
                : "translate-y-0 opacity-100 sm:translate-x-0"
            }
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {/* pointer-events-auto */}
            <div className=" w-full max-w-xl overflow-hidden rounded-lg bg-white shadow-2xl shadow-black-500/50 ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                  {title.startsWith("لم") ? (
                      <XCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ltr:ml-3 rtl:mr-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                       {title}
                    </p>
                    {/* <p className="mt-1 text-sm text-gray-500">Anyone with a link can now view this file.</p> */}
                  </div>
                  <div className="ltr:ml-4 rtl:mr-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
