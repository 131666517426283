import React from "react";
import EditorComponent from "../components/EditorComponents";
import TextEditor from "../components/TextEditor";
import Editor from "../components/Editor";

export default function TryEditor() {
  return (
    <div className="bg-red-400">
      <Editor />
    </div>
  );
}

//  @editorjs/checklist @editorjs/code @editorjs/delimiter @editorjs/editorjs @editorjs/embed @editorjs/header @editorjs/inline-code @editorjs/link @editorjs/list @editorjs/marker @editorjs/paragraph @editorjs/quote @editorjs/raw @editorjs/warning editorjs-html html-react-parser react-editor-js
