import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useParams, useNavigate } from "react-router-dom";
import instance from "../api/api";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RestPassword() {
  const navigate = useNavigate();
  const { token } = useParams();

  const [open, setOpen] = useState(true);
  const [isSend, setIsSend] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [handleError, setHandleError] = useState("");
  const cancelButtonRef = useRef(null);
  const [newOject, setNewObject] = useState({
    password: "",
  });

  const handleFields = (e) => {
    setHandleError("")
    setIsError(newOject.password.length >= 0 && newOject.password.length <= 5);
    setNewObject({ ...newOject, [e.target.name]: e.target.value });
  };

  const sendLinkPassword = async (e) => {
    e.preventDefault();
    console.log("password: ", newOject.password);
    setIsLoading(true);

    try {
      const res = await instance.post(`/companies/reset-password-controller`, {
        token: token,
        newPassword: newOject.password,
      });
      if (res) {
        // localStorage.setItem("Token", request.data.accessToken);
        // console.log("jj :", request.data);
        setTimeout(() => {
          setIsLoading(false);
          setIsSend(true);
          navigate("/login");
          setNewObject({ password: "" });
        }, 2000);
      }
    } catch (error) {
      setTimeout(() => {
        setHandleError(error.response.data.error);
        setIsSend(false);
        setIsLoading(false);
        setNewObject({ password: "" });
      }, 2000);

    }
  };

  return (
    <div className="h-full ">
      <Transition.Root show={true} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 h-full"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-10 pb-4 pt-5 rtl:text-right ltr:text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-2xl  sm:p-6">
                  <form
                    className=""
                    action="#"
                    method="POST"
                    onSubmit={sendLinkPassword}
                  >
                    <div>
                      <div
                        className={classNames(
                          isSend ? "bg-green-100" : "bg-red-100",
                          "mx-auto flex h-12 w-12 items-center justify-center rounded-full my-2"
                        )}
                      >
                        {isSend ? (
                          <PaperAirplaneIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowPathIcon
                            className="h-6 w-6 text-red-600 "
                            aria-hidden="true"
                          />
                        )}
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        {/* <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      تم الإرسال بنجاح
                    </Dialog.Title> */}
                        <div className="my-2 w-full text-right">
                          <p className="text-sm text-gray-500">
                            {isSend
                              ? "تم تحديث كلمة المرور بنجاح"
                              : "الرجاء كتابة كلمة المرور الجديدة"}
                          </p>
                        </div>
                      </div>
                    </div>
                    {isSend === false && (
                      <>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          value={newOject.password}
                          onChange={(e) => handleFields(e)}
                          placeholder=""
                          autoComplete="password"
                          required
                          className={classNames(
                            isError || handleError?.length > 0
                              ? "ring-red-500 border focus:border-red-500 my-1 "
                              : "focus:ring-indigo-600 my-3",
                            "block w-full px-2 rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset  sm:text-sm sm:leading-6 "
                          )}
                        />
                        {isError && (
                          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs ltr:ml-1 rtl:mr-1 ">
                            لابد من كلمة المرور تكون أكثر من ٦ حروف أو أرقام
                            
                          </span>
                        )}

                        {handleError?.length > 0 && (
                          <span className="flex items-center font-medium  tracking-wide text-red-500 text-xs mt-1 ltr:ml-1 rtl:mr-1 ">
                            {handleError}

                          </span>
                        )}

                        <div className="mt-1 sm:mt-3 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                          <button
                            type="submit"
                            className={classNames(
                              isError
                                ? " bg-gray-300 "
                                : " bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600",
                              "inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  ltr:sm:col-start-2 rtl:sm:col-start-1"
                            )}
                            disabled={isError}
                          >
                            {isLoading ? (
                              <>
                                <svg
                                  aria-hidden="true"
                                  role="status"
                                  className="inline w-4 h-4 text-white animate-spin rtl:ml-3 ltr:mr-3"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#E5E7EB"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentColor"
                                  />
                                </svg>
                                
                              </>
                            ) : (
                              "إرسال"
                            )}
                          </button>

                          <a
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ltr:sm:col-start-1 rtl:col-start-2 sm:mt-0"
                            href="/login"
                          >
                            إلغاء
                          </a>
                        </div>
                      </>
                    )}
                    {isSend && (
                      <a
                        href="/login"
                        className="block text-center bg-indigo-600 w-full px-2 rounded-md border-0 py-1.5 text-white shadow-sm ring-1   sm:text-sm sm:leading-6 my-3 sm:my-3 "
                      >
                        تم
                      </a>
                    )}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
