import React from "react";
import { ContextProvider } from "./context/ContextProvider";
import RouterBase from "./pages/RouterBase";


function App() {

  return (
    <ContextProvider>
      <RouterBase />
    </ContextProvider>
  );
}

export default App;
