import { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Context } from "../context/ContextProvider";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import instance, {
  useAxios,
  useAxiosDublecate,
  useAxiosInviceOrder,
  useAxiosSeconde,
  useAxiosThried,
  useAxiosThirdCompany,
} from "../api/api";
import jwt_decode from "jwt-decode";
import {
  ClipboardDocumentCheckIcon,
  CreditCardIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  CheckCircleIcon,
  ArrowUpOnSquareStackIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import i18n from "../i18n/i18n";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
// const response = []

export default function Order({ t }) {
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
  } = useContext(Context);
  const people = [
    { id: 1, name: "REDBOX" },
    { id: 2, name: "SMSA" },
    { id: 3, name: "ARAMEX" },
    { id: 4, name: `${t("specialDelivery")}` },
  ];
  const navigate = useNavigate();
  // const { orderId } = useParams();
  const { tranRef } = useParams();

  const [searchParams] = useSearchParams();

  // Get a specific query parameter
  const itemNo = searchParams.get("itemNo");

  // useEffect(() => {
  //   console.log("itemNo: ", itemNo);
  // }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [isLoadingRefund, setIsLoadingRefund] = useState(false);
  const [isLoadingEndRefund, setIsLoadingEndRefund] = useState(false);
  const [selected, setSelected] = useState(people[0]);

  const [newOject, setNewObject] = useState({
    shippingCompany: "لم يحدد",
    trackingNumber: "",
    isShipped: true,
    isCancel: false,
    isRefund: false,
    isEndRefund: false,
  });

  const handleFields = (e) => {
    setNewObject({ ...newOject, [e.target.name]: e.target.value });
  };
  const [isRefund, setIsRefund] = useState(false);
  const [isEndRefund, setIsEndRefund] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const textCutFunc = (text) =>
    `${text
      .trim()
      .split(" ")
      .splice(0, text?.length > 25 ? 6 : 5)
      .join(" ")}  ${text?.length > 25 ? "..." : ""}`;

  const prographCutFunc = (text) =>
    `${text.split(" ").splice(0, 10).join(" ")} ...`;

  const { data1, data2, data3, loading, error } = useAxiosInviceOrder(
    // response._id,
    {
      method: "GET",
      // url: `/invoices/transition-ref/${tranRef}`,
      url:
        tranRef === "search"
          ? `/invoices/transition-ref/search?itemNo=${itemNo}`
          : `/invoices/transition-ref/${tranRef}`,
      headers: {
        accept: "*/*",
      },
    },
    {
      method: "GET",
      url: `/orders/getOrder/`,
      headers: {
        accept: "*/*",
      },
    },
    {
      method: "GET",
      url: `/users/login/`,
      headers: {
        accept: "*/*",
      },
    },
  );

  // const { data1, data2, data3, loading, error } = useAxiosThirdCompany(
  //   // response._id,
  //   {
  //     method: "GET",
  //     url: `/invoices/transition-ref/${tranRef}`,
  //     headers: {
  //       accept: "*/*",
  //     },
  //   },
  //   {
  //     method: "GET",
  //     url: `/orders/getOrder/`,
  //     headers: {
  //       accept: "*/*",
  //     },
  //   },
  //   {
  //     method: "GET",
  //     url: `/companies/get-company-ecommerce/`,
  //     headers: {
  //       accept: "*/*",
  //     },
  //   }
  // );

  const endReFundOrder = async (e) => {
    e.preventDefault();
    setIsLoadingEndRefund(true);

    try {
      const refund = await instance.patch(`/invoices/${data1._id}`, {
        isEndRefund: true,
      });
      if (refund.status === 200) {
        const refundMoney = await instance.get(
          `/push/refund-payment/${refund.data.orderId}/${(
            refund.data.price + 9.0
          ).toFixed(2)}`,
        );
        if (refundMoney.status === 200) {
          // console.log("refund: ",refunde);
          setTimeout(() => {
            setIsEndRefund(true);
            setIsLoadingEndRefund(false);
          }, 1000);
        }
      }
      // if (refund.status === 200) {
      //   setIsEndRefund(true);
      //   setIsLoadingEndRefund(false);
      // }
    } catch (error) {
      console.log("error: ", error.message);
      setIsLoadingEndRefund(false);
    }
  };

  const reFundOrder = async (e) => {
    e.preventDefault();
    setIsLoadingRefund(true);
    // console.log("e: ", {
    //   ...newOject,
    //   shippingCompany: selected.name,
    // });
    // setTimeout(() => {
    //   setIsLoadingRefund(false);
    // }, 3000);
    try {
      const refund = await instance.patch(`/invoices/${data1._id}`, {
        isRefund: true,
        isEndRefund: false,
        shipment_id: data1.shipment_id,
      });
      // if (refund.status === 200) {
      //   const refunde = await instance.get(
      //     `push/refund-payment/${refund.data.orderId}/${(
      //       refund.data.price + 9.000
      //     ).toFixed(2)}`
      //   );
      //   if (refunde.status === 200) {
      //     // console.log("refund: ",refunde);
      //     setTimeout(() => {
      //       setIsRefund(true);
      //       setIsLoadingRefund(false);
      //     }, 1000);
      //   }
      // }
      if (refund.status === 200) {
        setTimeout(() => {
          setIsRefund(true);
          setIsLoadingRefund(false);
        }, 1000);
      }
    } catch (error) {
      console.log("error: ", error.message);
      setIsLoadingRefund(false);
    }
  };

  const cancelOrder = async (e) => {
    e.preventDefault();
    // console.log("kk :");
    setIsLoadingCancel(true);
    // console.log("e: ", {
    //   ...newOject,
    //   shippingCompany: selected.name,
    // });
    // setTimeout(() => {
    //   setIsLoadingCancel(false);
    // }, 3000);
    try {
      const update = await instance.patch(`/invoices/${data1._id}`, {
        isCancel: true,
        shipment_id: data1.shipment_id,
      });
      if (update.status === 200) {
        // console.log("req: ", update);
        // ${update.data.price}
        const refunde = await instance.get(
          `/push/refund-payment/${update.data.orderId}/${(
            update.data.price + 9.0
          ).toFixed(2)}`,
        );
        if (refunde.status === 200) {
          // console.log("refund: ",refunde);
          setTimeout(() => {
            setIsCancel(true);
            setIsLoadingCancel(false);
          }, 1000);
        }
        setIsCancel(true);
        setIsLoadingCancel(false);
      }
    } catch (error) {
      console.log("error: ", error.message);
      setIsLoadingCancel(false);
    }
  };
  const updateOrder = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log("e: ", {
    //   ...newOject,
    //   shippingCompany: selected.name,
    // });
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 3000);
    try {
      const update = await instance.patch(`/invoices/${data1._id}`, {
        ...newOject,
        shippingCompany: selected.name,
        trackingNumber:
          data1?.trackingNumber !== undefined
            ? data1.trackingNumber
            : newOject.trackingNumber,
      });
      if (update.status === 200) {
        // console.log("req: ", update);
        setIsUpdate(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error: ", error.message);
      setIsLoading(false);
    }
  };

  const [newClipboard, setNewClipboard] = useState({ show: false, key: 0 });

  const updateClipboard = (newClip, newClipboard) => {
    navigator.clipboard.writeText(newClip).then(
      () => {
        setNewClipboard(newClipboard);
        setTimeout(() => {
          setNewClipboard({ ...newClipboard, show: false });
        }, 2000);
        // setNewClipboard(false);
      },
      () => {
        console.log("clipboard write failed");
      },
    );
  };

  const oneFetch = useRef(true);
  useEffect(() => {
    if (oneFetch.current || loading === false) {
      setCartItems([]);
      localStorage.setItem("Order", JSON.stringify([]));
      localStorage.removeItem("OrderId");
      localStorage.setItem("CountCart", 0);
      oneFetch.current = false;

      // console.log("c: ",  localStorage.getItem("Order") == null);
      // console.log("order: ", data2);
    }
  }, [loading]);

  // useEffect(() => {
  //   // console.log("c: ",  localStorage.getItem("Order") == null);
  //   setTimeout(() => {
  //     console.log("order: ", data2);
  //   }, 1500);
  // }, []);

  const subTotal = (orders) => {
    // console.log("data: ", orders);
    return orders
      ?.reduce((price, item) => price + item.qty * item.unit, 0)
      .toFixed(2);
  };

  // const { data, loadingSeconde } = useAxiosSeconde({
  //   method: "GET",
  //   url: `/orders/getOrder/${_id}`,
  //   headers: {
  //     accept: "*/*",
  //   },
  // });

  function shortCutFile(string) {
    return string
      .substring(string.lastIndexOf("/") + 1)
      .replace(/[0-9]/g, "") // $& means the whole matched string
      .replace("-", ""); // $& means the whole matched string
  }

  return (
    <>
      {loading ? (
        <div className="flex w-full items-center justify-center py-20">
          <div className="flex items-center justify-center space-x-1 text-sm text-gray-700 rtl:space-x-reverse">
            <svg
              fill="none"
              className="h-20 w-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <main className="bg-white px-2 pb-24 pt-2 sm:px-6 lg:px-8 lg:py-4">
          <div className="sm:mx-auto sm:max-w-3xl">
            <div className="sm:max-w-3xl">
              <div className="flex flex-row">
                <div
                  onClick={() => {
                    // navigate(`/orders`);
                    navigate(-1);
                  }}
                  className="my-2 flex flex-row  items-center justify-between rounded-md drop-shadow-lg hover:bg-gray-600 hover:drop-shadow-2xl "
                >
                  <button
                    type="button"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="flex h-8  w-44 items-center justify-start  gap-1 rounded-md  bg-indigo-500 px-1 text-4xl text-white hover:bg-indigo-700 sm:h-10 "
                    id="btn-back-to-top"
                  >
                    <ChevronRightIcon
                      className="h-6  w-6 ltr:rotate-180 "
                      aria-hidden="true"
                    />
                    <p
                      htmlFor="url"
                      className="mx-2  text-sm font-semibold  leading-6 text-white sm:mt-0 sm:block sm:text-base "
                    >
                      معلومات الطلب
                    </p>
                  </button>
                </div>
                <div></div>
              </div>

              <div className="flex flex-row justify-between ">
                <div className="flex flex-row items-center gap-1">
                  <div className="mx-0 my-3 flex w-32 justify-around border-green-500 bg-green-100   px-2 py-2 text-[8px] font-semibold  text-green-600 ltr:border-l-4 rtl:border-r-4 sm:w-52 sm:py-2 sm:pt-2.5 sm:text-sm">
                    <p>{t("itemNo")}:</p>
                    <p>
                      {data1.orderId !== undefined
                        ? data1.orderId
                        : data1.itemNo}
                    </p>
                  </div>
                  {data2.orders[0].kind === 0 && (
                    <button
                      onClick={() =>
                        // console.log("print: ", data1?.url_shipping_label)
                        window.open(
                          `${data1?.url_shipping_label}`,
                          "_blank",
                          "rel=noopener noreferrer",
                        )
                      }
                      // type="submit"
                      // disabled={newOject.trackingNumber.length === 0}
                      className={classNames(
                        false
                          ? "bg-gray-400  hover:bg-gray-400"
                          : "bg-green-600  hover:bg-green-500 focus:ring-green-600 focus-visible:outline-green-600",
                        "w-23 my-3 flex justify-center rounded-md border px-3  py-2 text-[8px]  font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2  focus-visible:outline-offset-2 sm:w-36 sm:text-sm ",
                      )}
                    >
                      {false ? (
                        <>
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline h-4 w-4 animate-spin text-white ltr:mr-3 rtl:ml-3"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="#E5E7EB"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentColor"
                            />
                          </svg>
                        </>
                      ) : (
                        `${t("waybillPrint")}`
                      )}
                    </button>
                  )}
                </div>

                {data1.isCancel === false &&
                isCancel === false &&
                data1.isShipped === false &&
                isUpdate === false ? (
                  <form onSubmit={cancelOrder}>
                    <button
                      type="submit"
                      // disabled={newOject.trackingNumber.length === 0}
                      className={classNames(
                        false
                          ? "bg-gray-400  hover:bg-gray-400"
                          : "bg-red-600  hover:bg-red-500 focus:ring-red-600 focus-visible:outline-red-600",
                        "my-3 flex w-36 justify-center rounded-md border  px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline  focus-visible:outline-2 focus-visible:outline-offset-2 sm:text-sm ",
                      )}
                    >
                      {isLoadingCancel ? (
                        <>
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline h-4 w-4 animate-spin text-white ltr:mr-3 rtl:ml-3"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="#E5E7EB"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentColor"
                            />
                          </svg>
                        </>
                      ) : (
                        `${t("requestCancelOrder")}`
                      )}
                    </button>
                  </form>
                ) : (data1.isShipped &&
                    data1.isRefund === false &&
                    isRefund === false) ||
                  (isUpdate === true && isRefund === false) ? (
                  <form onSubmit={reFundOrder}>
                    <button
                      type="submit"
                      // disabled={newOject.trackingNumber.length === 0}
                      className={classNames(
                        false
                          ? "bg-gray-400  hover:bg-gray-400"
                          : "bg-orange-600  hover:bg-orange-500 focus:ring-orange-600 focus-visible:outline-orange-600",
                        "my-3 flex w-36 justify-center rounded-md border  px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline  focus-visible:outline-2 focus-visible:outline-offset-2 sm:text-sm ",
                      )}
                    >
                      {isLoadingRefund ? (
                        <>
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline h-4 w-4 animate-spin text-white ltr:mr-3 rtl:ml-3"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="#E5E7EB"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentColor"
                            />
                          </svg>
                        </>
                      ) : (
                        `${t("requestRefund")}`
                      )}
                    </button>
                  </form>
                ) : (data1.isEndRefund === false &&
                    isEndRefund === false &&
                    isCancel === false &&
                    data1.isCancel === false) ||
                  (isRefund === true && isEndRefund === false) ? (
                  <form onSubmit={endReFundOrder}>
                    <button
                      type="submit"
                      // disabled={newOject.trackingNumber.length === 0}
                      className={classNames(
                        false
                          ? "bg-gray-400  hover:bg-gray-400"
                          : "bg-gray-600  hover:bg-gray-500 focus:ring-gray-600 focus-visible:outline-gray-600",
                        "my-3 flex w-36 justify-center rounded-md border  px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline  focus-visible:outline-2 focus-visible:outline-offset-2 sm:text-sm ",
                      )}
                    >
                      {isLoadingEndRefund ? (
                        <>
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline h-4 w-4 animate-spin text-white ltr:mr-3 rtl:ml-3"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="#E5E7EB"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentColor"
                            />
                          </svg>
                        </>
                      ) : (
                        `${t("completeReturn")}`
                      )}
                    </button>
                  </form>
                ) : null}
              </div>
              {(data1.isRefund &&
                data1.isEndRefund === false &&
                isEndRefund === false) ||
              (isRefund &&
                data1.isEndRefund === false &&
                isEndRefund === false &&
                data1.isEndRefund === false) ? (
                <div className="my-2 flex flex-row">
                  <ArrowPathIcon
                    className="mt-1 h-5 w-5 text-orange-700 ltr:mr-2 rtl:ml-2"
                    aria-hidden="true"
                  />
                  <p className="mt-0.5 text-base text-orange-700 ltr:mr-2 rtl:ml-2">
                    {t("pending1")}
                  </p>
                </div>
              ) : data1.isEndRefund || isEndRefund ? (
                <div className="my-2 flex flex-row">
                  <XCircleIcon
                    className="mt-1 h-5 w-5 text-red-700 ltr:mr-2 rtl:ml-2"
                    aria-hidden="true"
                  />

                  <p className="mt-0.5 text-base text-red-700 ltr:mr-2 rtl:ml-2">
                    {t("requestRefundTitle")}{" "}
                    {(data1.price + 9.0).toFixed(2) + " " + t("sar")}
                  </p>
                </div>
              ) : data1.isCancel || isCancel ? (
                <div className="my-2 flex flex-row">
                  <XCircleIcon
                    className="mt-1 h-5 w-5 text-red-700 ltr:mr-2 rtl:ml-2"
                    aria-hidden="true"
                  />

                  <p className="mt-0.5 text-base text-red-700 ltr:mr-2 rtl:ml-2">
                    {t("requestCancelTitle")}{" "}
                    {(data1.price + 9.0).toFixed(2) + " " + t("sar")}
                  </p>
                </div>
              ) : data1?.isShipped || isUpdate ? (
                <div className="my-2 flex flex-row">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 text-green-700 ltr:mr-2 rtl:ml-2"
                    aria-hidden="true"
                  />

                  <p className=" mt-0.5 text-base text-green-700 ltr:mr-2 rtl:ml-2">
                    {t("shipped")}
                  </p>
                </div>
              ) : (
                <>
                  <div className="my-2 flex flex-row">
                    <ArrowPathIcon
                      className="mt-1 h-5 w-5 text-yellow-500 ltr:mr-2 rtl:ml-2"
                      aria-hidden="true"
                    />
                    <p className=" mt-0.5 text-base text-yellow-500 ltr:mr-2 rtl:ml-2">
                      {t("pending")}
                    </p>
                  </div>
                  <form className="my-4" onSubmit={updateOrder}>
                    <Listbox value={selected} onChange={setSelected}>
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                            {t("shippingMethod")}
                          </Listbox.Label>
                          <div className="relative mt-2">
                            <Listbox.Button
                              // onChange={(e) =>
                              //   console.log("e: ", e.target.value)
                              // }
                              className="relative w-full cursor-default rounded-md bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 ltr:pl-3 ltr:pr-10 ltr:text-left rtl:pl-10 rtl:pr-3 rtl:text-right sm:text-sm sm:leading-6"
                            >
                              <span className="block truncate">
                                {selected.name}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 flex items-center ltr:right-0 ltr:pr-2 rtl:left-0 rtl:pl-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {people.map((person) => (
                                  <Listbox.Option
                                    key={person.id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 ltr:pl-3 ltr:pr-9 rtl:pl-9 rtl:pr-3",
                                      )
                                    }
                                    value={person}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate",
                                          )}
                                        >
                                          {person.name}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 flex items-center ltr:right-0 ltr:pr-4 rtl:left-0 rtl:pl-4",
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                          {selected.name === "REDBOX" && (
                            <>
                              <Listbox.Label className="mt-1 block text-xs font-medium leading-6 text-red-700 sm:text-sm">
                                {t("note1")}
                              </Listbox.Label>
                              <Listbox.Label className="block text-xs font-medium leading-6 text-red-700 sm:text-sm">
                                {t("note2")}
                              </Listbox.Label>
                              <Listbox.Label className="block text-xs font-medium leading-6 text-red-700 sm:text-sm">
                                {t("note3")}
                              </Listbox.Label>
                            </>
                          )}
                        </>
                      )}
                    </Listbox>
                    {selected.id !== 4 && selected.id !== 1 ? (
                      <div className="my-2">
                        <label
                          htmlFor="trackingNumber"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {t("trackingNumber")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name="trackingNumber"
                            id="trackingNumber"
                            value={newOject.trackingNumber}
                            className="block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="12345"
                            onChange={(e) => handleFields(e)}
                          />
                        </div>
                      </div>
                    ) : null}
                    <button
                      type="submit"
                      disabled={
                        newOject.trackingNumber.length === 0 &&
                        selected.id !== 4 &&
                        selected.id !== 1
                      }
                      className={classNames(
                        newOject.trackingNumber.length === 0 &&
                          selected.id !== 4 &&
                          selected.id !== 1
                          ? "bg-gray-400  hover:bg-gray-400"
                          : "bg-indigo-600  hover:bg-indigo-500 focus:ring-indigo-600 focus-visible:outline-indigo-600",
                        "my-3 flex w-full justify-center rounded-md border  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ",
                      )}
                    >
                      {isLoading ? (
                        <>
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline h-4 w-4 animate-spin text-white ltr:mr-3 rtl:ml-3"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="#E5E7EB"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentColor"
                            />
                          </svg>
                        </>
                      ) : (
                        `${t("orderShipping")}`
                      )}
                    </button>
                  </form>
                </>
              )}

              {data2.orders[0].kind === 0 && (
                <dl className="mt-4 text-sm font-medium">
                  <dt className="text-gray-900">{t("trackingNumber")}</dt>
                  {data1.isShipped ? (
                    <div className="flex w-full flex-col items-start ">
                      <button
                        onClick={() =>
                          data1.shippingCompany.trim() === "SMSA"
                            ? window.location.replace(
                                `https://www.smsaexpress.com/sa/ar/trackingdetails?tracknumbers%5B0%5D=${data1.trackingNumber.trim()}`,
                              )
                            : data1.shippingCompany.trim() === "ARAMEX"
                              ? window.location.replace(
                                  `https://www.aramex.com/sa/ar/track/results?mode=0&ShipmentNumber=${data1.trackingNumber.trim()}`,
                                )
                              : data1.shippingCompany.trim() === "REDBOX"
                                ? window.location.replace(
                                    `https://redboxsa.com/en/tracking-page/?tracking_number=${data1.trackingNumber}`,
                                  )
                                : ""
                        }
                        className="my-4 text-indigo-600"
                      >
                        {data1.trackingNumber}
                      </button>
                      <span className="flex flex-row">
                        <p className="mt-0.5">{t("trackingLink")} :</p>
                        <ArrowUpOnSquareStackIcon
                          onClick={() =>
                            data1.shippingCompany.trim() === "SMSA"
                              ? window.location.replace(
                                  `https://www.smsaexpress.com/sa/ar/trackingdetails?tracknumbers%5B0%5D=${data1.trackingNumber.trim()}`,
                                )
                              : data1.shippingCompany.trim() === "ARAMEX"
                                ? window.location.replace(
                                    `https://www.aramex.com/sa/ar/track/results?mode=0&ShipmentNumber=${data1.trackingNumber.trim()}`,
                                  )
                                : data1.shippingCompany.trim() === "REDBOX"
                                  ? window.location.replace(
                                      `https://redboxsa.com/en/tracking-page/?tracking_number=${data1.trackingNumber}`,
                                    )
                                  : ""
                          }
                          className="mr-2 h-5 w-5 text-indigo-600"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                  ) : (
                    <dd className="mt-2 text-indigo-600">
                      {t("waitTrackingNumber")}
                    </dd>
                  )}
                </dl>
              )}
            </div>

            <section
              aria-labelledby="order-heading"
              className="mt-5 border-t border-gray-200"
            >
              <h2 id="order-heading" className="sr-only">
                {t("yourOrder")}
              </h2>

              <h3 className="sr-only">Items</h3>
              {data2?.orders != null &&
                data2?.orders.map((order) => (
                  <div
                    key={order?._id}
                    className="flex space-x-6 border-b border-gray-200 py-2 rtl:space-x-reverse"
                  >
                    <div className="">
                      <div className="flex flex-row gap-2">
                        <img
                          src={
                            order?.image === undefined
                              ? "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png"
                              : order.image.split(",")[0]
                          }
                          alt={"image0"}
                          className="sm:h-30 sm:w-30 h-20 w-20 flex-none rounded-lg bg-gray-100 object-contain object-center"
                        />

                        <div className="flex flex-auto flex-col">
                          <div>
                            <h4 className="font-medium text-gray-900">
                              <a href={order?.href}>
                                {textCutFunc(order?.name)}
                              </a>
                            </h4>
                            <p className="mt-2 text-sm text-gray-600">
                              {order?.description === undefined
                                ? ""
                                : prographCutFunc(order?.description)}
                            </p>
                          </div>
                          <div className="flex flex-1 items-end sm:mt-8 ">
                            <dl className="flex space-x-4 divide-gray-200 text-sm ltr:divide-x rtl:space-x-reverse sm:space-x-6">
                              <div className="flex">
                                <dt className="font-medium text-gray-900">
                                  {t("quantity") + ":"}
                                </dt>
                                <dd className="text-gray-700 ltr:ml-2 rtl:mr-2">
                                  {order?.qty}
                                </dd>
                              </div>
                              <div className="flex ltr:pl-4 rtl:border-r rtl:pr-4   ltr:sm:pl-6 rtl:sm:pr-6 ">
                                <dt className="font-medium text-gray-900">
                                  {t("price") + ":"}
                                </dt>
                                <dd className="text-gray-700 ltr:ml-2 rtl:mr-2">
                                  {order.unit.toFixed(2) + " " + t("sar")}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                      {order.kind === 0 && (
                        <div className="mt-1 flex flex-1 items-end ">
                          <dl className="flex space-x-4 divide-gray-200 text-sm ltr:divide-x rtl:space-x-reverse sm:space-x-6">
                            {order?.color?.length > 0 ? (
                              <div className="flex">
                                <dt className="font-medium text-gray-900 ">
                                  {t("color") + ":"}
                                </dt>
                                <dd
                                  className="h-5 w-5 rounded-xl text-gray-700 ring-2 ltr:ml-2 rtl:mr-2"
                                  style={{ backgroundColor: order.color }}
                                ></dd>
                              </div>
                            ) : null}

                            {order?.size?.length > 0 ? (
                              <div className="flex flex-row gap-1 ltr:pl-4 rtl:border-r rtl:pr-4   ltr:sm:pl-6 rtl:sm:pr-6 ">
                                <p className="text-gray-500 ">{t("size")}:</p>
                                <p className="text-gray-500 ">{order.size}</p>
                              </div>
                            ) : null}
                          </dl>
                        </div>
                      )}

                      {order.kind === 2 && (
                        <div className="mt-3 border-t pt-3">
                          <div>
                            <p className="text-[12px] font-bold text-indigo-500 sm:text-lg">
                              معلومات البطاقة
                            </p>
                          </div>

                          <div className="mt-0 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
                              <div className="inline-block min-w-full pt-2 align-middle sm:px-6 lg:px-8 ">
                                <table className=" min-w-full divide-y divide-gray-300">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3.5 text-[12px] font-semibold text-gray-900 ltr:pl-4 ltr:pr-3 ltr:text-left rtl:pl-3 rtl:pr-4 rtl:text-right sm:text-sm ltr:sm:pl-0 rtl:sm:pr-0 "
                                      >
                                        اسم البطاقة الرقمية
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-[12px] font-semibold text-gray-900 ltr:text-left rtl:text-right sm:text-sm"
                                      >
                                        رقم الكود أو الرقم السري
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-[12px] font-semibold text-gray-900 ltr:text-left rtl:text-right sm:text-sm"
                                      >
                                        حالتها
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-[12px] font-semibold text-gray-900 ltr:text-left rtl:text-right sm:text-sm"
                                      >
                                        ملاحظات
                                      </th>
                                      <th
                                        scope="col"
                                        className="relative py-3.5 ltr:pl-3 ltr:pr-4 rtl:pl-3 rtl:pr-4 rtl:text-right ltr:sm:pr-0 rtl:sm:pl-0"
                                      >
                                        <span className="sr-only">Edit</span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className=" divide-y divide-gray-200 bg-white">
                                    {order.cards?.map((card, key) => (
                                      <tr key={key}>
                                        <td className="whitespace-nowrap py-5 text-[12px] ltr:pl-4 ltr:pr-3 rtl:pl-3 rtl:pr-4 sm:text-sm ltr:sm:pl-0 rtl:sm:pr-0 ">
                                          <div className="flex items-center">
                                            <div className="ltr:ml-4 rtl:mr-4">
                                              <div className="font-medium text-gray-900">
                                                {card.title}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="whitespace-nowrap px-4 py-5 text-[12px] text-gray-500 sm:text-sm">
                                          <div className="flex flex-row items-center justify-start gap-4 ">
                                            {newClipboard.show === true &&
                                            newClipboard.key === key ? (
                                              <ClipboardDocumentCheckIcon
                                                className=" h-5 w-5 animate-bounce text-green-600 hover:cursor-pointer"
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <DocumentDuplicateIcon
                                                className=" h-5 w-5 text-gray-400 hover:cursor-pointer"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  updateClipboard(
                                                    card.digitalCard,
                                                    {
                                                      show: true,
                                                      key: key,
                                                    },
                                                  )
                                                }
                                              />
                                            )}

                                            {card.isShow === true ? (
                                              <div className="text-gray-900">
                                                {card.digitalCard}
                                              </div>
                                            ) : (
                                              <div className="pt-1 text-gray-900">
                                                ****************
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                          <span
                                            className={classNames(
                                              card.isValid
                                                ? "bg-green-50 text-green-700 ring-green-600/20"
                                                : "bg-red-50 text-red-700 ring-red-600/20",
                                              "inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ",
                                            )}
                                          >
                                            {card.isValid
                                              ? "Active"
                                              : "Not Active"}
                                          </span>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                          {card.note}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {order.kind === 1 && (
                        <div className="mt-3 border-t pt-3">
                          <div>
                            <p className="text-[12px] font-bold text-indigo-500 sm:text-sm">
                              معلومات الملف
                            </p>
                          </div>
                          <div className="mt-0 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
                              <div className="inline-block min-w-full pt-2 align-middle sm:px-6 lg:px-8 ">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3.5 text-[12px] font-semibold text-gray-900 ltr:pl-4 ltr:pr-3 ltr:text-left rtl:pl-3 rtl:pr-4 rtl:text-right sm:text-sm ltr:sm:pl-0 rtl:sm:pr-0 "
                                      >
                                        رابط الملف
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-[12px] font-semibold text-gray-900 ltr:text-left rtl:text-right sm:text-sm"
                                      >
                                        كلمة السر
                                      </th>

                                      <th
                                        scope="col"
                                        className="relative py-3.5 ltr:pl-3 ltr:pr-4 rtl:pl-3 rtl:pr-4 rtl:text-right ltr:sm:pr-0 rtl:sm:pl-0"
                                      >
                                        <span className="sr-only">Edit</span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className=" divide-y divide-gray-200 bg-white">
                                    <tr>
                                      <td className="whitespace-nowrap py-5 text-sm ltr:pl-4 ltr:pr-3 rtl:pl-3 rtl:pr-4 ltr:sm:pl-0 rtl:sm:pr-0 ">
                                        <div className="flex items-center">
                                          <div className="flex flex-row items-center justify-start gap-3 ltr:ml-4 rtl:mr-4">
                                            {newClipboard.show === true &&
                                            newClipboard.key === 1 ? (
                                              <ClipboardDocumentCheckIcon
                                                className=" h-5 w-5 animate-bounce text-green-600 hover:cursor-pointer"
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <DocumentDuplicateIcon
                                                className=" h-5 w-5 text-gray-400 hover:cursor-pointer"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  updateClipboard(
                                                    order.digitalProductFile,
                                                    {
                                                      show: true,
                                                      key: 1,
                                                    },
                                                  )
                                                }
                                              />
                                            )}
                                            <div className="   font-medium text-gray-900">
                                              {shortCutFile(
                                                order.digitalProductFile,
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="whitespace-nowrap px-4 py-5 text-sm text-gray-500">
                                        <div className="flex flex-row items-center justify-start gap-4 ">
                                          {order.digitalProductFilePassword
                                            .length > 0 ? (
                                            newClipboard.show === true &&
                                            newClipboard.key === 2 ? (
                                              <ClipboardDocumentCheckIcon
                                                className=" h-5 w-5 animate-bounce text-green-600 hover:cursor-pointer"
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <DocumentDuplicateIcon
                                                className=" h-5 w-5 text-gray-400 hover:cursor-pointer"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  updateClipboard(
                                                    order.digitalProductFilePassword,
                                                    {
                                                      show: true,
                                                      key: 2,
                                                    },
                                                  )
                                                }
                                              />
                                            )
                                          ) : (
                                            <></>
                                          )}

                                          {order.digitalProductFilePassword
                                            .length > 0 ? (
                                            <div className=" text-gray-900">
                                              {order.digitalProductFilePassword}
                                            </div>
                                          ) : (
                                            <div className=" text-gray-900">
                                              لاتوجد كلمة سر
                                            </div>
                                          )}
                                        </div>
                                      </td>

                                      <td className="whitespace-nowrap px-3 text-sm text-white ">
                                        <button
                                          type="button"
                                          className="rounded bg-indigo-600 p-2 hover:cursor-pointer  "
                                          onClick={() => {
                                            window.open(
                                              `${order.digitalProductFile}`,
                                              "_blank",
                                              "rel=noopener noreferrer",
                                            );
                                          }}
                                        >
                                          اضغط هنا
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}

              <div className="ltr:sm:ml-40 ltr:sm:pl-6 rtl:sm:mr-40 rtl:sm:pr-6 ">
                <h3 className="sr-only">معلوماتك</h3>

                {data2.orders[0].kind === 0 && (
                  <>
                    <h4 className="sr-only">العنوان</h4>
                    <dl className="grid grid-cols-2 gap-x-6 py-5 text-sm">
                      <div>
                        <dt className="font-medium text-gray-900">
                          {t("shippingAddress")}
                        </dt>
                        <dd className="mt-2 text-gray-700">
                          <address className="not-italic">
                            <span className="block">{data3?.city}</span>
                            <span className="block">{data3?.neighborhood}</span>
                            <span className="block">{data3?.address}</span>
                          </address>
                        </dd>
                      </div>
                      <div>
                        <dt className="font-medium text-gray-900">
                          {t("billingAddress")}
                        </dt>
                        <dd className="mt-2 text-gray-700">
                          <address className="not-italic">
                            <span className="block">{data3?.city}</span>
                            <span className="block">{data3?.neighborhood}</span>
                            <span className="block">{data3?.address}</span>
                          </address>
                        </dd>
                      </div>
                    </dl>
                  </>
                )}

                <h4 className="sr-only">الدفع</h4>
                <dl
                  className={classNames(
                    data2.orders[0].kind === 0 ? "border-t " : " ",
                    " grid grid-cols-2  gap-x-6 border-gray-200 py-5 text-sm",
                  )}
                >
                  <div>
                    <dt className="font-medium text-gray-900">
                      {t("PaymentMethod")}
                    </dt>
                    <div className="mt-2 flex flex-row text-gray-700">
                      {data1.paymentType !== "CARD" ? (
                        <img
                          src={
                            "https://fatora-sa.herokuapp.com/api/v1/products/1681458043373-fatora-cashier-Apple_Pay_Mark_RGB_041619.svg"
                          }
                          alt=""
                          className="mt-0.5 block h-4 w-auto flex-shrink-0 ltr:mr-2 rtl:ml-2"
                        />
                      ) : (
                        <CreditCardIcon
                          className=" h-5 w-5 ltr:mr-2 rtl:ml-2"
                          aria-hidden="true"
                        />
                      )}
                      <p>{data1.paymentType}</p>
                      {/* <p>Mastercard</p>
                    <p>
                      <span aria-hidden="true">••••</span>
                      <span className="sr-only">Ending in </span>1545
                    </p> */}
                    </div>
                  </div>
                  {data2.orders[0].kind === 0 && (
                    <div>
                      <dt className="font-medium text-gray-900">
                        {t("shippingMethod")}
                      </dt>
                      <dd className="mt-2 text-gray-700">
                        <p>
                          {data1.shippingCompany
                            ? data1.shippingCompany
                            : `${t("waitRackingNumber")}`}
                        </p>
                        <p>{t("limitShipping")}</p>
                      </dd>
                    </div>
                  )}
                </dl>

                <h3 className="sr-only">Summary</h3>

                <dl className="space-y-6 border-t border-gray-200 pt-5 text-sm">
                  <div className="flex justify-between">
                    <dt className="font-medium text-gray-900">
                      {t("subTotal")}
                    </dt>
                    <dd className="text-gray-700">
                      {data1.price.toFixed(2) + " " + t("sar")}
                      {/* {subTotal(data2?.orders) + " " + t("sar")} */}
                    </dd>
                  </div>
                  {/* <div className="flex justify-between">
                <dt className="flex font-medium text-gray-900">
                  Discount
                  <span className="ltr:ml-2 rtl:mr-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-600">
                    STUDENT50
                  </span>
                </dt>
                <dd className="text-gray-700">-$18.00 (50%)</dd>
              </div> */}
                  {data2.orders[0].kind === 0 && (
                    <div className="flex justify-between">
                      <dt className="font-medium text-gray-900">
                        {t("shippingCost")}
                      </dt>
                      <dd className="text-gray-700">
                        {(9.0).toFixed(2) + " " + t("sar")}
                      </dd>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <dt className="font-medium text-gray-900">
                      {t("orderTotal")}
                    </dt>
                    {data2.orders[0].kind === 0 ? (
                      <dd className="text-gray-900">
                        {(data1.price + 9.0).toFixed(2) + " " + t("sar")}
                      </dd>
                    ) : (
                      <dd className="text-gray-900">
                        {data1.price.toFixed(2) + " " + t("sar")}
                      </dd>
                    )}
                  </div>
                </dl>
              </div>
            </section>
          </div>
        </main>
      )}
    </>
  );
}
