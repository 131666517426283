import { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Context } from "../context/ContextProvider";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import instance, {
  useAxios,
  useAxiosDublecate,
  useAxiosInviceOrder,
  useAxiosSeconde,
  useAxiosThried,
  useAxiosThirdCompany,
  useAxiosThriedSaveDomain,
} from "../api/api";
import jwt_decode from "jwt-decode";
import { CreditCardIcon } from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  CheckCircleIcon,
  ArrowUpOnSquareStackIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import i18n from "../i18n/i18n";
import {
  ChevronRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
// const response = []

export default function OrderMerchant({ t }) {
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
  } = useContext(Context);
  const people = [
    { id: 1, name: "SMSA" },
    { id: 2, name: "ARAMEX" },
    { id: 3, name: `${t("specialDelivery")}` },
  ];
  const navigate = useNavigate();
  const { tranRef } = useParams();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [isLoadingRefund, setIsLoadingRefund] = useState(false);
  const [isLoadingEndRefund, setIsLoadingEndRefund] = useState(false);
  const [selected, setSelected] = useState(people[0]);

  const [newOject, setNewObject] = useState({
    shippingCompany: "لم يحدد",
    trackingNumber: "",
    isShipped: true,
    isCancel: false,
    isRefund: false,
    isEndRefund: false,
  });

  const handleFields = (e) => {
    setNewObject({ ...newOject, [e.target.name]: e.target.value });
  };
  const [isRefund, setIsRefund] = useState(false);
  const [isEndRefund, setIsEndRefund] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isShipped, setIsShipped] = useState(false);

  const textCutFunc = (text) =>
    `${text
      .trim()
      .split(" ")
      .splice(0, text?.length > 25 ? 6 : 5)
      .join(" ")}  ${text?.length > 25 ? "..." : ""}`;

  const prographCutFunc = (text) =>
    `${text.split(" ").splice(0, 10).join(" ")} ...`;

  const { data1, data2, data3, loading, error } = useAxiosThirdCompany(
    // response._id,
    {
      method: "GET",
      url: `/invoices/transition-ref/${tranRef}`,
      headers: {
        accept: "*/*",
      },
    },
    {
      method: "GET",
      url: `/orders/getOrder/`,
      headers: {
        accept: "*/*",
      },
    },
    {
      method: "GET",
      url: `/companies/get-company-ecommerce/`,
      headers: {
        accept: "*/*",
      },
    },
  );
  // const { infoOrder, infoDomain, loadingSaveDomain, errorSaveDomain } =
  //   useAxiosThriedSaveDomain(
  //     {
  //       method: "GET",
  //       url: `/invoices/item-no/${orderId}`,
  //       headers: {
  //         accept: "*/*",
  //       },
  //     },
  //     {
  //       method: "GET",
  //       url: `/orders/getOrder/`,
  //       headers: {
  //         accept: "*/*",
  //       },
  //     },
  //     {
  //       method: "POST",
  //       url: `/push/buy-domain`,
  //       headers: {
  //         accept: "*/*",
  //         "Content-Type": "application/json",
  //       },
  //     },
  //     {
  //       method: "POST",
  //       url: `/push/save-domain-in-company`,
  //       headers: {
  //         accept: "*/*",
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );

  const sureBuyAdmain = async () => {
    const jj = {
      domainName: data2?.orders[0].name,
      purchasePrice: data2?.orders[0].unit / 3.75,
    };
    console.log("jj: ", jj);
    try {
      const buyAdmain = await instance.post(`/push/buy-domain`, {
        domainName: data2?.orders[0].name,
        purchasePrice: data2?.orders[0].unit / 3.75,
      });

      const data = await buyAdmain.data;
      if (data.message === "Internal Error") {
        console.log("not buy domain error from server");
        setIsLoading(false);
      } else {
        const saveDomain = await instance.post(`/push/save-domain-in-company`, {
          domainName: data.domainName,
          nameservers: data.nameservers,
          locked: data.locked,
          autorenewEnabled: data.autorenewEnabled,
          expireDate: data.expireDate,
          createDate: data.createDate,
          renewalPrice: data.renewalPrice,
          company_id: data1.merchant_id,
        });
        // const saveSure = saveDomain.data;

        setIsLoading(false);
        setIsShipped(true);
        // console.log(
        //   "data: ",
        //   jwt_decode(localStorage.getItem("TokenNAEM")).company?.superAdmin,
        //   isShipped === false,
        //   data1.isShipped === false
        // );
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error: ", error.message);
    }
  };

  const endReFundOrder = async (e) => {
    e.preventDefault();
    setIsLoadingEndRefund(true);

    try {
      const refund = await instance.patch(`invoices/${data1._id}`, {
        isEndRefund: true,
      });
      if (refund.status === 200) {
        const refundMoney = await instance.get(
          `push/refund-payment/${
            refund.data.orderId
          }/${refund.data.price.toFixed(2)}`,
        );
        if (refundMoney.status === 200) {
          // console.log("refund: ",refunde);
          setTimeout(() => {
            setIsEndRefund(true);
            setIsLoadingEndRefund(false);
          }, 1000);
        }
      }
      // if (refund.status === 200) {
      //   setIsEndRefund(true);
      //   setIsLoadingEndRefund(false);
      // }
    } catch (error) {
      console.log("error: ", error.message);
      setIsLoadingEndRefund(false);
    }
  };

  const reFundOrder = async (e) => {
    e.preventDefault();
    setIsLoadingRefund(true);
    // console.log("e: ", {
    //   ...newOject,
    //   shippingCompany: selected.name,
    // });
    // setTimeout(() => {
    //   setIsLoadingRefund(false);
    // }, 3000);
    try {
      const refund = await instance.patch(`invoices/${data1._id}`, {
        isRefund: true,
      });
      // if (refund.status === 200) {
      //   const refunde = await instance.get(
      //     `push/refund-payment/${refund.data.orderId}/${(
      //       refund.data.price + 9.000
      //     ).toFixed(2)}`
      //   );
      //   if (refunde.status === 200) {
      //     // console.log("refund: ",refunde);
      //     setTimeout(() => {
      //       setIsRefund(true);
      //       setIsLoadingRefund(false);
      //     }, 1000);
      //   }
      // }
      if (refund.status === 200) {
        setTimeout(() => {
          setIsRefund(true);
          setIsLoadingRefund(false);
        }, 1000);
      }
    } catch (error) {
      console.log("error: ", error.message);
      setIsLoadingRefund(false);
    }
  };

  const cancelOrder = async (e) => {
    e.preventDefault();
    // console.log("kk :");
    setIsLoadingCancel(true);
    // console.log("e: ", {
    //   ...newOject,
    //   shippingCompany: selected.name,
    // });
    // setTimeout(() => {
    //   setIsLoadingCancel(false);
    // }, 3000);
    try {
      const update = await instance.patch(`invoices/${data1._id}`, {
        isCancel: true,
      });
      if (update.status === 200) {
        // console.log("req: ", update);
        // ${update.data.price}
        const refunde = await instance.get(
          `push/refund-payment/${
            update.data.orderId
          }/${update.data.price.toFixed(2)}`,
        );
        if (refunde.status === 200) {
          // console.log("refund: ",refunde);
          setTimeout(() => {
            setIsCancel(true);
            setIsLoadingCancel(false);
          }, 1000);
        }
        setIsCancel(true);
        setIsLoadingCancel(false);
      }
    } catch (error) {
      console.log("error: ", error.message);
      setIsLoadingCancel(false);
    }
  };
  const updateOrder = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log("e: ", {
    //   ...newOject,
    //   shippingCompany: selected.name,
    // });
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 3000);
    try {
      const update = await instance.patch(`invoices/${data1._id}`, {
        ...newOject,
        shippingCompany: "NAEM.ME",
      });
      if (update.status === 200) {
        console.log("req: ", update);

        if (data2?.orders[0]._id == "6488add2feae75d6ff1801d7") {
          setIsLoading(false);
          setIsShipped(true);
        } else {
          sureBuyAdmain();
        }

        setIsUpdate(true);
        // setIsLoading(false);
      }
    } catch (error) {
      console.log("error: ", error.message);
      setIsLoading(false);
    }
  };

  const oneFetch = useRef(true);
  useEffect(() => {
    if (oneFetch.current || loading === false) {
      setCartItems([]);
      localStorage.setItem("Order", JSON.stringify([]));
      localStorage.removeItem("OrderId");
      localStorage.setItem("CountCart", 0);
      oneFetch.current = false;
      console.log("data: ", data2?.orders[0]._id == "6488add2feae75d6ff1801d7");
      // console.log("data d: ", infoDomain);

      // console.log("c: ",  localStorage.getItem("Order") == null);
      // console.log("company: ",  jj);
    }
  }, [loading]);

  const subTotal = (orders) => {
    console.log("data: ", orders);
    return orders
      ?.reduce((price, item) => price + item.qty * item.unit, 0)
      .toFixed(2);
  };

  // const { data, loadingSeconde } = useAxiosSeconde({
  //   method: "GET",
  //   url: `/orders/getOrder/${_id}`,
  //   headers: {
  //     accept: "*/*",
  //   },
  // });

  useEffect(() => {
    // console.log("c: ",  localStorage.getItem("Order") == null);
    setTimeout(() => {
      console.log("info data1: ", data1, data2);
    }, 1500);
  }, [loading]);

  return (
    <>
      {loading ? (
        <div className="flex w-full items-center justify-center py-20">
          <div className="flex items-center justify-center space-x-1 text-sm text-gray-700 rtl:space-x-reverse">
            <svg
              fill="none"
              className="h-20 w-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <main className="bg-white px-2 pb-24 pt-5 sm:px-6 lg:px-8 lg:py-6">
          <div className="sm:mx-auto sm:max-w-3xl">
            <div className="sm:max-w-3xl">
              <div className=" container mx-auto my-5 flex flex-row">
                <button
                  type="button"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  className="flex  h-10 w-10 items-center  justify-center rounded-md bg-indigo-500  text-4xl text-white drop-shadow-lg hover:bg-indigo-700 hover:drop-shadow-2xl"
                  id="btn-back-to-top"
                  onClick={() => {
                    navigate("/orders-merchant");
                    // scrollTop, clientHeight, scrollHeight
                  }}
                >
                  <ChevronRightIcon
                    className="h-6  w-6 ltr:rotate-180 "
                    aria-hidden="true"
                  />
                </button>
                <p
                  htmlFor="url"
                  className="text-md mx-5 mt-2 block font-semibold leading-6 text-gray-900 sm:mt-1 sm:text-xl"
                >
                  معلومات الطلب
                </p>
              </div>
              <div className="flex flex-row justify-between ">
                <div className="mx-0 my-3 flex justify-around  border-green-500 bg-green-100 px-2 py-2 text-xs font-semibold text-green-600 ltr:border-l-4 rtl:border-r-4 sm:w-52 sm:text-sm">
                  <p>{t("itemNo")}:</p>
                  <p>
                    {data1?.orderId !== undefined
                      ? data1.orderId
                      : data1?.itemNo}
                  </p>
                </div>
                <div className="flex flex-row gap-3">
                  {localStorage.getItem("TokenNAEM") != null &&
                  jwt_decode(localStorage.getItem("TokenNAEM")).company
                    ?.superAdmin &&
                  isShipped === false &&
                  data1.isShipped === false ? (
                    <form className="" onSubmit={updateOrder}>
                      <button
                        type="submit"
                        // disabled={newOject.trackingNumber.length === 0}
                        className={classNames(
                          false
                            ? "bg-gray-400  hover:bg-gray-400"
                            : "bg-green-600  hover:bg-green-500 focus:ring-green-600 focus-visible:outline-green-600",
                          "my-3 flex  justify-center rounded-md border px-3  py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2  focus-visible:outline-offset-2 sm:w-36 sm:text-sm ",
                        )}
                      >
                        {isLoading ? (
                          <>
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline h-4 w-4 animate-spin text-white ltr:mr-3 rtl:ml-3"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              />
                            </svg>
                          </>
                        ) : (
                          `${t("orderSure")}`
                        )}
                      </button>
                    </form>
                  ) : null}
                  {localStorage.getItem("TokenNAEM") != null &&
                  jwt_decode(localStorage.getItem("TokenNAEM")).company
                    ?.superAdmin &&
                  data1.isCancel === false &&
                  isCancel === false &&
                  data1.isShipped === false &&
                  isUpdate === false ? (
                    <form onSubmit={cancelOrder}>
                      <button
                        type="submit"
                        // disabled={newOject.trackingNumber.length === 0}
                        className={classNames(
                          false
                            ? "bg-gray-400  hover:bg-gray-400"
                            : "bg-red-600  hover:bg-red-500 focus:ring-red-600 focus-visible:outline-red-600",
                          "my-3 flex justify-center rounded-md border px-3  py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2  focus-visible:outline-offset-2 sm:w-36 sm:text-sm ",
                        )}
                      >
                        {isLoadingCancel ? (
                          <>
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline h-4 w-4 animate-spin text-white ltr:mr-3 rtl:ml-3"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              />
                            </svg>
                          </>
                        ) : (
                          `${t("requestCancelOrder")}`
                        )}
                      </button>
                    </form>
                  ) : (localStorage.getItem("TokenNAEM") != null &&
                      jwt_decode(localStorage.getItem("TokenNAEM")).company
                        ?.superAdmin &&
                      data1.isShipped &&
                      data1?.isRefund === false &&
                      isRefund === false) ||
                    (isUpdate === true && isRefund === false) ? (
                    <form onSubmit={reFundOrder}>
                      <button
                        type="submit"
                        // disabled={newOject.trackingNumber.length === 0}
                        className={classNames(
                          false
                            ? "bg-gray-400  hover:bg-gray-400"
                            : "bg-orange-600  hover:bg-orange-500 focus:ring-orange-600 focus-visible:outline-orange-600",
                          "my-3 flex w-36 justify-center rounded-md border  px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline  focus-visible:outline-2 focus-visible:outline-offset-2 sm:text-sm ",
                        )}
                      >
                        {isLoadingRefund ? (
                          <>
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline h-4 w-4 animate-spin text-white ltr:mr-3 rtl:ml-3"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              />
                            </svg>
                          </>
                        ) : (
                          `${t("requestRefund")}`
                        )}
                      </button>
                    </form>
                  ) : (localStorage.getItem("TokenNAEM") != null &&
                      jwt_decode(localStorage.getItem("TokenNAEM")).company
                        ?.superAdmin &&
                      data1.isEndRefund === false &&
                      isEndRefund === false &&
                      isCancel === false &&
                      data1.isCancel === false) ||
                    (isRefund === true && isEndRefund === false) ? (
                    <form onSubmit={endReFundOrder}>
                      <button
                        type="submit"
                        // disabled={newOject.trackingNumber.length === 0}
                        className={classNames(
                          false
                            ? "bg-gray-400  hover:bg-gray-400"
                            : "bg-gray-600  hover:bg-gray-500 focus:ring-gray-600 focus-visible:outline-gray-600",
                          "my-3 flex w-36 justify-center rounded-md border  px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline  focus-visible:outline-2 focus-visible:outline-offset-2 sm:text-sm ",
                        )}
                      >
                        {isLoadingEndRefund ? (
                          <>
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline h-4 w-4 animate-spin text-white ltr:mr-3 rtl:ml-3"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              />
                            </svg>
                          </>
                        ) : (
                          `${t("completeReturn")}`
                        )}
                      </button>
                    </form>
                  ) : null}
                </div>
              </div>

              <h1 className="text-base font-medium text-indigo-600">
                شكراً لك!
              </h1>
              {(data1?.isRefund &&
                data1?.isEndRefund === false &&
                isEndRefund === false) ||
              (isRefund &&
                data1?.isEndRefund === false &&
                isEndRefund === false &&
                data1?.isEndRefund === false) ? (
                <div className="my-2 flex flex-row">
                  <ArrowPathIcon
                    className="mt-1 h-5 w-5 text-orange-700 ltr:mr-2 rtl:ml-2"
                    aria-hidden="true"
                  />
                  <p className="mt-0.5 text-base text-orange-700 ltr:mr-2 rtl:ml-2">
                    {t("pending1")}
                  </p>
                </div>
              ) : data1?.isEndRefund || isEndRefund ? (
                <div className="my-2 flex flex-row">
                  <XCircleIcon
                    className="mt-1 h-5 w-5 text-red-700 ltr:mr-2 rtl:ml-2"
                    aria-hidden="true"
                  />

                  <p className="mt-0.5 text-base text-red-700 ltr:mr-2 rtl:ml-2">
                    {t("requestRefundTitle")}{" "}
                    {data1?.price.toFixed(2) + " " + t("sar")}
                  </p>
                </div>
              ) : data1?.isCancel || isCancel ? (
                <div className="my-2 flex flex-row">
                  <XCircleIcon
                    className="mt-1 h-5 w-5 text-red-700 ltr:mr-2 rtl:ml-2"
                    aria-hidden="true"
                  />

                  <p className="mt-0.5 text-base text-red-700 ltr:mr-2 rtl:ml-2">
                    {t("requestCancelTitle")}{" "}
                    {data1?.price.toFixed(2) + " " + t("sar")}
                  </p>
                </div>
              ) : data1?.isShipped || isUpdate ? (
                <div className="my-2 flex flex-row">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 text-green-700 ltr:mr-2 rtl:ml-2"
                    aria-hidden="true"
                  />

                  <p className=" mt-0.5 text-base text-green-700 ltr:mr-2 rtl:ml-2">
                    {t("orderSureTitle")}
                  </p>
                </div>
              ) : (
                <>
                  <div className="my-2 flex flex-row">
                    <ArrowPathIcon
                      className="mt-1 h-5 w-5 text-yellow-500 ltr:mr-2 rtl:ml-2"
                      aria-hidden="true"
                    />
                    <p className=" mt-0.5 text-base text-yellow-500 ltr:mr-2 rtl:ml-2">
                      {t("pending")}
                    </p>
                  </div>
                  {/* <form className="my-4" onSubmit={updateOrder}>
                    <Listbox value={selected} onChange={setSelected}>
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                            {t("shippingMethod")}
                          </Listbox.Label>
                          <div className="relative mt-2">
                            <Listbox.Button
                              onChange={(e) =>
                                console.log("e: ", e.target.value)
                              }
                              className="relative w-full cursor-default rounded-md bg-white py-1.5 ltr:pl-3 ltr:pr-10 rtl:pr-3 rtl:pl-10 rtl:text-right ltr:text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <span className="block truncate">
                                {selected.name}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-2 rtl:pl-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {people.map((person) => (
                                  <Listbox.Option
                                    key={person.id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 ltr:pl-3 ltr:pr-9 rtl:pr-3 rtl:pl-9"
                                      )
                                    }
                                    value={person}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {person.name}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-4 rtl:pl-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                    {selected.id !== 3 ? (
                      <div className="my-4">
                        <label
                          htmlFor="trackingNumber"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {t("trackingNumber")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name="trackingNumber"
                            id="trackingNumber"
                            value={newOject.trackingNumber}
                            className="px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="12345"
                            onChange={(e) => handleFields(e)}
                          />
                        </div>
                      </div>
                    ) : null}
                    <button
                      type="submit"
                      disabled={
                        newOject.trackingNumber.length === 0 &&
                        selected.id !== 3
                      }
                      className={classNames(
                        newOject.trackingNumber.length === 0 &&
                          selected.id !== 3
                          ? "bg-gray-400  hover:bg-gray-400"
                          : "focus:ring-indigo-600  bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600",
                        "flex w-full border my-3 justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                      )}
                    >
                      {isLoading ? (
                        <>
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline w-4 h-4 text-white animate-spin rtl:ml-3 ltr:mr-3"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="#E5E7EB"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentColor"
                            />
                          </svg>
                        </>
                      ) : (
                        `${t("orderShipping")}`
                      )}
                    </button>
                  </form> */}
                </>
              )}

              {/*<p className="mt-2 text-2xl sm:text-4xl  font-bold tracking-tight">
                طلبك في الطريق!
              </p>
              <p className="mt-2 text-base text-gray-500">
                طلبك #
                {data1.orderId !== undefined ? data1.orderId : data1.itemNo}{" "}
                سيتم شحنه وسيكون معك قريباً
              </p> */}

              {/* <dl className="mt-4 text-sm font-medium">
                <dt className="text-gray-900">{t("trackingNumber")}</dt>
                {data1.isShipped ? (
                  <div className="flex flex-col w-full items-start ">
                    <button
                      onClick={() =>
                        data1.shippingCompany.trim() === "SMSA"
                          ? window.location.replace(
                              `https://www.smsaexpress.com/sa/ar/trackingdetails?tracknumbers%5B0%5D=${data1.trackingNumber.trim()}`
                            )
                          : data1.shippingCompany.trim() === "ARAMEX"
                          ? window.location.replace(
                              `https://www.aramex.com/sa/ar/track/results?mode=0&ShipmentNumber=${data1.trackingNumber.trim()}`
                            )
                          : ""
                      }
                      className="my-4 text-indigo-600"
                    >
                      {data1.trackingNumber}
                    </button>
                    <span className="flex flex-row">
                      <p className="mt-0.5">{t("trackingLink")} :</p>
                      <ArrowUpOnSquareStackIcon
                        onClick={() =>
                          data1.shippingCompany.trim() === "SMSA"
                            ? window.location.replace(
                                `https://www.smsaexpress.com/sa/ar/trackingdetails?tracknumbers%5B0%5D=${data1.trackingNumber.trim()}`
                              )
                            : data1.shippingCompany.trim() === "ARAMEX"
                            ? window.location.replace(
                                `https://www.aramex.com/sa/ar/track/results?mode=0&ShipmentNumber=${data1.trackingNumber.trim()}`
                              )
                            : ""
                        }
                        className="h-5 w-5 text-indigo-600 mr-2"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                ) : (
                  <dd className="mt-2 text-indigo-600">
                    {t("waitTrackingNumber")}
                  </dd>
                )}
              </dl> */}
            </div>

            <section
              aria-labelledby="order-heading"
              className="mt-5 border-t border-gray-200"
            >
              <h2 id="order-heading" className="sr-only">
                {t("yourOrder")}
              </h2>

              <h3 className="sr-only">Items</h3>
              {data2?.orders != null &&
                data2?.orders.map((order) => (
                  <div
                    key={order?._id}
                    className="flex space-x-6 border-b border-gray-200 py-10 rtl:space-x-reverse"
                  >
                    <img
                      src={
                        order?.image === undefined
                          ? "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png"
                          : order.image.split(",")[0]
                      }
                      alt={"image0"}
                      className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
                    />
                    <div className="flex flex-auto flex-col">
                      <div>
                        <h4 className="font-medium text-gray-900">
                          <a href={order?.href}>{textCutFunc(order?.name)}</a>
                        </h4>
                        <p className="mt-2 text-sm text-gray-600">
                          {order?.description === undefined
                            ? ""
                            : prographCutFunc(order?.description)}
                        </p>
                      </div>
                      <div className="mt-6 flex flex-1 items-end ">
                        <dl className="flex space-x-4 divide-gray-200 text-sm ltr:divide-x rtl:space-x-reverse sm:space-x-6">
                          <div className="flex">
                            <dt className="font-medium text-gray-900">
                              {t("quantity")}
                            </dt>
                            <dd className="text-gray-700 ltr:ml-2 rtl:mr-2">
                              {order?.qty}
                            </dd>
                          </div>
                          <div className="flex ltr:pl-4 rtl:border-r rtl:pr-4   ltr:sm:pl-6 rtl:sm:pr-6 ">
                            {order?._id === "644c3918d64ebf3a0cda5d51" ? (
                              "مجاناً"
                            ) : (
                              <>
                                <dt className="font-medium text-gray-900">
                                  {t("price")}
                                </dt>
                                <dd className="text-gray-700 ltr:ml-2 rtl:mr-2">
                                  {order?.unit.toFixed(2) + " ر.س "}
                                </dd>
                              </>
                            )}
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                ))}

              <div className="ltr:sm:ml-40 ltr:sm:pl-6 rtl:sm:mr-40 rtl:sm:pr-6 ">
                <h3 className="sr-only">معلوماتك</h3>

                <h4 className="sr-only">العنوان</h4>
                {/* <dl className="grid grid-cols-2 gap-x-6 py-10 text-sm">
                  <div>
                    <dt className="font-medium text-gray-900">
                      {t("shippingAddress")}
                    </dt>
                    <dd className="mt-2 text-gray-700">
                      <address className="not-italic">
                        <span className="block">{data3?.city}</span>
                        <span className="block">{data3?.neighborhood}</span>
                        <span className="block">{data3?.address}</span>
                      </address>
                    </dd>
                  </div>
                  <div>
                    <dt className="font-medium text-gray-900">
                      {t("billingAddress")}
                    </dt>
                    <dd className="mt-2 text-gray-700">
                      <address className="not-italic">
                        <span className="block">{data3?.city}</span>
                        <span className="block">{data3?.neighborhood}</span>
                        <span className="block">{data3?.address}</span>
                      </address>
                    </dd>
                  </div>
                </dl> */}

                <h4 className="sr-only">الدفع</h4>
                <dl className="grid grid-cols-2 gap-x-6 border-t border-gray-200 py-10 text-sm">
                  <div>
                    <dt className="font-medium text-gray-900">
                      {t("PaymentMethod")}
                    </dt>
                    <div className="mt-2 flex flex-row text-gray-700">
                      {data1?.paymentType !== "CARD" ? (
                        <img
                          src={
                            "https://fatora-sa.herokuapp.com/api/v1/products/1681458043373-fatora-cashier-Apple_Pay_Mark_RGB_041619.svg"
                          }
                          alt=""
                          className="mt-0.5 block h-4 w-auto flex-shrink-0 ltr:mr-2 rtl:ml-2"
                        />
                      ) : (
                        <CreditCardIcon
                          className=" h-5 w-5 ltr:mr-2 rtl:ml-2"
                          aria-hidden="true"
                        />
                      )}
                      <p>{data1?.paymentType}</p>
                      {/* <p>Mastercard</p>
                    <p>
                      <span aria-hidden="true">••••</span>
                      <span className="sr-only">Ending in </span>1545
                    </p> */}
                    </div>
                  </div>
                  {/* <div>
                    <dt className="font-medium text-gray-900">
                      {t("shippingMethod")}
                    </dt>
                    <dd className="mt-2 text-gray-700">
                      <p>
                        {data1.shippingCompany
                          ? data1.shippingCompany
                          : `${t("waitRackingNumber")}`}
                      </p>
                      <p>{t("limitShipping")}</p>
                    </dd>
                  </div> */}
                </dl>

                <h3 className="sr-only">Summary</h3>

                <dl className="space-y-6 border-t border-gray-200 pt-10 text-sm">
                  <div className="flex justify-between">
                    <dt className="flex  flex-row  gap-2 font-medium text-gray-900">
                      رسوم الخدمة{" "}
                      <InformationCircleIcon
                        className="h-5 w-5 text-gray-400 hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd className="text-gray-700">
                      {data2?.orders[0]._id === "6488add2feae75d6ff1801d7"
                        ? "00.00" + t("sar")
                        : 9.99 + " " + t("sar")}
                    </dd>
                  </div>
                  <div className="flex justify-between">
                    <dt className="font-medium text-gray-900">
                      {t("subTotal")}
                    </dt>
                    <dd className="text-gray-700">
                      {data1?.price + " " + t("sar")}
                      {/* {subTotal(data2?.orders) + " " + t("sar")} */}
                    </dd>
                  </div>
                  {/* <div className="flex justify-between">
                <dt className="flex font-medium text-gray-900">
                  Discount
                  <span className="ltr:ml-2 rtl:mr-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-600">
                    STUDENT50
                  </span>
                </dt>
                <dd className="text-gray-700">-$18.00 (50%)</dd>
              </div> */}
                  {/* <div className="flex justify-between">
                    <dt className="font-medium text-gray-900">
                      {t("shippingCost")}
                    </dt>
                    <dd className="text-gray-700">
                      {(9.000).toFixed(2) + " " + t("sar")}
                    </dd>
                  </div> */}
                  <div className="flex justify-between">
                    <dt className="font-medium text-gray-900">
                      {" "}
                      {t("orderTotal")}
                    </dt>
                    <dd className="text-gray-900">
                      {data1?.price.toFixed(2) + " " + t("sar")}
                    </dd>
                  </div>
                </dl>
              </div>
            </section>
          </div>
        </main>
      )}
    </>
  );
}
