import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import instance from "../api/api";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/20/solid";

import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AlertBuy({ alertBuy, setAlertBuy, active,i18n }) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={alertBuy} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setAlertBuy}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-3000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-start my-20 justify-center p-3 text-center sm:items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              //   enter="ease-out duration-300"
              //   enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              //   enterTo="opacity-100 translate-y-0 sm:scale-100"
              //   leave="ease-in duration-200"
              //   leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              //   leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter="transform ease-out duration-500 transition"
              enterFrom="translate-y-0 opacity-100 sm:translate-x-0"
              enterTo="opacity-100 translate-y-2 translate-x-0"
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-0 pb-4 pt-0 rtl:text-right ltr:text-left shadow-xl transition-all sm:my-8 sm:max-w-2xl  sm:p-6 w-full">
                <div>
                  <div className="mt-0 text-center sm:mt-2">
                    <div className="mt-0 mb-4 w-full text-right">
                      <div>
                        <div className="flex flex-row justify-between w-full bg-red-200 sm:rounded-md sm:mx-auto">
                          <p
                            htmlFor="url"
                            className="block text-sm font-medium leading-6 text-gray-600  px-2 py-2"
                          >
                            تنبيه
                          </p>
                          <XMarkIcon
                            onClick={() => {
                              setAlertBuy(false);
                              //   setNewObject({ url: "" });
                            }}
                            className=" h-5 w-5 text-gray-600 mt-2.5 mx-4"
                            aria-hidden="true"
                          />
                        </div>

                        {active === false && (
                          <div className="ltr:border-l-4 rtl:border-r-4 border-yellow-400 bg-yellow-50 p-4 my-3">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <ExclamationTriangleIcon
                                  className="h-5 w-5 text-yellow-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="text-xs md:text-md ltr:ml-3 rtl:mr-3 flex flex-row justify-between w-full">
                                <p className=" text-yellow-700">
                                  هذه الخدمة متوفرة على باقة الإنطلاقة
                                </p>
                                <a
                                  href="/pricing"
                                  className="font-medium text-yellow-700 underline hover:text-yellow-600 outline-none"
                                >
                                  ترقية الباقة
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
