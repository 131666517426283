import ProductView from "./ProductView";
import { useNavigate, useParams } from "react-router-dom";
import instance, { useAxios, useAxiosSeconde } from "../api/api";
import { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Context } from "../context/ContextProvider";
import jwt_decode from "jwt-decode";

import {
  Dialog,
  Popover,
  Tab,
  Transition,
  Disclosure,
} from "@headlessui/react";
import {
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  GlobeAltIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import NavDisClosure from "../components/NavDisClosure";
import Notifications from "../components/Notifications";
import {
  ArrowUturnRightIcon,
  EyeDropperIcon,
  ShoppingCartIcon,
} from "@heroicons/react/20/solid";
import { PencilSquareIcon, PlusIcon } from "@heroicons/react/24/solid";
import AlertMerchantAuth from "../components/AlertMerchantAuth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductList({ t }) {
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    setCompany,
    show,
    setShow,
    company,
    cartOrdersId,
    setCartOrdersId,
  } = useContext(Context);

  // useEffect(() => {
  //   console.log(
  //     "error: ",
  //     // localStorage.getItem("TokenNAEM") != null,
  //     // jwt_decode(localStorage.getItem("TokenNAEM"))?.company?.companyName ===
  //     //   localStorage.getItem("CN"),
  //     // company?.companyName === localStorage.getItem("CN")
  //   );
  // }, []);

  const navigate = useNavigate();
  const { companyName } = useParams();
  // const jfalds = useParams();
  const [limit, setLimit] = useState(10);

  // const [categories, setCategories] = useState([]);
  const [select, setSelect] = useState("all");
  // const [items, setItems] = useState([]);
  const [value, setValue] = useState(0);

  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingBottom, setLoadingBottom] = useState(false);

  useEffect(() => {
    // if (oneFetch.current || loading) {
    const fetchData = async () => {
      setLoadingBottom(true);
      // oneFetch.current = false;
      // setLoading(false);
      try {
        const response = await instance.get(
          `/companies/company-limits?companyUrl=${localStorage.getItem(
            "CN",
          )}&limit=${limit}`,
        );
        setResponse(response.data);
        setItems(response.data);
        // console.log("response: " + JSON.stringify(response.data));

        setLoading(false);
        setLoadingBottom(false);
      } catch (error) {
        console.log("error:  ", error.message);
      }
    };
    fetchData();
    // }
  }, [limit]);

  // const { response, loading, error } = useAxios({
  //   method: "GET",
  //   url: `/companies/company-limits/${companyName}`,
  //   headers: {
  //     accept: "*/*",
  //   },
  // });

  const { data, loadingSeconde, errorSeconde } = useAxiosSeconde({
    method: "GET",
    url: `/companies/get-company-name/${localStorage.getItem("CN")}`,
    headers: {
      accept: "*/*",
    },
  });

  // if (loadingSeconde) {

  //   console.log("here ...");
  // }

  const [isLoading, setIsLoading] = useState(true);
  const oneFetch = useRef(true);
  useEffect(() => {
    if (oneFetch.current || loading === false) {
      localStorage.getItem("Order") != null &&
        setCartItems(JSON.parse(localStorage.getItem("Order")));
      oneFetch.current = false;

      setCategories([
        "all",
        ...new Set(
          response
            ?.map((item) => item.category)
            .filter((category) => category.trim() !== ""),
        ),
      ]);

      filterItems("all");
      setTimeout(() => {
        setCompany(data);
        setIsLoading(false);
      }, 100);
    }
  }, [loading]);

  const handleNotification = () => {
    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 500);
  };

  const textCutFunc = (text) =>
    `${text
      .trim()
      .split(" ")
      .splice(0, text?.length > 25 ? 3 : 4)
      .join(" ")}  ${text?.length > 25 ? "..." : ""}`;

  const filterItems = (category) => {
    if (category === "all") {
      setItems(response);
      setSelect("all");
      return;
    }

    const newItems = response.filter((item) => item.category === category);

    if (newItems.length > 0) {
      setSelect(newItems[0].category);
      setItems(newItems);
    } else if (items.length < 30) {
    } else {
    }
  };

  const handleChange = (newValue) => {
    console.log("new: ", newValue.target.value);
    // setValue(newValue.target.value);
    // console.log("new Value: ", categories[newValue]);

    filterItems(categories[newValue.target.value]);
  };

  const [smallHeight, setSmallHeight] = useState(true);

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 100 || smallHeight == false) {
      // console.log("limit: ", limit);
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const onScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    toggleVisibility();
    if (event) {
      const computedScrollHeight = Math.round(scrollTop) + clientHeight;

      if (
        scrollHeight >= computedScrollHeight - 1 &&
        scrollHeight <= computedScrollHeight + 1
      ) {
        // console.log(
        //   "Reached Bottom",
        //   scrollTop,
        //   clientHeight,
        //   computedScrollHeight,
        //   // index
        //   limit
        // );

        // setIsLoading(true)
        // setLoading(true)
        // if (items.length < limit) {
        // }
        setTimeout(() => {
          setLimit(limit + 10);
        }, 2000);
      }
      if (scrollTop === 0) setSmallHeight(true);
    }
  };

  const [open, setOpen] = useState(false);

  return (
    <div
      onScroll={onScroll}
      className={classNames(
        smallHeight ? "h-screen" : "",
        "overflow-y-auto overflow-x-hidden scroll-smooth bg-white",
      )}
    >
      <Notifications title={t("addedToCart")} />
      <AlertMerchantAuth open={open} setOpen={setOpen} />
      {loading ? (
        <div className="flex items-center justify-center py-20">
          <div className="flex items-center justify-center space-x-1 space-x-reverse text-sm text-gray-700">
            <svg
              fill="none"
              className="h-20 w-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <>
          {response?.length > 0 ? (
            <header className="relative mx-auto max-w-2xl bg-white md:max-w-7xl md:px-4 lg:px-8">
              <nav aria-label="Top" className="flex justify-start ">
                <div className="flex h-16 ">
                  {/* Flyout menus */}

                  <div className="absolute inset-x-0 bottom-0 ltr:mr-10 rtl:ml-10 sm:static sm:hidden sm:flex-1 sm:self-stretch ">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                      id="tabs"
                      name="tabs"
                      className="mx-5 block w-full rounded-md border border-gray-300 px-3 py-2 pl-5 text-base hover:bg-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={
                        categories?.find((tab) => tab?.current) || "default"
                      }
                      // value={categoryIdx}
                      onChange={(e) => handleChange(e)}
                    >
                      {categories?.map((tab, index) => (
                        <option key={index} value={index}>
                          {tab === "all" ? `${t("all")}` : tab}
                        </option>
                      ))}
                    </select>
                  </div>
                  <Popover.Group className="absolute inset-x-0 bottom-0  hidden flex-1 self-stretch sm:block">
                    <div className="flex h-16 space-x-3 overflow-x-auto px-3 pb-px rtl:space-x-reverse xl:px-4 ">
                      {categories?.map((category, categoryIdx) => (
                        <Popover key={categoryIdx} className="flex">
                          {({ open }) => (
                            <>
                              <div className="relative flex">
                                <Popover.Button
                                  // onClick={}
                                  value={categoryIdx}
                                  // defaultValue={
                                  //   categories[0]
                                  // }
                                  onClick={(e) => handleChange(e)}
                                  className={classNames(
                                    open || categories[categoryIdx] === select
                                      ? "border-black text-black"
                                      : " text-bold border-gray-300 text-gray-500 hover:text-gray-800",
                                    "z-8 relative  -mb-px mt-3 flex items-center border-b-4 pt-px text-sm font-medium transition-colors duration-200 ease-out hover:rounded hover:bg-gray-200  focus:outline-0 md:px-4",
                                  )}
                                >
                                  {category === "all"
                                    ? `${t("all")}`
                                    : category}
                                </Popover.Button>
                              </div>
                            </>
                          )}
                        </Popover>
                      ))}
                    </div>
                  </Popover.Group>
                </div>
              </nav>
            </header>
          ) : (
            <div className="flex flex-row justify-between rounded bg-gray-200 p-3 px-5">
              <h1 className="text-1xl mt-0.5 font-bold tracking-tight text-gray-900 sm:text-xl">
                لا توجد منتجات في المتجر :(
              </h1>
            </div>
          )}
          <div className="mx-auto max-w-2xl px-6 py-6 sm:py-6 lg:max-w-7xl lg:px-8">
            {response?.length > 0 ? (
              <div className="flex flex-row justify-between">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">
                  {t("best")}
                </h2>
                <div
                  onClick={() => navigate("/edite-product")}
                  className="flex flex-row rounded-md border-2 border-indigo-500 px-3  py-1 tracking-tight text-indigo-500 hover:border-transparent hover:bg-indigo-500 hover:text-white"
                >
                  <PlusIcon
                    className=" mt-0.5 h-5 w-5 ltr:mr-1 rtl:ml-1 "
                    aria-hidden="true"
                  />
                  <button className="text-md ltr:mr-1 rtl:ml-1">
                    {t("addProduct")}
                  </button>
                </div>
              </div>
            ) : (
              <div
                onClick={() =>
                  data?.merchantState === "ready"
                    ? navigate("/edite-product")
                    : setOpen(true)
                }
                className="flex flex-row justify-center rounded-md border-2 border-indigo-500 px-3  py-1 tracking-tight text-indigo-500 hover:border-transparent hover:bg-indigo-500 hover:text-white"
              >
                <PlusIcon
                  className=" mt-0.5 h-5 w-5 ltr:mr-1 rtl:ml-1 "
                  aria-hidden="true"
                />
                <button className="text-md ltr:mr-1 rtl:ml-1">
                  {t("addProduct")}
                </button>
              </div>
            )}

            <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-3 md:gap-y-6 lg:gap-x-8 xl:grid-cols-4">
              {items?.map((product) => (
                <div key={product._id} className="group relative">
                  <button
                    onClick={() => navigate(`/detail/${product._id}`)}
                    className="text-start"
                  >
                    <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                      <img
                        src={
                          product.image.includes(",")
                            ? product.image.split(",")[0]
                            : product.image
                        }
                        alt={product.imageAlt}
                        // className="h-full w-full object-cover object-center"
                        className={classNames(
                          isLoading ? "w-full animate-pulse" : "",
                          "h-full w-full object-cover object-center",
                        )}
                        style={{
                          animationFillMode: "backwards",
                          animationDelay: "150ms",
                        }}
                      />
                    </div>
                    <h3
                      className={classNames(
                        isLoading ? "w-full animate-pulse" : "",
                        "mt-4 text-sm text-gray-700",
                      )}
                    >
                      <a href={product.href}>
                        <span className="absolute inset-0" />
                        {product.name ? textCutFunc(product.name) : ""}
                      </a>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {product.marker}
                    </p>
                    <div className="flex flex-row">
                      <p className="text-md mt-1 font-medium text-green-600 ltr:mr-2 rtl:ml-2">
                        {product.unit
                          ? product.unit?.toFixed(2) + " " + t("sar")
                          : ""}
                      </p>
                      <p className="text-md mt-1 font-medium text-red-600 line-through">
                        {product.basicPrice
                          ? product.basicPrice?.toFixed(2) + " " + t("sar")
                          : ""}
                      </p>
                    </div>
                  </button>

                  <div className="mt-6 flex w-full flex-row  gap-1">
                    <button
                      onClick={() => {
                        // setShow(false);
                        // handleAddProduct(product) & (cartItmes?.length > 0) &&
                        //   localStorage.setItem(
                        //     "Order",
                        //     JSON.stringify(cartItmes)
                        //   );
                        // handleNotification();
                        navigate(`/edite-product`, {
                          state: {
                            product: product,
                            //...values
                          },
                        });
                      }}
                      className="relative flex h-8 w-full flex-row items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-xs text-gray-900 hover:bg-gray-200 focus:bg-indigo-600 focus:text-white"
                    >
                      <PencilSquareIcon
                        className="absolute mb-2 mt-1 h-4 lg:left-0 lg:ml-10 lg:rtl:right-0 lg:rtl:mr-10"
                        aria-hidden="true"
                      />
                      <div className="hidden h-4 lg:flex">
                        {t("editeProduct")}
                      </div>
                    </button>
                    <button
                      onClick={() => {
                        setShow(false);
                        handleAddProduct(product) & (cartItmes?.length > 0) &&
                          localStorage.setItem(
                            "Order",
                            JSON.stringify(cartItmes),
                          );
                        handleNotification();
                        // navigate(`/edite-product`, {
                        //   state: {
                        //     product: product,
                        //     //...values
                        //   },
                        // });
                      }}
                      className="relative flex w-full items-center justify-center rounded-md  border border-transparent bg-green-100 px-8 py-2 text-sm font-medium text-gray-900 hover:bg-green-300 focus:bg-green-600 focus:text-white lg:w-10"
                    >
                      <ShoppingBagIcon
                        className="absolute mb-2 mt-1 h-4"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              ))}

              {loadingBottom && (
                <div className="flex items-center justify-center py-20">
                  <div className="flex items-center justify-center space-x-1 space-x-reverse text-sm text-gray-700">
                    <svg
                      fill="none"
                      className="h-20 w-20 animate-spin"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clipRule="evenodd"
                        d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                        fill="currentColor"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>

            {/* <div className="mt-8 text-sm md:hidden">
          <a
            href="dd"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Shop the collection
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div> */}
          </div>
        </>
      )}

      <div className="h-15 flex w-full  justify-end pb-10 pl-10">
        <button
          type="button"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className={classNames(
            isVisible ? " opacity-100" : "opacity-0",
            // " inline-block p-3 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out bottom-5 right-5"
            "z-90 fixed bottom-20 flex h-16 w-16  items-center  justify-center rounded-full bg-blue-600 text-4xl text-white drop-shadow-lg hover:bg-blue-700 hover:drop-shadow-2xl ltr:right-8 rtl:left-8",
          )}
          id="btn-back-to-top"
          onClick={() => {
            setSmallHeight(false);

            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });

            // scrollTop, clientHeight, scrollHeight
          }}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            className="h-5 w-5"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
}
