import React, { useContext, useState } from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import instance, { useAxios, useNaemApi } from "../api/api";
import { Context } from "../context/ContextProvider";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  ShoppingBagIcon,
  BanknotesIcon,
  PresentationChartLineIcon,
  ShoppingCartIcon,
  UsersIcon,
  GlobeAltIcon,
  UserIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
// import { useAxiosSeconde, useNaemApi } from "../api/api";
import { useAxiosDublecate } from "../api/api";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Settings({ t, i18n }) {
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    setCompany,
    show,
    setShow,
  } = useContext(Context);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [people, setPeople] = useState([
    { domainName: "Amira Alexander", purchasePrice: 22.22 },
  ]);
  const [newOject, setNewObject] = useState({
    url: "",
  });

  const { naemData, errorNaem, loadingNaem } = useNaemApi({
    method: "GET",
    url: `/companies/company-limits?companyUrl=naem&limit=20`,
    headers: {
      // no need to stringify
      accept: "*/*",
    },
  });

  const handleFields = (e) => {
    setNewObject({ ...newOject, [e.target.name]: e.target.value });
  };

  const searchFunc = async () => {
    setIsLoading(true);
    try {
      const d = await instance.post("/push/search", {
        search: newOject.url,
      });
      console.log("data: ", d.data.results);
      setPeople(d.data.results);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error: ", error.message);
    }
  };

  const addToCart = (key) => {
    console.log("key: ", naemData[0]);
    if (loadingNaem === false) {
      handleAddProduct(naemData[0]) & (cartItmes?.length > 0) &&
        localStorage.setItem("Order", JSON.stringify(cartItmes));
    }
  };

  // const { data1, data2, loading, error } = useAxiosDublecate(
  //   "",
  //   {
  //     method: "GET",
  //     url: `/companies/get-company-name/${
  //       localStorage.getItem("CN") !== null && localStorage.getItem("CN")
  //     }`,
  //     headers: {
  //       accept: "*/*",
  //     },
  //   },
  //   {
  //     method: "GET",
  //     url: "/invoices/get-casheir-invoices/",
  //     headers: {
  //       accept: "*/*",
  //     },
  //   }
  // );

  const { response, loading, error } = useAxios({
    method: "GET",
    // url: `/companies/get-company-name/ater`,
    url: `/companies/get-company-name/${localStorage.getItem("CN")}`,
    headers: {
      accept: "*/*",
    },
  });

  const stats = [
    {
      id: 1,
      name: "معلومات المتجر",
      stat: "d",
      icon: UserIcon,
      link: "profile",

      //   change: "5.4%",
      //   changeType: "increase",
    },
    {
      id: 2,
      name: "إعدادات الدومين",
      stat: "jkjfs",
      icon: GlobeAltIcon,
      link: "domain",
      //   change: "122",
      //   changeType: "increase",
    },
    {
      id: 3,
      name: "مشتريات المتجر",
      stat: "fd",
      icon: ShoppingBagIcon,
      link: "orders-merchant",
    },
    {
      id: 4,
      name: "المتاجر",
      stat: "dfs",
      icon: BanknotesIcon,
      link: "merchants",
    },
  ];

  return (
    <div className="mx-5 mt-0 sm:container sm:mx-auto">
      <div className="my-4">
        {loading ? (
          <div className="flex w-full items-center justify-center py-20">
            <div className="flex items-center justify-center space-x-1 text-sm text-gray-700">
              <svg
                fill="none"
                className="h-20 w-20 animate-spin"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                  fill="currentColor"
                  fillRule="evenodd"
                />
              </svg>
            </div>
          </div>
        ) : (
          <>
            {response?.active ? (
              <div className="my-4 border-green-400 bg-green-50 p-4 ltr:border-l-4 rtl:border-r-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ltr:ml-3 rtl:mr-3">
                    <h3 className="md:text-md text-xs font-medium text-green-800 outline-none">
                      أنت الآن على الباقة المميزة
                    </h3>
                  </div>
                </div>
              </div>
            ) : (
              <div className="my-4 border-blue-400 bg-blue-50 p-4 ltr:border-l-4 rtl:border-r-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex w-full flex-row justify-between ltr:ml-3 rtl:mr-3">
                    <p className="md:text-md text-xs  text-blue-700">
                      أنت الآن على الباقة الأساسية
                    </p>
                    <a
                      href="/pricing"
                      className="md:text-md text-xs font-medium text-blue-700 underline outline-none hover:text-blue-600"
                    >
                      ترقية الباقة
                    </a>
                  </div>
                </div>
              </div>
            )}

            <dl className=" mt-0 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
              {stats
                .filter((c) => (response?.superAdmin ? c.id !== 3 : c.id !== 4))
                .map((item) => (
                  <div
                    key={item.id}
                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                  >
                    <dt className="flex flex-row justify-start gap-3">
                      <div className="w-12 rounded-md bg-indigo-500 p-3">
                        <item.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="  text-md mt-3.5 w-full font-bold  text-gray-500">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-0 flex items-baseline pb-6 ltr:ml-16 rtl:mr-16 sm:pb-7">
                      <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                        <div className="text-sm">
                          <a
                            href={`/${item.link}`}
                            className="font-medium text-indigo-600 hover:text-indigo-500 focus:underline"
                          >
                            {t("viewMore")}{" "}
                            <span className="sr-only"> {item.name} stats</span>
                          </a>
                        </div>
                      </div>
                    </dd>
                  </div>
                ))}
            </dl>
          </>
        )}
      </div>
    </div>
  );
}

export default Settings;
