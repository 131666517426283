import React, { useState } from "react";

const Pagination = ({ ordersPerPage, totalOrders, paginate,t }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalOrders / ordersPerPage); i++) {
    pageNumbers.push(i);
  }
  const [count, setCount] = useState(1);
  const changePlus = () => {
    if (pageNumbers.length > count) {
      setCount((c) => c + 1);
      paginate(count + 1);
    }
  };
  const changeRemove = () => {
    if (count > 1) {
      setCount((c) => c - 1);
      paginate(count - 1);
    }
  };

  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
        {t("showing")} <span className="font-medium">{count === 1 ? count : count * ordersPerPage - ordersPerPage }</span> {t("to")} {" "}
          <span className="font-medium">
          {" "} {pageNumbers.length === count ? totalOrders  :count === 1 ? ordersPerPage : count * ordersPerPage}
          {" "} {t("of")} {" "}
          </span>
          <span className="font-medium">{totalOrders}</span>{" "} {t("results")}
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <button
          onClick={changeRemove}
          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
        >
          {t("previous")}
        </button>
        <button
          className="relative ltr:ml-3 rtl:mr-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          onClick={changePlus}
        >
           {t("next")}
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
