/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, {
  useEffect,
  useState,
  useContext,
  useInsertionEffect,
} from "react";
import { useAxios } from "../api/api";
import instance from "../api/api";
import jwt_decode from "jwt-decode";
import { useParams, useNavigate } from "react-router-dom";
import ForgetPassword from "../components/ForgetPassword";
import { Context } from "../context/ContextProvider";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Login() {
  const navigate = useNavigate();

  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    setCompany,
    show,
    setShow,
  } = useContext(Context);

  // useEffect(() => {
  //   console.log(
  //     "jj: ",
  //     jwt_decode(localStorage.getItem("TokenNAEM")).company.companyName ==
  //       localStorage.getItem("CN")
  //   );
  // }, [1000]);

  // const { response } = useAxios({
  //   method: "GET",
  //   url: `/companies/get-company-name/${localStorage.getItem("CN")}`,
  //   headers: {
  //     accept: "*/*",
  //   },
  // });
  const [open, setOpen] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [statusError, setStatusError] = useState("");
  const [handleError, setHandleError] = useState("");
  const [newOject, setNewObject] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleFields = (e) => {
    setHandleError("");
    setNewObject({ ...newOject, [e.target.name]: e.target.value });
  };

  const formLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const email = newOject.email;
    const password = newOject.password;

    try {
      const res = await instance.post(`/companies/login`, {
        email,
        password,
      });
      if (res) {
        localStorage.setItem("TokenNAEM", res.data.accessToken);
        const data = jwt_decode(res.data.accessToken);
        console.log("data: " + JSON.stringify(data));
        // console.log("data one: ", Object.keys(data.company));
        // console.log("data: ", Object.keys(data.company).includes("url"));
        // // localStorage.setItem("CN", data.company.companyName);
        // // window.location.host.includes("localhost:3000")
        // //   ? localStorage.setItem("CN", data.company.url)
        // //   :

        if (
          Object.keys(data.company).includes("url") &&
          Object.keys(data.company).includes("companyUrl")
        ) {
          data.company.url.includes("naem.me")
            ? localStorage.setItem("CN", data.company.url.split(".me/")[1])
            : data.company.url.split(".").length > 2
              ? localStorage.setItem("CN", data.company.url.split(".")[1])
              : localStorage.setItem("CN", data.company.url.split(".")[0]);

          setCompany(data);
          setTimeout(() => {
            setNewObject({ email: "", password: "" });
            setIsLoading(false);
            navigate(`/`);
          }, 1000);
        } else {
          navigate(`/merchant-id/0`);
        }
      }
    } catch (error) {
      // console.log("error", typeof error.response.status);
      setStatusError(error.response.status);
      setHandleError(error.response.data.error);
      setIsLoading(false);
      setNewObject({ email: "", password: "" });
    }
  };

  function handleEnter(field, event) {
    console.log("field: ", field);
    var keyCode = event.keyCode
      ? event.keyCode
      : event.which
        ? event.which
        : event.charCode;
    if (keyCode == 13) {
      var i;
      for (i = 0; i < field.form.elements.length; i++)
        if (field == field.form.elements[i]) break;
      i = (i + 1) % field.form.elements.length;
      field.form.elements[i].focus();
      return false;
    } else return true;
  }

  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-gray-50">
          <body class="h-full">
          ```
        */}
      <div className="container mx-auto my-20 flex flex-col justify-center sm:px-6 lg:px-8">
        <ForgetPassword
          open={open}
          setOpen={setOpen}
          isSend={isSend}
          setIsSend={setIsSend}
        />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto rounded"
            src={require("../images/logo512.png")}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            مرحبا بك
          </h2>
          {/* <p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                start your 14-day free trial
              </a>
            </p> */}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full">
          <form
            className="space-y-6"
            // action="#"
            // method="POST"
            onSubmit={formLogin}
          >
            <div className="mx-5 bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
              <div>
                <label
                  htmlFor="email"
                  className="mt-5 block text-sm font-medium leading-6 text-gray-900"
                >
                  البريد الإلكتروني
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={newOject.email}
                    onChange={(e) => handleFields(e)}
                    autoComplete="email"
                    required
                    // onkeydown={()=>handleKeyDown()}
                    // onkeypress={(event) => handleEnter(this, event)}
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                  {statusError === 404 && handleError?.length > 0 && (
                    <span className="mt-1 flex items-center  text-xs font-medium tracking-wide text-red-500 ltr:ml-1 rtl:mr-1 ">
                      {handleError}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="mt-5 block text-sm font-medium leading-6 text-gray-900"
                >
                  كلمة المرور
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={newOject.password}
                    onChange={(e) => handleFields(e)}
                    autoComplete="current-password"
                    required
                    // onkeypress="return handleEnter(this, event)"
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                  {statusError === 401 && handleError?.length > 0 && (
                    <span className="mt-1 flex items-center  text-xs font-medium tracking-wide text-red-500 ltr:ml-1 rtl:mr-1 ">
                      {handleError}
                    </span>
                  )}
                </div>
              </div>

              <div className="mt-5 flex items-center justify-between">
                <div className="flex items-center text-sm">
                  <button
                    type="button"
                    onClick={() => setOpen(true) & setIsSend(false)}
                    className=" text-red-600 outline-none hover:text-red-500"
                  >
                    نسيت كلمة المرور ؟
                  </button>
                </div>

                <div className="text-sm">
                  <a
                    href="/register"
                    className=" text-indigo-600 hover:text-indigo-500"
                  >
                    ليس لديك حساب ؟
                  </a>
                </div>
              </div>
              <div className="bg-red-400 text-red-400"></div>

              <div>
                <button
                  type="submit"
                  disabled={
                    newOject.password.length <= 5 || newOject.email.length === 0
                  }
                  className={classNames(
                    newOject.password.length <= 5 || newOject.email.length === 0
                      ? "bg-gray-400  hover:bg-gray-400"
                      : "bg-indigo-600  hover:bg-indigo-500 focus:ring-indigo-600 focus-visible:outline-indigo-600",
                    "my-3 flex w-full justify-center rounded-md border  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ",
                  )}
                >
                  {isLoading ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline h-4 w-4 animate-spin text-white ltr:mr-3 rtl:ml-3"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    "تسجيل دخول"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
