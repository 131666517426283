import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import i18n from "../i18n/i18n";
import {
  CheckIcon,
  ChevronUpDownIcon,
  MinusCircleIcon,
  PhotoIcon,
  PlusCircleIcon,
  PaperClipIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import jwt_decode from "jwt-decode";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { Listbox, Tab, Transition } from "@headlessui/react";
import { Context } from "../context/ContextProvider";
import instance, { useAxios } from "../api/api";
import axios from "axios";
import AddNewCategory from "../components/AddNewCategory";
import AddNewColor from "../components/AddNewColor";
import { SketchPicker, SwatchesPicker } from "react-color";
import AddNewSize from "../components/AddNewSize";
import AddColor from "../components/AddColor";
import AlertEditProduct from "../components/AlertEditProduct";
import {
  ClipboardDocumentCheckIcon,
  DocumentDuplicateIcon,
  ExclamationTriangleIcon,
  KeyIcon,
} from "@heroicons/react/24/outline";

import AlertImageAuthMerchant from "../components/AlertImageAuthMerchant";
import Notifications from "../components/Notifications";
import AlertDigitalProduct from "../components/AlertDigitalProduct";
import Branded from "../components/Branded";
import EditorComponent from "../components/EditorComponents";

import exampleData from "../data/exampleData";
import Editor from "../components/Editor";
import AlertDigitalCard from "../components/AlertDigitalCard";

function classNames(...classes) {
  return classes?.filter(Boolean).join(" ");
}

const people = [
  { id: 1, name: "SMSA" },
  { id: 2, name: "ARAMIX" },
];

let req;

const LIST = [
  "XXS",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "30ML",
  "50ML",
  "100ML",
  "150ML",
  "300ML",
  "200ML",
  "75ML",
  "125ML",
];

function EditeProduct({ t }) {
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    setCompany,
    show,
    setShow,
    select,
    selectedBranded,
    setSelectedBranded,
  } = useContext(Context);
  const navigate = useNavigate();

  const [backgroundColor, setBackgroundColor] = useState("#fff");

  const handleChangeComplete = (color) => {
    setBackgroundColor(color.hex);
  };
  //   const history = useHistory();

  const [open, setOpen] = useState(false);
  const [openAddNewColor, setOpenAddNewColor] = useState(false);
  const [openAddColor, setOpenAddColor] = useState(false);
  const [openAddNewSize, setOpenAddNewSize] = useState(false);
  // const [colors, setColors] = useState([]);
  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);

  const [isSend, setIsSend] = useState(false);

  const { state } = useLocation();
  // useEffect(() => {
  //   console.log("state: ", select);
  // }, []);

  const [currentCards, setCurrentCards] = useState([]);

  const [newDigitalProductData, setNewDigitalProductData] = useState({
    digitalProductFilePassword: "",
    digitalProductFile: "",
  });
  // state.product.description?.replace(/<\/?[^>]+(>|$)/g, "")
  const [newOject, setNewObject] = useState({
    name: state != null ? state.product.name : "",
    marker: state != null ? state.product.marker : "",
    code: "#ffffff",
    unit: state != null ? state.product.unit : "",
    // content: state != null ? state.product.content : "",
    description: state != null ? state.product.description : "",
    category_id: state != null ? state.product.category_id : 50,
    after_vat: state != null ? state.product.after_vat : "",
    basicPrice: state != null ? state.product.basicPrice : "",
    qty: state != null ? state.product.qty : 0,
    color: state != null ? state.product.color : color,
    size: state != null ? state.product.size : size, //state.product.size
    cards: state != null ? state.product.cards : [], //state.product.size
    kind:
      state != null && state?.product?.kind !== undefined
        ? state?.product?.kind
        : 0,
    digitalProductFile: state != null ? state.product.digitalProductFile : null, //state.product.size
    digitalProductFilePassword:
      state != null ? state.product.digitalProductFilePassword : null, //state.product.size
    serialNumber: "",
    category: state != null ? state.product.category : t("notDetermined"),
    stock: state != null ? state.product.stock : "",
    // stock: newOject?.stock && newOject.stock > 0 ? newOject.stock : 1000000,
    image:
      state != null && state?.product?.image?.length > 0
        ? state.product.image
        : "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",
    imageLocaly: "",
  });

  const [sizesList, setSizesList] = useState(LIST);

  // state.product.size
  const asArray = Object.entries(newOject);
  const filtered = asArray?.filter(([key, value]) => value?.length === 0);

  const [selecte, setSelecte] = useState(
    state != null ? state.product.category : t("notDetermined"),
  );
  // const [selecteSize, setSelecteSize] = useState(sizes[0]);

  const handleFields = (e) => {
    if (
      e.target.name === "unit" ||
      e.target.name === "stock" ||
      e.target.name === "after_vat" ||
      e.target.name === "basicPrice"
    ) {
      setNewObject({ ...newOject, [e.target.name]: Number(e.target.value) });
    } else {
      setNewObject({ ...newOject, [e.target.name]: e.target.value });
    }
  };

  const [file, setFile] = React.useState(null);
  const [imageName, setImageName] = React.useState(
    "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",
  );

  const [loadingImage, setLoadingImage] = useState(false);
  const inputRef = useRef();

  const onChange = async (e) => {
    e.preventDefault();
    setImageName("");
    setFile(e.target.files[0]);
    var src = URL.createObjectURL(e.target.files[0]);
    setImageName(src);
    setLoadingImage(true);
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    await axios
      .post(
        "https://fatora-sa.herokuapp.com/api/v1/products/create-one-image",
        formData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Contant-Type": "multipart/form-data",
          },
        },
      )
      .then(({ data }) => {
        // console.log("data: ", data);
        setNewObject({
          ...newOject,
          image: [
            `https://fatora-sa.herokuapp.com/api/v1/products/${data.createOneImage}`,
            ...newOject.image.split(","),
          ]
            ?.filter(
              (img) =>
                img !==
                "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",
            )
            .toString(),
        });
        setTimeout(() => {
          setLoadingImage(false);
          // console.log("data image: ", newOject.image);
        }, 2000);
      })
      .catch((e) => setLoadingImage(false) & console.log("error: ", e));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event) => {
    event.preventDefault();

    setFile(event.dataTransfer.files[0]);
    var src = URL.createObjectURL(event.dataTransfer.files[0]);
    // console.log("image: ",["https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",...newOject.image.split(',')].toString());

    setImageName(src);
    // setNewObject({ ...newOject, image: ["https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png",...newOject.image.split(',')].toString() });

    setLoadingImage(true);
    const formData = new FormData();
    formData.append("image", event.dataTransfer.files[0]);
    await axios
      .post(
        "https://fatora-sa.herokuapp.com/api/v1/products/create-one-image",
        formData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Contant-Type": "multipart/form-data",
          },
        },
      )
      .then(({ data }) => {
        // console.log("data: ", data);
        // setIsUpload(true) &
        // setImages((perv) => [
        //   `https://fatora-sa.herokuapp.com/api/v1/products/${data.createOneImage}`,
        //   ...perv,
        // ]) &
        setNewObject({
          ...newOject,
          image: [
            `https://fatora-sa.herokuapp.com/api/v1/products/${data.createOneImage}`,
            ...newOject.image.split(","),
          ]
            ?.filter(
              (img) =>
                img !==
                "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",
            )
            .toString(),
        });
        setTimeout(() => {
          setLoadingImage(false);
          // console.log("data image: ", newOject.image);
          // setNewObject({ ...newOject, image: images?.toString() });
          // https://cdn.salla.sa/Xrgxy3J2hm3rownNH27q4aKXOwiMowSdEZDiQepQ.png,https://cdn.salla.sa/c57vGiTKlivkXRroj35Nfl7zJArQpanDmXM7ucjB.jpeg,https://cdn.salla.sa/RHjBBLlOKYd48MB6w0qEsZ32jF7wyeucCUIhtJPb.jpeg
          // https://fatora-sa.herokuapp.com/api/v1/products/1681547428788-fatora-cashier-yKU0kCl9xgyS0nuaiW4bKX0kIRUxU70AAnnh4JTV.jpg,https://cdn.salla.sa/Xrgxy3J2hm3rownNH27q4aKXOwiMowSdEZDiQepQ.png,https://cdn.salla.sa/c57vGiTKlivkXRroj35Nfl7zJArQpanDmXM7ucjB.jpeg,https://cdn.salla.sa/RHjBBLlOKYd48MB6w0qEsZ32jF7wyeucCUIhtJPb.jpeg
        }, 2000);
      })
      .catch((e) => setLoadingImage(false) & console.log("error: ", e));
  };

  const deleteImage = async (key) => {
    setLoadingImage(true);
    const deleteOneImage = newOject.image
      .split(",")
      [key].replace(/^.*[\\\/]/, "");

    try {
      await instance
        .post("/products/delete-one-image", {
          filename: deleteOneImage,
        })
        .then(async ({ data }) => {
          setNewObject({
            ...newOject,
            image: newOject.image
              .split(",")
              ?.filter((img) => img !== newOject.image.split(",")[key])
              .toString(),
          });

          // console.log(
          //   "image: ",
          //   newOject.image
          //     .split(",")
          //     .filter((img) => img !== newOject.image.split(",")[key])
          //     .toString()
          // );
          const images = newOject.image
            .split(",")
            ?.filter((img) => img !== newOject.image.split(",")[key])
            .toString();
          if (images?.length === 0) {
            setNewObject({
              ...newOject,
              image:
                "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",
            });
          }

          if (state != null && state.product != null) {
            await instance.post(`/products/update-product`, {
              ...newOject,
              image: newOject?.image
                .split(",")
                ?.filter((img) => img !== newOject?.image?.split(",")[key])
                .toString(),
              _id: state?.product?._id,
            });
          }

          setLoadingImage(false);
        });
    } catch (error) {
      setLoadingImage(false);
      console.log("error: ", error.message);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRemoveProduct, setIsLoadingRemoveProduct] = useState(false);

  const addNewProduct = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (state != null) {
        req = await instance.post(`/products/update-product`, {
          ...newOject,
          category: selecte,
          description: JSON.stringify(data),
          cards: currentCards?.map((card) => card._id),
          kind: selectedBranded !== 0 ? selectedBranded : 0,
          // digitalProductFile: newDigitalProductData.digitalProductFile,
          // digitalProductFilePassword:
          //   newDigitalProductData.digitalProductFilePassword,
          // colors: colors?.filter((c) => c.color.startsWith("#")),
          image: newOject.image
            .split(",")
            ?.filter(
              (img) =>
                img !==
                "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",
            )
            .toString(),
          _id: state?.product?._id,
        });
      } else {
        // products/create-product
        req = await instance.post(`/products/create-product-sin`, {
          ...newOject,
          isfirstporduct: select == null ? true : false,
          category: selecte,
          description: JSON.stringify(data),
          cards: currentCards?.map((card) => card._id),
          kind: selectedBranded !== 0 ? selectedBranded : 0,
          // digitalProductFile: newDigitalProductData.digitalProductFile,
          // digitalProductFilePassword:
          //   newDigitalProductData.digitalProductFilePassword,
          stock: newOject.stock?.length === 0 ? 1000000 : newOject.stock,
          image: newOject.image
            .split(",")
            ?.filter(
              (img) =>
                img !==
                "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",
            )
            .toString(),
        });
      }
      if (req) {
        setTimeout(() => {
          setIsLoading(false);
          state != null
            ? navigate(`/detail/${state?.product?._id}`)
            : navigate(`/${localStorage.getItem("CN")}`);
        }, 2000);
      } else {
      }
      // setIsLoading(false);
    } catch (error) {
      console.log("error", error.message);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const oneFetch = useRef(true);
  useEffect(() => {
    if (oneFetch.current) {
      oneFetch.current = false;
      console.log("jj: ", state?.product?._id);

      categories.includes("تصنيف جديد") === false &&
        setCategories(["تصنيف جديد", ...categories]);
      // colors?.length === 0 &&
      // setColors([{ color: "add", inStock: false }, ...newOject.colors]);
      // colors[0].color.includes("إضافة لون جديد") === false
      // console.log("here ..", colors);
      // sizes[0].name.includes("إضافة مقاس جديد") === false &&
      //   setSizes([{ name: "إضافة مقاس جديد", inStock: true }, ...sizes]);
      // setSizes();
    }
  }, []);

  const { company } = jwt_decode(localStorage.getItem("TokenNAEM"));

  const { response, loading, error } = useAxios({
    method: "GET",
    url: `/products/first-product-in-same-products/${company._id}`,
    headers: {
      accept: "*/*",
    },
  });

  const deletePdf = async (key) => {
    // setLoadingImage(true);
    const deleteOneImage = newOject.digitalProductFile
      .split(",")
      [key].replace(/^.*[\\\/]/, "");
    console.log("deleteOneImage: ", deleteOneImage);
    try {
      if (deleteOneImage.length > 0) {
        await instance
          .post("/products/delete-one-image", {
            filename: deleteOneImage,
          })
          .then(async ({ data }) => {
            setIsUpdateImage(false);
          });
      }
      setNewObject({
        ...newOject,
        digitalProductFilePassword: null,
        digitalProductFile: null,
      });
    } catch (error) {
      // setLoadingImage(false);
      console.log("error: ", error.message);
    }
  };

  const [alertEdit, setAlertEdit] = useState(loading);
  // useEffect(() => {
  //   setAlertEdit(loading === false);
  // }, [loading]);

  const handleNotification = () => {
    setShow(true);

    setTimeout(() => {
      setShow(false);
      // JSON.parse(localStorage.getItem("Order")).length === 0 &&
      //   navigate(`/${localStorage.getItem("CN")}`);
    }, 1500);
  };

  const [alertDigitalProduct, setAlertDigitalProduct] = useState(false);
  const [alertDigitalCard, setAlertDigitalCard] = useState(false);

  const [isUpdateImage, setIsUpdateImage] = useState(false);

  const [newClipboard, setNewClipboard] = useState(false);
  const updateClipboard = (newClip) => {
    navigator.clipboard.writeText(newClip).then(
      () => {
        setNewClipboard(true);
        handleNotification();
        setTimeout(() => {
          setNewClipboard(false);
        }, 2000);
        // setNewClipboard(false);
      },
      () => {
        console.log("clipboard write failed");
      },
    );
  };

  // const [data, setData] = useState("");

  const [data, setData] = useState(
    state !== null
      ? newOject?.description?.length > 0 &&
        newOject?.description.startsWith("{")
        ? newOject.description
        : JSON.stringify(exampleData)
      : "",
  );
  // console.log("i18n: ", i18n.language);
  return (
    <>
      {isUpdateImage && newClipboard === false && (
        <Notifications title={t("successText")} />
      )}
      {newClipboard === true && <Notifications title={t("copyText")} />}

      <AlertDigitalProduct
        setNewObject={setNewObject}
        newOject={newOject}
        alertDigitalProduct={alertDigitalProduct}
        setAlertDigitalProduct={setAlertDigitalProduct}
        i18n={i18n}
        t={t}
        newDigitalProductData={newDigitalProductData}
        setNewDigitalProductData={setNewDigitalProductData}
        isUpdateImage={isUpdateImage}
        setIsUpdateImage={setIsUpdateImage}
        handleNotification={handleNotification}
      />
      {alertDigitalCard === true && (
        <AlertDigitalCard
          alertDigitalCard={alertDigitalCard}
          setAlertDigitalCard={setAlertDigitalCard}
          t={t}
          currentCards={currentCards}
          setCurrentCards={setCurrentCards}
          isEdit={
            state != null && currentCards.length === newOject.cards.length
          }
          editCard={newOject.cards}
          product_id={state != null ? state?.product?._id : null}
        />
      )}
      {state == null && loading ? (
        <div className="w-full p-0">
          <div className="relative h-2 overflow-hidden  bg-gray-300">
            <div
              id="progress"
              className="absolute h-full w-1/2 animate-loading  bg-indigo-500"
            ></div>
          </div>
        </div>
      ) : (
        <>
          {loadingImage && (
            <div className="w-full p-0">
              <div className="relative h-2 overflow-hidden  bg-gray-300">
                <div
                  id="progress"
                  className="absolute h-full w-1/2 animate-loading  bg-indigo-500"
                ></div>
              </div>
            </div>
          )}
          <div className=" my-5 px-2 sm:container sm:mx-auto sm:px-3 2xl:px-0">
            {state == null && (
              <div className="border-orange-400 bg-orange-50 p-4 ltr:border-l-4 rtl:border-r-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-5 w-5 text-orange-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="flex w-full flex-row justify-between ltr:ml-3 rtl:mr-3">
                    <p className="text-[10px] text-orange-700  sm:text-sm">
                      لإضافة المنتجات أو المقاسات المشابهة
                    </p>
                    <button
                      onClick={() => setAlertEdit(true)}
                      className="text-[10px] font-medium text-orange-700 underline outline-none hover:text-orange-600 sm:text-sm"
                    >
                      اضغط هنا
                    </button>
                  </div>
                </div>
              </div>
            )}
            {state == null && (
              <AlertEditProduct
                alertEdit={alertEdit}
                setAlertEdit={setAlertEdit}
                response={response}
                loading={loading}
                setNewObject={setNewObject}
                i18n={i18n}
                t={t}
                setData={setData}
                data={data}
              />
            )}
            <AddNewCategory
              open={open}
              setOpen={setOpen}
              isSend={isSend}
              setIsSend={setIsSend}
              categories={categories}
              setCategories={setCategories}
              setSelecte={setSelecte}
            />

            <AddColor
              openAddColor={openAddColor}
              setOpenAddColor={setOpenAddColor}
              color={color}
              setColor={setColor}
              setNewObject={setNewObject}
              newOject={newOject}
              t={t}
            />

            <div className="my-2 flex flex-row items-center justify-between">
              <h2 className="text-base font-bold tracking-tight text-gray-900 ltr:text-left rtl:text-right sm:text-xl">
                {state != null ? t("editeProductInformation") : t("addProduct")}
              </h2>
              <Branded kind={newOject?.kind} />
            </div>

            <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8  ">
              <Tab.Group as="div" className="flex flex-col-reverse">
                {/* hidden */}
                <div className="mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none">
                  <Tab.List className="grid grid-cols-4 gap-6">
                    {newOject?.image.split(",").map((image, index) => (
                      <Tab
                        key={index}
                        className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                      >
                        {({ selected }) => (
                          <div key={index}>
                            <span className="sr-only"> {image} </span>
                            <span className="absolute inset-0 overflow-hidden rounded-md">
                              {loadingImage === false &&
                              image !==
                                "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png" ? (
                                <XCircleIcon
                                  className="absolute ml-0 mt-0 h-7 w-7 rounded-xl bg-white  text-red-600  "
                                  aria-hidden="true"
                                  onClick={() => deleteImage(index)}
                                />
                              ) : null}
                              <img
                                src={image}
                                alt=""
                                className="h-full w-full object-cover object-center "
                              />
                            </span>
                            <span
                              className={classNames(
                                selected
                                  ? "ring-indigo-500"
                                  : "ring-transparent",
                                "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2",
                              )}
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>

                <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                  {newOject?.image
                    ? newOject?.image.split(",").map((image, key) => (
                        <Tab.Panel key={key}>
                          <img
                            src={image}
                            alt={key}
                            className="h-full w-full object-cover object-center sm:rounded-lg"
                          />
                        </Tab.Panel>
                      ))
                    : ""}
                </Tab.Panels>
              </Tab.Group>
            </div>

            {loadingImage == false ? (
              <div className="col-span-full my-10">
                <label
                  htmlFor="cover-photo"
                  className="block text-xl  font-medium leading-6 text-gray-900"
                >
                  {t("addImage")}
                </label>
                <div
                  className="mt-5 flex justify-center rounded-lg border-2 border-dashed border-gray-900/25 px-6 py-10"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <div className="text-center">
                    <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span> {t("addImage")}</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={onChange}
                          ref={inputRef}
                          // onDragOver={handleDragOver}
                          // onDrop={handleDrop}
                        />
                      </label>
                      <p className="pl-2 pr-2">
                        {t("or")} {t("addFileTitle")}{" "}
                      </p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>
              </div>
            ) : null}

            <Listbox value={selecte} onChange={setSelecte}>
              {({ open }) => (
                <>
                  <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                    {t("productKindCategory")}
                  </Listbox.Label>
                  <div className="relative mt-2">
                    <Listbox.Button
                      onChange={(e) => console.log("e: ", e.target.value)}
                      className="relative w-full cursor-default rounded-md bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 ltr:pl-3 ltr:pr-10 ltr:text-left rtl:pl-10 rtl:pr-3 rtl:text-right sm:text-sm sm:leading-6"
                    >
                      <span className="block truncate">{selecte}</span>
                      <span className="pointer-events-none absolute inset-y-0 flex items-center ltr:right-0 ltr:pr-2 rtl:left-0 rtl:pl-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {categories
                          ?.filter((c) => c !== "all")
                          .map((person, key) => (
                            <Listbox.Option
                              key={key}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 ltr:pl-3 ltr:pr-9 rtl:pl-9 rtl:pr-3",
                                )
                              }
                              value={person}
                              onClick={() =>
                                person.startsWith("تصنيف") && setOpen(true)
                              }
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "block truncate",
                                    )}
                                  >
                                    {person}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 flex items-center ltr:right-0 ltr:pr-4 rtl:left-0 rtl:pl-4",
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>

            <div className="my-4">
              <label
                htmlFor="company-website"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("productName")}
              </label>
              <div className="my-4">
                <input
                  type="name"
                  name="name"
                  id="name"
                  value={newOject?.name}
                  className="block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  // placeholder="الرياض"
                  onChange={(e) => handleFields(e)}
                />
              </div>
            </div>
            <div className="my-4">
              <label
                htmlFor="company-website"
                className="block  text-sm font-medium leading-6 text-gray-900"
              >
                {t("productMarker")}
              </label>
              <div className="my-4">
                <input
                  type="marker"
                  name="marker"
                  id="marker"
                  value={newOject.marker}
                  className="block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  // placeholder="الرياض"
                  onChange={(e) => handleFields(e)}
                />
              </div>
            </div>
            <div className="my-4">
              <label
                htmlFor="company-website"
                className="mb-3 block text-sm font-medium leading-6 text-gray-900"
              >
                {t("productDesc")}
              </label>

              {/* <EditorComponent
                  description={newOject?.description}
                  setNewObject={setNewObject}
                  newOject={newOject}
                /> */}
              {/* <textarea
                  type="text"
                  rows={5}
                  name="description"
                  id="description"
                  value={newOject?.description}
                  className="block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  // placeholder="الرياض"
                  onChange={(e) => handleFields(e)}
                /> */}
              <div className="block rounded-md border pt-28 text-left text-gray-900  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 rtl:text-right sm:text-sm sm:leading-6">
                {state !== null && data?.length > 0 ? (
                  <Editor
                    data={JSON.parse(data)}
                    setData={setData}
                    readOnly={false}
                  />
                ) : state == null && data?.length > 0 ? (
                  <Editor
                    data={JSON.parse(data)}
                    setData={setData}
                    readOnly={false}
                  />
                ) : null}
              </div>
            </div>
            <div className="my-4">
              <label
                htmlFor="company-website"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("productCost")}
              </label>
              <div className="my-4">
                <input
                  type="number"
                  name="after_vat"
                  id="after_vat"
                  value={newOject?.after_vat}
                  className="block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  // placeholder="الرياض"
                  onChange={(e) => handleFields(e)}
                />
              </div>
            </div>
            <div className="my-4">
              <label
                htmlFor="company-website"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("productPrice")}
              </label>
              <div className="my-4">
                <input
                  type="number"
                  name="unit"
                  id="unit"
                  value={newOject?.unit}
                  className="block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  // placeholder="الرياض"
                  onChange={(e) => handleFields(e)}
                />
              </div>
            </div>
            <div className="my-4">
              <label
                htmlFor="company-website"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("productBasicPrice")}
              </label>
              <div className="my-4">
                <input
                  type="number"
                  name="basicPrice"
                  id="basicPrice"
                  value={newOject?.basicPrice}
                  className="block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  // placeholder="الرياض"
                  onChange={(e) => handleFields(e)}
                />
              </div>
            </div>
            {/* <div className="my-4">
          <label
            htmlFor="company-website"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            الكمية
          </label>
          <div className="my-4">
            <input
              type="text"
              name="qty"
              id="qty"
              value={newOject?.qty.toFixed(2)}
              className="px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              // placeholder="الرياض"
              onChange={(e) => handleFields(e)}
            />
          </div>
        </div> */}
            <div className="my-4">
              <label
                htmlFor="company-website"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("productStock")}
              </label>
              <div className="my-4">
                <input
                  type="number"
                  name="stock"
                  id="stock"
                  value={
                    newOject?.kind === 2 &&
                    newOject?.cards?.map((card) => card._id).length > 0
                      ? newOject?.cards?.map((card) => card._id).length
                      : newOject?.stock
                  }
                  className="block w-full rounded-md border px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  // placeholder="الرياض"
                  onChange={(e) => handleFields(e)}
                />
              </div>
            </div>

            {selectedBranded === 0 && (
              <>
                <p className="mt-5"> لون المنتج:(إضافية)</p>
                <div className="my-3 flex flex-row justify-between">
                  <p className=" rounded bg-red-100 p-2 px-1 text-[10px] sm:text-sm ">
                    ملاحظة: هذه الخانة مخصصة إذا كان المنتج له لون
                  </p>

                  <div className="flex flex-row justify-between gap-1 ">
                    <button
                      onClick={() => {
                        setOpenAddColor(true);
                      }}
                      className="rounded bg-blue-600 p-2 px-3   text-[10px] text-white hover:bg-blue-700  sm:text-sm"
                    >
                      {t("addColor")}
                    </button>
                    {newOject?.color != null && (
                      <button
                        onClick={() => {
                          setNewObject({
                            ...newOject,
                            color: null,
                          });
                          setColor(null);
                        }}
                        className="rounded bg-red-600 p-2 px-3 text-[10px] text-white hover:bg-red-700 sm:text-sm"
                      >
                        حذف اللون
                      </button>
                    )}
                  </div>
                </div>
                {newOject?.color != null ? (
                  <div className="my-2 flex flex-row overflow-auto whitespace-nowrap rounded border-2 border-dotted bg-gray-100 px-1 py-2">
                    <div
                      className=" mx-1 mt-0  rounded p-5 "
                      style={{ backgroundColor: newOject?.color }}
                      onClick={() => {
                        setColor(null);
                        setNewObject({
                          ...newOject,
                          color: null,
                        });
                      }}
                    >
                      <MinusCircleIcon className="relative top-0 h-5  w-5 rounded text-white " />
                    </div>
                  </div>
                ) : (
                  <div className="mb-2 mt-3 flex flex-row justify-between rounded border-2  border-dotted bg-gray-100 px-1 py-2">
                    <p className="mt-1 text-gray-700">
                      {" "}
                      لم تتم إضافة لون للمنتج
                    </p>
                  </div>
                )}

                <div className="my-2 flex flex-row"></div>

                <p className="mt-3"> قائمة المقاسات أو الأحجام :(إضافية)</p>
                <div className="flex flex-row justify-between">
                  <p className="mt-1 rounded bg-red-100 p-2 py-2 text-xs sm:text-sm">
                    اختر من القائمة المقاس أو الحجم الخاصة بالمنتج
                  </p>
                </div>

                <div className="my-3 flex flex-row overflow-auto whitespace-nowrap rounded border-2 border-dotted bg-gray-100 px-1 py-2">
                  {sizesList.map((size, key) => (
                    <div className="inline-block  " key={key}>
                      <div
                        onClick={() => {
                          setNewObject({
                            ...newOject,
                            size: size,
                          });
                          setSize(size);
                        }}
                        className=" mx-1 mt-0  flex flex-row   gap-2 rounded border border-black bg-white p-2 px-5 "
                      >
                        <PlusCircleIcon className="relative  top-0 h-7 w-7 rounded bg-white text-green-600 " />
                        <p className=" pt-0.5">{size}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <p className="mt-3"> المقاس أو الحجم المتوفر:(إضافية)</p>
                <div className="flex  flex-row justify-between ">
                  <p className="rounded bg-red-100 p-2 text-[10px]  sm:text-sm">
                    ملاحظة:إذا لديك أكثر من مقاس قم بإضافة منتج جديد
                  </p>
                  {newOject?.size != null && (
                    <button
                      onClick={() => {
                        setNewObject({
                          ...newOject,
                          size: null,
                        });
                        setSize(null);
                      }}
                      className=" rounded bg-red-600 p-2  px-3 text-[10px] text-white hover:bg-red-700 sm:text-sm"
                    >
                      حذف المقاس
                    </button>
                  )}
                </div>
                {newOject?.size != null ? (
                  <div className="my-3 flex flex-row overflow-auto whitespace-nowrap rounded border-2 border-dotted bg-gray-100 px-1 py-2">
                    <div className="inline-block  ">
                      <div
                        onClick={() => {
                          setNewObject({
                            ...newOject,
                            size: null,
                          });
                          setSize(null);
                        }}
                        className=" mx-1 mt-0  flex  flex-row gap-5 rounded border border-black bg-white p-2 px-5 "
                      >
                        <MinusCircleIcon className="relative top-0 h-7 w-7 rounded bg-white text-red-700 " />
                        <p className=" pt-0.5">{newOject?.size}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="my-3 flex flex-row justify-between rounded border-2  border-dotted bg-gray-100 px-1 py-2">
                    <p className="mt-1 text-gray-700">
                      لم يتم إضافة مقاس أو حجم لهذا المنتج
                    </p>
                  </div>
                )}
              </>
            )}

            {selectedBranded === 1 && (
              <>
                <p className="mt-5"> الملف</p>
                <div className="mt-2 flex flex-row items-center justify-between gap-1 px-1">
                  <p className="  rounded  bg-red-100 p-3 text-[8px] sm:text-sm">
                    ملاحظة مهمه: اضف الملف أو الدورة أو الكتاب الإلكتروني للوصول
                    إليه من قبل عمليك بشكل مباشر
                  </p>
                  <div className=" flex flex-row justify-between  ">
                    {newOject?.digitalProductFile?.length > 0 ? (
                      <button
                        onClick={() => {
                          deletePdf(0);
                        }}
                        className=" w-20 rounded bg-red-600 py-4 text-[9px] text-white hover:bg-red-700 sm:w-32 sm:p-3  sm:text-sm"
                      >
                        حذف الملف
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setNewClipboard(false);
                          setAlertDigitalProduct(true);
                        }}
                        className=" w-20 rounded bg-blue-600 py-4 text-[9px] text-white hover:bg-blue-700 sm:w-32 sm:p-3  sm:text-sm"
                      >
                        إضافة ملف
                      </button>
                    )}
                  </div>
                </div>

                {newOject?.digitalProductFile != null ? (
                  <div className="my-3 flex flex-row justify-between rounded border-2  border-dotted bg-gray-100 px-1 py-2 ">
                    <button
                      onClick={() =>
                        updateClipboard(newOject?.digitalProductFile)
                      }
                      type="button"
                      className={classNames(
                        newClipboard
                          ? "bg-green-100 text-green-600"
                          : "text-gray-400",
                        "relative -ml-px inline-flex items-center gap-x-1.5 rounded px-3 py-2 text-sm font-semibold text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                      )}
                    >
                      {newClipboard ? (
                        <ClipboardDocumentCheckIcon
                          className=" h-5 w-5 animate-bounce text-green-600"
                          aria-hidden="true"
                        />
                      ) : (
                        <DocumentDuplicateIcon
                          className=" h-5 w-5 text-gray-400"
                          aria-hidden="true"
                          // onClick={() => copyToClipboard(response.url)}
                        />
                      )}
                      <p className="hidden sm:block">{t("copy")}</p>
                    </button>
                    <div className="flex flex-row items-center gap-2 px-2">
                      <p className=" text-bold text-700-800 text-left  text-[9px] sm:text-base ">
                        {newOject?.digitalProductFile}
                      </p>
                      <PaperClipIcon
                        className=" h-5 w-5 text-gray-400"
                        aria-hidden="true"
                        // onClick={() => copyToClipboard(response.url)}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setNewClipboard(false);
                      setAlertDigitalProduct(true);
                    }}
                    className="my-3 flex cursor-pointer justify-center gap-2  rounded border-2 border-dashed border-gray-900/25 bg-gray-100 px-1 py-3"
                  >
                    <p className="">لايوجد ملف حالياً - </p>
                    <p className=" text-blue-600">{t("addFile")}</p>
                  </div>
                )}
                {newOject?.digitalProductFile?.length > 0 && (
                  <>
                    <div className="flex  flex-row justify-between ">
                      <p className="mt-3">كلمة المرور للملف</p>
                      <div className="flex flex-row justify-between gap-1 ">
                        {newOject?.digitalProductFile.length > 0 ? (
                          <button
                            onClick={() => {
                              setAlertDigitalProduct(true);
                            }}
                            className="rounded bg-blue-600 p-2 px-3 text-[10px] text-white hover:bg-blue-700 sm:text-sm"
                          >
                            تغيير
                          </button>
                        ) : null}
                      </div>
                    </div>
                    <div className="my-3 flex flex-row justify-between rounded border-2  border-dotted bg-gray-100 px-1 py-2 ">
                      <div className="flex flex-row items-center gap-2 px-2">
                        <KeyIcon
                          className=" h-6 w-6 text-gray-400"
                          aria-hidden="true"
                          // onClick={() => copyToClipboard(response.url)}
                        />
                        <p className="text-bold w-full  text-sm text-gray-700 ">
                          {newOject?.digitalProductFilePassword}
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          updateClipboard(newOject?.digitalProductFilePassword)
                        }
                        type="button"
                        className={classNames(
                          newClipboard
                            ? "bg-green-100 text-green-600"
                            : "text-gray-400",
                          "relative -ml-px inline-flex items-center gap-x-1.5 rounded px-3 py-2 text-sm font-semibold text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                        )}
                      >
                        {newClipboard ? (
                          <ClipboardDocumentCheckIcon
                            className=" h-5 w-5 animate-bounce text-green-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <DocumentDuplicateIcon
                            className=" h-5 w-5 text-gray-400"
                            aria-hidden="true"
                            // onClick={() => copyToClipboard(response.url)}
                          />
                        )}
                        <p className="hidden sm:block">{t("copy")}</p>
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
            {selectedBranded === 2 && (
              <>
                <p className="mt-5"> بطاقة رقمية</p>
                <div className="mt-2 flex flex-row items-center justify-between gap-1 px-1">
                  <p className="  rounded  bg-red-100 p-3 text-[8px] sm:text-sm">
                    ملاحظة مهمه: يمكنك إضافة بطاقة رقمية واحدة أو أكثر
                  </p>
                  <div className=" flex flex-row justify-between  ">
                    {newOject?.cards?.map((card) => card._id).length > 0 ? (
                      <button
                        onClick={() => {
                          setAlertDigitalCard(true);
                        }}
                        className=" w-20 rounded bg-green-600 py-4 text-[9px] text-white hover:bg-green-700 sm:w-32 sm:p-3  sm:text-sm"
                      >
                        تعديل
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setAlertDigitalCard(true);
                        }}
                        className=" w-20 rounded bg-blue-600 py-4 text-[9px] text-white hover:bg-blue-700 sm:w-32 sm:p-3  sm:text-sm"
                      >
                        إضافة
                      </button>
                    )}
                  </div>
                </div>

                {newOject?.digitalProductFile != null ? (
                  <div className="my-3 flex flex-row justify-between rounded border-2  border-dotted bg-gray-100 px-1 py-2 ">
                    <button
                      onClick={() =>
                        updateClipboard(newOject?.digitalProductFile)
                      }
                      type="button"
                      className={classNames(
                        newClipboard
                          ? "bg-green-100 text-green-600"
                          : "text-gray-400",
                        "relative -ml-px inline-flex items-center gap-x-1.5 rounded px-3 py-2 text-sm font-semibold text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                      )}
                    >
                      {newClipboard ? (
                        <ClipboardDocumentCheckIcon
                          className=" h-5 w-5 animate-bounce text-green-600"
                          aria-hidden="true"
                        />
                      ) : (
                        <DocumentDuplicateIcon
                          className=" h-5 w-5 text-gray-400"
                          aria-hidden="true"
                          // onClick={() => copyToClipboard(response.url)}
                        />
                      )}
                      <p className="hidden sm:block">{t("copy")}</p>
                    </button>
                    <div className="flex flex-row items-center gap-2 px-2">
                      <p className=" text-bold text-700-800 text-left  text-[9px] sm:text-base ">
                        {newOject?.digitalProductFile}
                      </p>
                      <PaperClipIcon
                        className=" h-5 w-5 text-gray-400"
                        aria-hidden="true"
                        // onClick={() => copyToClipboard(response.url)}
                      />
                    </div>
                  </div>
                ) : selectedBranded === 1 ? (
                  <div
                    onClick={() => {
                      setNewClipboard(false);
                      setAlertDigitalProduct(true);
                    }}
                    className="my-3 flex cursor-pointer justify-center gap-2  rounded border-2 border-dashed border-gray-900/25 bg-gray-100 px-1 py-3"
                  >
                    <p className="">لايوجد ملف حالياً - </p>
                    <p className=" text-blue-600">{t("addFile")}</p>
                  </div>
                ) : null}
                {newOject?.digitalProductFile?.length > 0 && (
                  <>
                    <div className="flex  flex-row justify-between ">
                      <p className="mt-3">كلمة المرور للملف</p>
                      <div className="flex flex-row justify-between gap-1 ">
                        {newOject?.digitalProductFile.length > 0 ? (
                          <button
                            onClick={() => {
                              setAlertDigitalProduct(true);
                            }}
                            className="rounded bg-blue-600 p-2 px-3 text-[10px] text-white hover:bg-blue-700 sm:text-sm"
                          >
                            تغيير
                          </button>
                        ) : null}
                      </div>
                    </div>
                    <div className="my-3 flex flex-row justify-between rounded border-2  border-dotted bg-gray-100 px-1 py-2 ">
                      <div className="flex flex-row items-center gap-2 px-2">
                        <KeyIcon
                          className=" h-6 w-6 text-gray-400"
                          aria-hidden="true"
                          // onClick={() => copyToClipboard(response.url)}
                        />
                        <p className="text-bold w-full  text-sm text-gray-700 ">
                          {newOject?.digitalProductFilePassword}
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          updateClipboard(newOject?.digitalProductFilePassword)
                        }
                        type="button"
                        className={classNames(
                          newClipboard
                            ? "bg-green-100 text-green-600"
                            : "text-gray-400",
                          "relative -ml-px inline-flex items-center gap-x-1.5 rounded px-3 py-2 text-sm font-semibold text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                        )}
                      >
                        {newClipboard ? (
                          <ClipboardDocumentCheckIcon
                            className=" h-5 w-5 animate-bounce text-green-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <DocumentDuplicateIcon
                            className=" h-5 w-5 text-gray-400"
                            aria-hidden="true"
                            // onClick={() => copyToClipboard(response.url)}
                          />
                        )}
                        <p className="hidden sm:block">{t("copy")}</p>
                      </button>
                    </div>
                  </>
                )}
              </>
            )}

            <div className="mt-10 flex flex-row gap-2">
              <div className="w-full">
                <form onSubmit={addNewProduct}>
                  <button
                    type="submit"
                    // disabled={
                    //   newOject?.name?.length < 1 ||
                    //   newOject.unit?.length < 1 ||
                    //   newOject.image.includes(
                    //     "1682959636665-fatora-cashier-placeholder-image.png",
                    //   ) === true ||
                    //   newOject.after_vat?.length < 1
                    // }
                    className={classNames(
                      newOject?.name?.length < 1 ||
                        newOject?.unit?.length < 1 ||
                        newOject?.image.includes(
                          "1682959636665-fatora-cashier-placeholder-image.png",
                        ) === true ||
                        newOject?.after_vat?.length < 1
                        ? "bg-gray-400  hover:bg-gray-400"
                        : "bg-indigo-600  hover:bg-indigo-500 focus:ring-indigo-600 focus-visible:outline-indigo-600",
                      "mt-5 flex w-full justify-center rounded-md border  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ",
                    )}
                  >
                    {isLoading ? (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline h-4 w-4 animate-spin text-white ltr:mr-3 rtl:ml-3"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      </>
                    ) : state != null ? (
                      t("editeProduct")
                    ) : (
                      t("addProduct")
                    )}
                  </button>
                </form>
              </div>
              {state != null ? (
                <div className="w-full">
                  <button
                    onClick={async () => {
                      setIsLoadingRemoveProduct(true);
                      newOject?.image.includes(
                        "1682959636665-fatora-cashier-placeholder-image.png",
                      ) === false
                        ? alert("لابد من حذف الصور قبل حذف المنتج")
                        : await instance
                            .post("/products/delete-product", {
                              productId: state?.product?._id,
                            })
                            .then(() =>
                              navigate(`/${localStorage.getItem("CN")}`),
                            )
                            .catch((error) =>
                              console.log("error: ", error.message),
                            );
                      setTimeout(() => {
                        setIsLoadingRemoveProduct(false);
                      }, 1000);
                    }}
                    className={classNames(
                      false
                        ? "bg-gray-400  hover:bg-gray-400"
                        : "bg-red-600  hover:bg-red-500 focus:ring-indigo-600 focus-visible:outline-red-600",
                      "mt-5 flex w-full justify-center rounded-md border  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ",
                    )}
                  >
                    {isLoadingRemoveProduct ? (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline h-4 w-4 animate-spin text-white ltr:mr-3 rtl:ml-3"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      </>
                    ) : (
                      t("removeProduct")
                    )}
                  </button>
                </div>
              ) : null}
            </div>
            <div>
              <button
                type="button"
                // disabled={filtered?.length !== 0}
                onClick={() => navigate(`/${localStorage.getItem("CN")}`)}
                className={
                  "mt-3  flex w-full justify-center rounded-md border bg-gray-600 px-3 py-2 text-sm  font-semibold text-white shadow-sm hover:bg-gray-500 focus:ring-gray-600 focus-visible:outline  focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 "
                }
              >
                {t("back")}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default EditeProduct;
