import { useEffect, useState } from "react";
import instance, { useAxios } from "../api/api";
import YourSvg from "../images/add_card_black_48dp.svg";
import {
  ClipboardDocumentCheckIcon,
  DocumentDuplicateIcon,
  EyeDropperIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    department: "Optimization",
    email: "lindsay.walton@example.com",
    role: "Member",
    image:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "ndsay Walton",
    title: "Front-end Developer",
    department: "Optimization",
    email: "lindsay.walton@example.com",
    role: "Member",
    image:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  // More people...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TableCards({
  cards,
  setLoading,
  loading,
  currentCards,
  setCurrentCards,
  isEdit,
  product_id,
  t,
}) {
  const [isAddCard, setIsAddCard] = useState(false);
  const [newCard, setNewCard] = useState({
    title: "",
    digitalCard: "",
    note: "",
  });

  const handleFields = (e) => {
    // setNewDigitalProductData({
    //   ...newDigitalProductData,
    //   [e.target.name]: e.target.value,
    // });
    setNewCard({
      ...newCard,
      [e.target.name]: e.target.value,
    });
  };

  // useEffect(() => {
  // setCurrentCards(cards);
  // console.log("product_id: ", product_id);
  // if (currentCard.length === 0) {
  // }
  // }, [loading]);

  const editCard = ({
    _id,
    digitalCard,
    title,
    note,
    isValid,
    created_at,
    updatedAt,
    __v,
    product_id,
  }) => {
    let edit = currentCards?.map((card) => {
      if (card._id === _id) {
        card.title = title;
        card.digitalCard = digitalCard;
        card.note = note;
        card.isValid = isValid;
        card.created_at = created_at;
        card.updatedAt = updatedAt;
        card.updatedAt = updatedAt;
        card.__v = __v;
        card.product_id = product_id;
      }
      return card;
    });
    setCurrentCards(edit);
  };

  const addNewCard = () => {
    setLoading(true);
    if (
      isAddCard === true &&
      newCard._id === undefined &&
      newCard.title.length > 0
    ) {
      instance
        .post(`/cards/create`, {
          title: newCard.title,
          digitalCard: newCard.digitalCard,
          note: newCard.note,
        })
        .then(({ data }) => {
          setCurrentCards((prev) => {
            // console.log("data fff: ", [data, ...prev]);

            return [data, ...prev];
          });
        });
    } else if (isAddCard === true && newCard._id !== undefined) {
      // console.log("product_id: ", product_id);
      editCard({ ...newCard, product_id: product_id });
      instance.patch(`/cards/update-card/${newCard._id}`, {
        ...newCard,
        product_id: product_id,
      });
    } else if (isAddCard === false) {
      setNewCard({
        title: "",
        digitalCard: "",
        note: "",
      });
    }
    setLoading(false);
    setIsAddCard(!isAddCard);
  };

  const [select, setSelect] = useState({ show: false, key: 0 });
  const [newClipboard, setNewClipboard] = useState({ show: false, key: 0 });
  const updateClipboard = (newClip, newClipboard) => {
    navigator.clipboard.writeText(newClip).then(
      () => {
        setNewClipboard(newClipboard);
        setTimeout(() => {
          setNewClipboard({ ...newClipboard, show: false });
        }, 2000);
        // setNewClipboard(false);
      },
      () => {
        console.log("clipboard write failed");
      },
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 xl:px-2 ">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("digitalCards")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">{t("digitalCardsList")}</p>
        </div>
        <div className="mt-5 flex flex-row gap-2 sm:mt-1 sm:flex-none ltr:sm:ml-16 rtl:sm:mr-16">
          <div className="">
            <button
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={addNewCard}
            >
              {isAddCard ? t("save") : t("addCard")}
            </button>
          </div>

          {isAddCard === true && (
            <div className="">
              <button
                type="button"
                className="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                onClick={() => {
                  setNewCard({
                    title: "",
                    digitalCard: "",
                    note: "",
                  });

                  setIsAddCard(false);
                }}
              >
                {t("back")}
              </button>
            </div>
          )}
        </div>
      </div>
      {currentCards?.length === 0 && isAddCard === false ? (
        <button
          onClick={addNewCard}
          type="button"
          className="relative my-10 block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <div className="flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </div>
          <span className="mt-2 block text-sm font-semibold text-gray-900">
            Create a new digital card
          </span>
        </button>
      ) : isAddCard === false ? (
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 text-sm font-semibold text-gray-900 ltr:pl-4 ltr:pr-3 ltr:text-left rtl:pl-3 rtl:pr-4 rtl:text-right ltr:sm:pl-0 rtl:sm:pr-0 "
                    >
                      اسم البطاقة الرقمية
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-sm font-semibold text-gray-900 ltr:text-left rtl:text-right"
                    >
                      رقم الكود أو الرقم السري
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-sm font-semibold text-gray-900 ltr:text-left rtl:text-right"
                    >
                      حالتها
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-sm font-semibold text-gray-900 ltr:text-left rtl:text-right"
                    >
                      ملاحظات
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 ltr:pl-3 ltr:pr-4 rtl:pl-3 rtl:pr-4 rtl:text-right ltr:sm:pr-0 rtl:sm:pl-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {currentCards?.map((card, key) => (
                    <tr key={key}>
                      <td className="whitespace-nowrap py-5 text-sm ltr:pl-4 ltr:pr-3 rtl:pl-3 rtl:pr-4 ltr:sm:pl-0 rtl:sm:pr-0 ">
                        <div className="flex items-center">
                          <div className="h-11 w-11 flex-shrink-0">
                            <div className="relative inline-flex h-11 w-11 items-center justify-center overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600">
                              <span className="font-medium text-gray-600 dark:text-gray-300">
                                {card.title?.slice(0, 2).toUpperCase()}
                              </span>
                            </div>
                          </div>
                          <div className="ltr:ml-4 rtl:mr-4">
                            <div className="font-medium text-gray-900">
                              {card.title}
                            </div>
                            {/* <div className="mt-1 text-gray-500">
                            {person.email}
                          </div> */}
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-4 py-5 text-sm text-gray-500">
                        <div className="flex flex-row items-center justify-start gap-4 ">
                          {newClipboard.show === true &&
                          newClipboard.key === key ? (
                            <ClipboardDocumentCheckIcon
                              className=" h-5 w-5 animate-bounce text-green-600"
                              aria-hidden="true"
                            />
                          ) : (
                            <DocumentDuplicateIcon
                              className=" h-5 w-5 text-gray-400"
                              aria-hidden="true"
                              onClick={() =>
                                updateClipboard(card.digitalCard, {
                                  show: true,
                                  key: key,
                                })
                              }
                            />
                          )}
                          {card.isShow === true ? (
                            <EyeIcon
                              onClick={() => {
                                const selectFilter = currentCards.find(
                                  (item) => item._id === card._id,
                                );
                                setCurrentCards(
                                  currentCards.map((item) =>
                                    item._id === card._id
                                      ? { ...selectFilter, isShow: false }
                                      : item,
                                  ),
                                );
                              }}
                              className="h-6 w-6 text-green-600"
                              aria-hidden="true"
                            />
                          ) : (
                            <EyeSlashIcon
                              onClick={() => {
                                const selectFilter = currentCards.find(
                                  (item) => item._id === card._id,
                                );
                                setCurrentCards(
                                  currentCards.map((item) =>
                                    item._id === card._id
                                      ? { ...selectFilter, isShow: true }
                                      : item,
                                  ),
                                );
                              }}
                              className="h-6 w-6 text-gray-600"
                              aria-hidden="true"
                            />
                          )}
                          {card.isShow === true ? (
                            <div className="text-gray-900">
                              {card.digitalCard}
                            </div>
                          ) : (
                            <div className="pt-1 text-gray-900">
                              ****************
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <span
                          className={classNames(
                            card.isValid
                              ? "bg-green-50 text-green-700 ring-green-600/20"
                              : "bg-red-50 text-red-700 ring-red-600/20",
                            "inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ",
                          )}
                        >
                          {card.isValid ? "Active" : "Not Active"}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {card.note}
                      </td>
                      <td className="relative whitespace-nowrap text-sm font-medium ltr:pl-3  ltr:pr-4 ltr:text-right rtl:pl-4 rtl:pr-3 rtl:text-left ltr:sm:pr-0 rtl:sm:pl-0">
                        <button
                          onClick={() => {
                            setNewCard(
                              currentCards.filter(
                                (item) => item.title === card.title,
                              )[0],
                            );
                            setIsAddCard(true);
                          }}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit<span className="sr-only">, {card.title}</span>
                        </button>
                      </td>
                      <td className="relative whitespace-nowrap text-sm font-medium ltr:pl-3  ltr:pr-4 ltr:text-right rtl:pl-4 rtl:pr-3 rtl:text-left ltr:sm:pr-0 rtl:sm:pl-0">
                        <button
                          onClick={async () => {
                            // setNewCard(
                            //   currentCards.filter(
                            //     (item) => item.title === card.title,
                            //   )[0],
                            // );

                            await instance
                              .delete(`/cards/delete-card/${card._id}`)
                              .then(() => {
                                const filter = currentCards.filter(
                                  (item) => item._id !== card._id,
                                );
                                setCurrentCards(filter);
                              });
                            // setIsAddCard(true);
                          }}
                          className="text-red-600 hover:text-red-900"
                        >
                          Remove<span className="sr-only">, {card.title}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="m-4 sm:mx-0 sm:my-4 ">
            <label
              htmlFor="id"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              اسم البطاقة الرقمية
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="title"
                id="title"
                value={newCard.title}
                className="block w-full rounded-md border bg-gray-50 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="بطاقة شحن سوا 20 ريال"
                onChange={(e) => handleFields(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addNewCard();
                  }
                }}
              />
            </div>
          </div>
          <div className="m-4 sm:mx-0 sm:my-4 ">
            <label
              htmlFor="id"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              رقم الكود أو الرقم السري
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="digitalCard"
                id="digitalCard"
                value={newCard.digitalCard}
                className="block w-full rounded-md border bg-gray-50 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="FJKJSJFSJDLJFSLJSDLFJL"
                onChange={(e) => handleFields(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addNewCard();
                  }
                }}
              />
            </div>
          </div>
          <div className="m-4 sm:mx-0 sm:my-4 ">
            <label
              htmlFor="id"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              ملاحظات
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="note"
                id="note"
                value={newCard.note}
                className="block w-full rounded-md border bg-gray-50 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="إذا لديك ملاحظة اكتبها هنا"
                onChange={(e) => handleFields(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addNewCard();
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
