import {
  CheckBadgeIcon,
  InformationCircleIcon,
  PaperClipIcon,
  ShieldCheckIcon,
} from "@heroicons/react/20/solid";
import jwt_decode from "jwt-decode";
import { useEffect, useState, useRef, useContext } from "react";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import AlertImageProfile from "../components/AlertImageProfile";
import { useAxios } from "../api/api";
import Notifications from "../components/Notifications";
import { Context } from "../context/ContextProvider";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import MerchantAuth from "../components/MerchantAuth";
import { PencilSquareIcon, PlusIcon } from "@heroicons/react/24/outline";
import AlertImageAuthMerchant from "../components/AlertImageAuthMerchant";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Profile({ t, i18n }) {
  const navigate = useNavigate();
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    company,
    user,
    setUser,
    show,
    setShow,
  } = useContext(Context);

  // const [isLoading, setIsLoading] = useState(true);
  // const [company, setCompany] = useState(null);

  // const oneFetch = useRef(true);
  // useEffect(() => {
  //   if (oneFetch.current) {
  //     console.log(
  //       "onFetch: ",
  //       oneFetch.current,
  //       localStorage.getItem("TokenNAEM") != null
  //     );
  //     oneFetch.current = false;
  //     var decoded = jwt_decode(localStorage.getItem("TokenNAEM"));
  //     setCompany(decoded.company);

  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 1000);
  //   }
  //   // else{
  //   //   navigate('/login')
  //   //   // oneFetch.current = true;
  //   // }
  // }, []);

  const { response, loading, error } = useAxios({
    method: "GET",
    url: `/companies/get-company-name/${
      localStorage.getItem("CN") !== null && localStorage.getItem("CN")
    }`,
    headers: {
      accept: "*/*",
    },
  });

  const [alertImageProfile, setAlertImageProfile] = useState(false);
  const [alertImageMerchantAuth, setAlertImageMerchantAuth] = useState(false);
  const [isUpdateImage, setIsUpdateImage] = useState(false);

  const [newOject, setNewObject] = useState({
    // stock: newOject?.stock && newOject.stock > 0 ? newOject.stock : 1000000,
    image: "",
    // response?.logo.length > 10
    //   ? response?.logo
    //   : "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",
  });
  const [newMerchantAuth, setNewMerchantAuth] = useState({
    id: "",
    file: "",
    merchantState: "",
  });

  // https://www.onlinelogomaker.com/blog/wp-content/uploads/2017/06/shopping-online.jpg

  const handleNotification = () => {
    setShow(true);

    setTimeout(() => {
      setShow(false);
      // JSON.parse(localStorage.getItem("Order")).length === 0 &&
      //   navigate(`/${localStorage.getItem("CN")}`);
    }, 1500);
  };

  const [open, setOpen] = useState(false);

  const [isSend, setIsSend] = useState(false);

  return (
    <div className=" bg-white sm:container sm:mx-auto my-0 mx-5">
      {isUpdateImage && <Notifications title={t("updatetitleAlert")} />}
      {loading === false && (
        <MerchantAuth
          t={t}
          open={open}
          setOpen={setOpen}
          isSend={isSend}
          setIsSend={setIsSend}
          i18n={i18n}
          active={true}
          response={response}
        />
      )}
      <AlertImageProfile
        alertImageProfile={alertImageProfile}
        setAlertImageProfile={setAlertImageProfile}
        i18n={i18n}
        t={t}
        email={response?.email}
        newOject={newOject}
        setNewObject={setNewObject}
        isUpdateImage={isUpdateImage}
        setIsUpdateImage={setIsUpdateImage}
        handleNotification={handleNotification}
      />
      <AlertImageAuthMerchant
        alertImageMerchantAuth={alertImageMerchantAuth}
        setAlertImageMerchantAuth={setAlertImageMerchantAuth}
        i18n={i18n}
        t={t}
        email={response?.email}
        newMerchantAuth={newMerchantAuth}
        setNewMerchantAuth={setNewMerchantAuth}
        isUpdateImage={isUpdateImage}
        setIsUpdateImage={setIsUpdateImage}
        handleNotification={handleNotification}
      />
      {loading ? (
        <div className="flex items-center justify-center w-full py-20">
          <div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
            <svg
              fill="none"
              className="w-20 h-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <div>
          <div className=" container mx-auto my-4 flex flex-row justify-between">
            <button
              type="button"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              className="flex bg-indigo-500 hover:bg-indigo-700 h-7 w-7 sm:w-10  sm:h-10 rounded-md drop-shadow-lg  justify-center items-center text-white text-4xl hover:drop-shadow-2xl"
              id="btn-back-to-top"
              onClick={() => {
                navigate("/settings");
                // scrollTop, clientHeight, scrollHeight
              }}
            >
              <ChevronRightIcon
                className=" h-6 w-6 ltr:rotate-180 "
                aria-hidden="true"
              />
            </button>

            {response?.merchantState === "ready" && (
              <div className="text-md sm:text-xl sm:mt-1 font-semibold leading-6 text-white flex-row flex gap-3  rounded px-5 items-center bg-green-500 hover:bg-green-600">
                <CheckBadgeIcon
                  className=" h-6 w-6 text-white "
                  aria-hidden="true"
                />
                <h2 className=" ">معتمد</h2>
              </div>
            )}
            {(response?.merchantState === "review" ||
              newMerchantAuth.merchantState === "review") && (
              <div className="text-md sm:text-xl sm:mt-1 font-semibold leading-6 text-white flex-row flex gap-3  rounded px-5 items-center bg-gray-500 hover:bg-gray-600 ">
                <InformationCircleIcon
                  className=" h-6 w-6 "
                  aria-hidden="true"
                />
                <h2 className=" ">تحت المراجعة</h2>
              </div>
            )}
            {((response?.merchantState == null &&
              newMerchantAuth.merchantState.length === 0) ||
              (response?.merchantState === "reject" &&
                newMerchantAuth.merchantState.length === 0) ||
              (newMerchantAuth.merchantState.length > 0 &&
                newMerchantAuth.merchantState !== "review")) && (
              <button
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 py-1 px-3 sm:px-3.5 sm:py-2.5  text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => {
                  newOject?.image.length === 0 && setIsUpdateImage(false);
                  setAlertImageMerchantAuth(true);
                }}
              >
                <ShieldCheckIcon className="h-5 w-5" aria-hidden="true" />
                <h2>{t("authMerchant")}</h2>
              </button>
            )}
          </div>
          <div className="overflow-hidden shadow sm:rounded-lg ">
            <div className="px-4 py-2 sm:px-6">
              <div className="flex flex-row justify-between">
                <div>
                  <h3 className="text-base font-semibold leading-6 text-gray-900 mt-8">
                    معلومات المتجر
                  </h3>
                </div>
                <div className="flex flex-row gap-3 mt-0">
                  <button
                    onClick={() => {
                      newOject?.image.length === 0 && setIsUpdateImage(false);
                      setAlertImageProfile(true);
                    }}
                    className="mt-1 sm:max-w-2xl text-xs sm:text-md text-red-500 underline "
                  >
                    تغيير الصورة
                  </button>
                  <img
                    className="inline-block h-full w-20 rounded-md"
                    src={
                      newOject?.image.length > 0 && isUpdateImage
                        ? newOject?.image
                        : response?.logo
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    اسم المتجر
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {response?.companyName}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    البريد الإلكتروني
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {response?.email}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    كلمة المرور
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    ********
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          {loading === false && response?.address.length > 1 && (
            <div className="overflow-hidden shadow sm:rounded-lg mt-5">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  {t("addressInformation")}
                </h3>
                {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {t("addressDetails")}
                </p> */}
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0 ">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      {t("storeManagerName")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {response?.storeManagerName}
                    </dd>
                  </div>

                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      {t("phone")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {response?.phone}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      {t("city")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {response?.city}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      {t("address")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {response?.address}
                    </dd>
                  </div>

                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      {t("neighborhood")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {response?.neighborhood}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      {t("zipCode")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {response?.zipCode}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          )}
          {/* <button
            type="button"
            className="my-5 inline-flex items-center gap-x-2 rounded-md bg-purple-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              newOject?.image.length === 0 && setIsUpdateImage(false);
              setAlertImageMerchantAuth(true);
            }}
          >
            {response?.address.length > 1 && true ? (
              <PencilSquareIcon
                className="-ml-0.5 h-5 w-5 ltr:rotate-180"
                aria-hidden="true"
              />
            ) : (
              <ArrowPathIcon
                className="-ml-0.5 h-5 w-5 ltr:rotate-180"
                aria-hidden="true"
              />
            )}

            {response?.address.length > 1 && true
              ? t("authMerchant")
              : t("reAuthMerchant")}
          </button> */}
          <button
            type="button"
            className="my-5 mr-2 inline-flex items-center gap-x-2 rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              setOpen(true);
              // console.log("response: ", response);
            }}
          >
            {response?.address.length > 1 ? (
              <PencilSquareIcon
                className="-ml-0.5 h-5 w-5 ltr:rotate-180"
                aria-hidden="true"
              />
            ) : (
              <PlusIcon
                className="-ml-0.5 h-5 w-5 ltr:rotate-180"
                aria-hidden="true"
              />
            )}

            {response?.address.length > 1 ? t("editAddress") : t("addAddress")}
          </button>
          <button
            type="button"
            className="my-5 mr-2 inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              localStorage.removeItem("TokenNAEM");
              localStorage.removeItem("Order");
              localStorage.setItem("CountCart", 0);
              navigate("/login");
            }}
          >
            <ArrowRightOnRectangleIcon
              className="-ml-0.5 h-5 w-5"
              aria-hidden="true"
            />
            تسجيل خروج
          </button>
        </div>
      )}
    </div>
  );
}
