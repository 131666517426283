import { Fragment, useContext, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  ShoppingBagIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { Context } from "../context/ContextProvider";
import { useParams, useNavigate, useHref } from "react-router-dom";
import instance, { useAxios } from "../api/api";
import jwt_decode from "jwt-decode";
import Banner from "./Banner";
import { LifebuoyIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavDisClosure({ changeLanguage, lang, t }) {
  const {
    amountOfItemsInCart,
    newList,
    cartItmes,
    numberInCart,
    company,
    setCompany,
    setOrders,
  } = useContext(Context);

  const navigation = [
    {
      name: `${t("home")}`,
      href: `/`,
      current: true,
    },
    {
      name: `${t("products")}`,
      href: `/${localStorage.getItem("CN")}`,
      // href: `/ater`,
      current: true,
    },
    { name: `${t("orders")}`, href: `/orders`, current: false },
    { name: `${t("settings")}`, href: `/settings`, current: false },
  ];

  const navigate = useNavigate();
  const path = useHref();

  // const { response, loading, error } = useAxios({
  //   method: "GET",
  //   // url: `/companies/get-company-name/ater`,
  //   url: `/companies/get-company-name/${
  //     localStorage.getItem("CN") !== null && localStorage.getItem("CN")
  //   }`,
  //   headers: {
  //     accept: "*/*",
  //   },
  // });

  // const updateCart = async () => {
  //   console.log("here dd .....", localStorage.getItem("OrderId"), cartItmes);
  //   try {
  //     const data = await instance.post("/orders/update", {
  //       _id: localStorage.getItem("OrderId"),
  //       orders: cartItmes,
  //     });
  //     console.log("data :", data);
  //   } catch (error) {
  //     console.log("error: ", error.message);
  //   }
  // };

  // setCompany(response); localStorage.getItem("OrderId") max-w-7xl

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log("hello: ", response);
  //   }, 1500);
  // }, []);

  const cartFull =
    localStorage.getItem("Order") != null &&
    JSON.parse(localStorage.getItem("Order")).length > 0
      ? JSON.parse(localStorage.getItem("Order"))
          // .filter((p) => p.merchant_id === response?._id)
          .map((item) => item.qty)
          .reduce((previous, item) => previous + item, 0)
      : 0;
  return (
    <>
      <Banner />
      <Disclosure as="nav" className="footer-image">
        {({ open }) => (
          <>
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="flex flex-1 ">
                  <div className="h-8  w-auto sm:hidden">
                    <Disclosure.Button className=" rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <h2 className="ml-5 mt-1 text-left text-2xl font-bold text-white rtl:mr-5 rtl:text-right sm:text-2xl">
                    NAEM
                  </h2>
                  {/* <img
                    className="h-10 w-auto pt-0  ltr:ml-4 rtl:mr-4 "
                    src={require("../images/logo512.png")}
                    alt="Your Company"
                  /> */}
                  {/* <img
                    className="h-8 md:h-10  w-auto sm:mt-3 xl:mt-0  rounded"
                    src="https://www.onlinelogomaker.com/blog/wp-content/uploads/2017/06/shopping-online.jpg"
                    alt=""
                  /> */}

                  {/* </div> */}
                  <div className="hidden sm:ml-6 sm:mr-6 sm:block">
                    <div className="flex space-x-4 space-x-reverse">
                      {navigation.map((item, key) => (
                        <button
                          key={item.name}
                          onClick={async () => {
                            navigate(`${item.href}`);
                          }}
                          className={classNames(
                            path === item.href
                              ? "bg-gray-700 text-white"
                              : "text-gray-300 hover:bg-gray-500 hover:text-white",
                            "mt-0.5 rounded-md px-3 text-sm sm:py-2 ",
                          )}
                          // aria-current={item.current}
                          // aria-current="page"
                          aria-current={path === item.href ? "page" : undefined}
                        >
                          {item.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex flex-1 items-center justify-end">
                  <div className="hidden sm:flex ltr:sm:pr-8 rtl:sm:pl-8">
                    <button
                      onClick={() => changeLanguage(lang)}
                      className="flex items-center text-white"
                    >
                      <img
                        src={
                          lang === "rtl"
                            ? "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                            : "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"
                        }
                        alt=""
                        className="block h-auto w-5 flex-shrink-0"
                      />
                      <span className="block text-sm font-medium ltr:ml-3 rtl:mr-3">
                        {t("lang")}
                      </span>
                      <span className="sr-only">, change currency</span>
                    </button>
                  </div>

                  {/* Cart */}
                  {localStorage.getItem("Order") != null &&
                    JSON.parse(localStorage.getItem("Order")).length > 0 && (
                      <div className="flow-root ltr:mr-6 rtl:ml-6 ltr:lg:ml-8 rtl:lg:mr-8">
                        <button
                          onClick={() => {
                            cartFull !== 0 &&
                              localStorage.setItem("CountCart", cartFull);
                            navigate("/cart");
                          }}
                          className="group -m-2 flex items-center p-2"
                        >
                          <ShoppingBagIcon
                            className="h-6 w-6 flex-shrink-0   text-white hover:text-blue-400 "
                            aria-hidden="true"
                          />
                          <span className="text-sm font-medium text-white hover:text-blue-400  ltr:ml-1 rtl:mr-1">
                            {cartFull}
                          </span>
                          <span className="sr-only">
                            items in cart, view bag
                          </span>
                        </button>
                      </div>
                    )}
                  <div className="flow-root rounded bg-green-600 p-2 hover:bg-green-700 ltr:mr-6 rtl:ml-6 ltr:lg:ml-8 rtl:lg:mr-8 ">
                    <a
                      href="mailto:info@naem.me"
                      className="group -m-2 flex items-center gap-2 p-2"
                    >
                      <LifebuoyIcon
                        className="h-6 w-6 flex-shrink-0  text-white hover:text-blue-400"
                        aria-hidden="true"
                      />
                      <p className="text-white">{t("supportTitle")}</p>
                      {/* <span className="sr-only">items in cart, view bag</span> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    // href={item.href}
                    onClick={async () => {
                      navigate(`${item.href}`);
                    }}
                    className={classNames(
                      path === item.href
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium",
                    )}
                    aria-current={path === item.href ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}

                <div className=" block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-gray-700">
                  <button
                    onClick={() => changeLanguage(lang)}
                    className="-m-2 flex items-center p-2 "
                  >
                    <img
                      src={
                        lang === "rtl"
                          ? "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                          : "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"
                      }
                      alt=""
                      className="block h-auto w-5 flex-shrink-0"
                    />
                    <span className="ml-3 block text-base font-medium text-white rtl:mr-3">
                      {t("lang")}
                    </span>
                    <span className="sr-only">, change currency</span>
                  </button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>{" "}
    </>
  );
}
