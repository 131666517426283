import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import {
  CheckIcon,
  DocumentCheckIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import instance from "../api/api";
import {
  ArrowPathIcon,
  DocumentIcon,
  MagnifyingGlassIcon,
  PhotoIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
// import { Listbox, Tab, Transition } from "@headlessui/react";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// alertDigitalProduct,
// setAlertDigitalProduct,
export default function AlertDigitalProduct({
  alertDigitalProduct,
  setAlertDigitalProduct,
  i18n,
  t,
  newOject,
  newDigitalProductData,
  setNewDigitalProductData,
  isUpdateImage,
  setIsUpdateImage,
  handleNotification,
  setNewObject,
}) {
  // const [newOject, setNewObject] = useState({
  //   // stock: newOject?.stock && newOject.stock > 0 ? newOject.stock : 1000000,
  //   image:
  //     "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",
  // });

  //   const [file, setFile] = useState(null);
  const [imageName, setImageName] = useState(
    "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",
  );
  const cancelButtonRef = useRef(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [isLoadingUpdateFinish, setIsLoadingUpdateFinish] = useState(false);
  const [isText, setIsText] = useState(false);

  useEffect(() => {
    if (isText) {
      setIsText(false);
    }
  }, [isText]);

  //   const inputRef = useRef();

  const handleFields = (e) => {
    // setNewDigitalProductData({
    //   ...newDigitalProductData,
    //   [e.target.name]: e.target.value,
    // });
    setNewObject({
      ...newOject,
      [e.target.name]: e.target.value,
    });
  };

  const onChange = async (e) => {
    e.preventDefault();
    setIsText(true);
    setLoadingImage(true);
    setImageName("");
    // setFile(e.target.files[0]);
    var src = URL.createObjectURL(e.target.files[0]);
    setImageName(src);
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    await axios
      .post(
        "https://fatora-sa.herokuapp.com/api/v1/products/create-certificate",
        formData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Contant-Type": "multipart/form-data",
          },
        },
      )
      .then(({ data }) => {
        // setNewDigitalProductData({
        //   ...newDigitalProductData,
        //   digitalProductFile: `http://localhost:9000/api/v1/products/${data.createOneImage}`,
        // });
        setNewObject({
          ...newOject,
          digitalProductFile: `https://fatora-sa.herokuapp.com/api/v1/products/${data.createOneImage}`,
        });

        setLoadingImage(false);

        // setTimeout(() => {
        //   // console.log("data image: ", newOject.image);
        // }, 2000);
      })
      .catch((e) => setLoadingImage(false) & console.log("error dd: ", e));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsText(true);
    setLoadingImage(true);
    // setFile(event.dataTransfer.files[0]);
    var src = URL.createObjectURL(event.dataTransfer.files[0]);
    setImageName(src);
    const formData = new FormData();
    formData.append("image", event.dataTransfer.files[0]);
    await axios
      .post(
        "https://fatora-sa.herokuapp.com/api/v1/products/create-certificate",
        formData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Contant-Type": "multipart/form-data",
          },
        },
      )
      .then(({ data }) => {
        // setNewDigitalProductData({
        //   ...newDigitalProductData,
        //   digitalProductFile: `https://fatora-sa.herokuapp.com/api/v1/products/${data.createOneImage}`,
        // });
        setNewObject({
          ...newOject,
          digitalProductFile: `https://fatora-sa.herokuapp.com/api/v1/products/${data.createOneImage}`,
        });

        setLoadingImage(false);
      })
      .catch((e) => setLoadingImage(false) & console.log("error: ", e));
  };

  const deleteImage = async (key) => {
    // setLoadingImage(true);
    const deleteOneImage = newOject?.digitalProductFile
      .split(",")
      [key].replace(/^.*[\\\/]/, "");
    // console.log("deleteOneImage: ", deleteOneImage);
    try {
      if (deleteOneImage.length > 0)
        await instance
          .post("/products/delete-one-image", {
            filename: deleteOneImage,
          })
          .then(async ({ data }) => {
            // setNewDigitalProductData({
            //   digitalProductFilePassword: "",
            //   digitalProductFile: "",
            //   merchantState: "",
            // });
            setNewObject({
              ...newOject,
              digitalProductFilePassword: null,
              digitalProductFile: null,
            });
            setIsText(false);
          });
    } catch (error) {
      // setLoadingImage(false);
      console.log("error: ", error.message);
    }
  };

  return (
    <Transition.Root show={alertDigitalProduct} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          newOject?.digitalProductFile?.length > 0 && deleteImage(0);
          setIsUpdateImage(false);
          setAlertDigitalProduct(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-3000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="my-20 flex min-h-full items-start justify-center p-3 text-center sm:items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="transform ease-out duration-500 transition"
              enterFrom="translate-y-0 opacity-100 sm:translate-x-0"
              enterTo="opacity-100 translate-y-2 translate-x-0"
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-0 pb-4 pt-0 shadow-xl transition-all ltr:text-left rtl:text-right sm:my-8  sm:max-w-2xl sm:p-6">
                <div>
                  <div className="mt-0 text-center sm:mt-0">
                    <div className="mb-4 mt-0 w-full text-right">
                      <div>
                        <div className="flex w-full flex-row justify-between bg-indigo-200 p-2 sm:mx-auto sm:rounded-md sm:p-0">
                          <p
                            htmlFor="url"
                            className="block px-2 py-2 text-sm font-medium  leading-6 text-gray-600"
                          >
                            {t("addDigitalProduct")}
                          </p>
                          <XMarkIcon
                            onClick={() => {
                              newOject?.digitalProductFile?.length > 0
                                ? deleteImage(0)
                                : setNewObject({
                                    ...newOject,
                                    digitalProductFilePassword: null,
                                    digitalProductFile: null,
                                  });
                              setAlertDigitalProduct(false);
                              setIsUpdateImage(false);
                              //   setNewObject({ url: "" });
                            }}
                            className=" mx-4 mt-2.5 h-5 w-5 text-gray-600"
                            aria-hidden="true"
                          />
                        </div>

                        <div className="m-4 sm:mx-0 sm:my-4 ">
                          <label
                            htmlFor="id"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            كلمة المرور (اختياري)
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="digitalProductFilePassword"
                              id="digitalProductFilePassword"
                              value={newOject.digitalProductFilePassword
                                ?.toString()
                                .replace(/[٠-٩]/g, (d) =>
                                  "٠١٢٣٤٥٦٧٨٩".indexOf(d),
                                )}
                              className="block w-full rounded-md border bg-gray-50 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="كلمة مرور للملف (اختيارية)"
                              onChange={(e) => handleFields(e)}
                            />
                          </div>
                        </div>

                        {loadingImage && (
                          <div className="mt-7 flex h-24 w-24 py-7">
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline h-10 w-full animate-spin text-indigo-500"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                        )}

                        {newOject?.digitalProductFile?.length > 0 ? (
                          <div className="mx-5 grid grid-cols-2 items-start sm:mx-0 sm:grid-cols-3 ">
                            <Tab.Group
                              as="div"
                              className="flex flex-col-reverse"
                            >
                              {/* hidden */}
                              <div className="container mx-auto mt-2 w-full max-w-2xl sm:block lg:max-w-none">
                                <Tab.List className="grid grid-cols-2">
                                  {newOject.digitalProductFile
                                    .split(",")
                                    .map((image, index) => (
                                      <Tab
                                        key={index}
                                        className="relative flex h-16 w-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50  "
                                      >
                                        {({ selected }) => (
                                          <div className="">
                                            <span className="absolute inset-0 overflow-hidden rounded-md">
                                              <>
                                                <XCircleIcon
                                                  className="absolute ml-0 mt-0 h-7 w-7  rounded-2xl text-red-600 hover:bg-red-50  "
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    deleteImage(index)
                                                  }
                                                />
                                                <DocumentCheckIcon
                                                  className="h-full w-full object-cover object-center text-indigo-600"
                                                  aria-hidden="true"
                                                />
                                              </>
                                            </span>

                                            <span
                                              className={classNames(
                                                selected
                                                  ? "ring-indigo-600"
                                                  : "ring-transparent",
                                                "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2",
                                              )}
                                              aria-hidden="true"
                                            />
                                          </div>
                                        )}
                                      </Tab>
                                    ))}
                                </Tab.List>
                              </div>
                            </Tab.Group>
                          </div>
                        ) : null}

                        {isText === false ? (
                          <div className="m-4 sm:mx-0 sm:my-4 ">
                            <label
                              htmlFor="id"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              أضف رابط لديك
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="digitalProductFile"
                                id="digitalProductFile"
                                value={newOject.digitalProductFile}
                                className="block w-full rounded-md border bg-gray-50 px-2 py-1.5 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="https://googdrive/com/gooogle/digital.pdf"
                                onChange={(e) => {
                                  handleFields(e);
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                        {isText === false && (
                          <>
                            <div className="divide-y">
                              <div></div>
                              <div></div>
                            </div>

                            <div className="mx-5 mt-4 sm:mx-0 sm:mt-4 ">
                              <label
                                htmlFor="id"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                أو ارفع الملف من خلال:
                              </label>
                            </div>
                          </>
                        )}

                        {isText === false && (
                          <div className="col-span-full mx-5 my-2 sm:mx-0">
                            <div
                              className="mt-5 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                              onDragOver={handleDragOver}
                              onDrop={handleDrop}
                            >
                              <div className="text-center">
                                <DocumentIcon
                                  className="mx-auto h-12 w-12 text-gray-300"
                                  aria-hidden="true"
                                />
                                <div className="mt-4 flex text-sm leading-6 text-gray-600 outline-none">
                                  <label
                                    htmlFor="file"
                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600   outline-none hover:text-indigo-500"
                                  >
                                    <span className=" outline-none">
                                      {t("addFile")}
                                    </span>
                                    <input
                                      id="file"
                                      name="file"
                                      type="file"
                                      className="sr-only"
                                      onChange={onChange}

                                      //   ref={inputRef}
                                      //   onDragOver={handleDragOver}
                                      //   onDrop={handleDrop}
                                    />
                                  </label>
                                  <p className="pl-2 pr-2">
                                    {t("or")} {t("addFileTitle1")}
                                  </p>
                                </div>
                                <p className="text-xs leading-5 text-gray-600">
                                  PDF up to 10MB
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* ) : null} */}

                        {(newOject?.digitalProductFile?.length > 0 &&
                          loadingImage === false) ||
                        (newOject?.digitalProductFilePassword?.length > 0 &&
                          loadingImage === false) ? (
                          <div className=" -mb-4 mt-5 flex w-full items-center justify-end ">
                            <button
                              type="button"
                              className="mx-4 my-0 inline-flex w-24 items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline sm:mx-0 "
                              onClick={() => {
                                setIsLoadingUpdateFinish(true);
                                setTimeout(() => {
                                  handleNotification();
                                  setIsUpdateImage(true);
                                  setIsLoadingUpdateFinish(false);
                                  setAlertDigitalProduct(false);
                                  if (
                                    newOject.digitalProductFilePassword ===
                                      null ||
                                    newOject.digitalProductFilePassword === ""
                                  ) {
                                    setNewObject({
                                      ...newOject,
                                      digitalProductFilePassword:
                                        "لا يحتوي على كلمة مرور",
                                    });
                                  } else {
                                    setNewObject({
                                      ...newOject,
                                      digitalProductFilePassword:
                                        newOject.digitalProductFilePassword,
                                    });
                                  }
                                  setIsText(true);
                                }, 1000);
                              }}
                            >
                              {isLoadingUpdateFinish ? (
                                <>
                                  <svg
                                    aria-hidden="true"
                                    role="status"
                                    className="inline h-5 w-full animate-spin text-white ltr:mr-3 rtl:ml-3"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="#E5E7EB"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </>
                              ) : (
                                <>
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  تأكيد
                                </>
                              )}
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
