import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import {
  CheckIcon,
  DocumentCheckIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import instance from "../api/api";
import {
  ArrowPathIcon,
  DocumentIcon,
  MagnifyingGlassIcon,
  PhotoIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
// import { Listbox, Tab, Transition } from "@headlessui/react";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AlertImageAuthMerchant({
  alertImageMerchantAuth,
  setAlertImageMerchantAuth,
  i18n,
  t,
  email,
  newOject,
  newMerchantAuth,
  setNewMerchantAuth,
  isUpdateImage,
  setIsUpdateImage,
  handleNotification,
}) {
  // const [newOject, setNewObject] = useState({
  //   // stock: newOject?.stock && newOject.stock > 0 ? newOject.stock : 1000000,
  //   image:
  //     "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png",
  // });

  const [file, setFile] = useState(null);
  const [imageName, setImageName] = useState(
    "https://fatora-sa.herokuapp.com/api/v1/products/1682959636665-fatora-cashier-placeholder-image.png"
  );
  const cancelButtonRef = useRef(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [isLoadiingUpdateFinish, setIsLoadingUpdateFinish] = useState(false);

  const inputRef = useRef();

  const handleFields = (e) => {
    setNewMerchantAuth({ ...newMerchantAuth, [e.target.name]: e.target.value });
  };

  const onChange = async (e) => {
    e.preventDefault();
    setLoadingImage(true);
    setImageName("");
    setFile(e.target.files[0]);
    var src = URL.createObjectURL(e.target.files[0]);
    setImageName(src);
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    await axios
      .post(
        "https://fatora-sa.herokuapp.com/api/v1/products/create-certificate",
        formData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Contant-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => {
        // console.log("data: ", data);
        setNewMerchantAuth({
          ...newMerchantAuth,
          file: `https://fatora-sa.herokuapp.com/api/v1/products/${data.createOneImage}`,
        });
        setLoadingImage(false);
        // setTimeout(() => {
        //   // console.log("data image: ", newOject.image);
        // }, 2000);
      })
      .catch((e) => setLoadingImage(false) & console.log("error: ", e));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setLoadingImage(true);
    setFile(event.dataTransfer.files[0]);
    var src = URL.createObjectURL(event.dataTransfer.files[0]);
    setImageName(src);
    const formData = new FormData();
    formData.append("image", event.dataTransfer.files[0]);
    await axios
      .post(
        "https://fatora-sa.herokuapp.com/api/v1/products/create-certificate",
        formData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Contant-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => {
        setNewMerchantAuth({
          ...newMerchantAuth,
          file: `https://fatora-sa.herokuapp.com/api/v1/products/${data.createOneImage}`,
        });
        setLoadingImage(false);
      })
      .catch((e) => setLoadingImage(false) & console.log("error: ", e));
  };

  const deleteImage = async (key) => {
    // setLoadingImage(true);
    const deleteOneImage = newMerchantAuth.file
      .split(",")
      [key].replace(/^.*[\\\/]/, "");
    console.log("deleteOneImage: ", deleteOneImage);
    try {
      if (deleteOneImage.length > 0)
        await instance
          .post("/products/delete-one-image", {
            filename: deleteOneImage,
          })
          .then(async ({ data }) => {
            setNewMerchantAuth({
              id: "",
              file: "",
              merchantState: "",
            });
          });
    } catch (error) {
      // setLoadingImage(false);
      console.log("error: ", error.message);
    }
  };

  // useEffect(() => {
  //   console.log("jldf: ", newOject.image);
  //   // window.location.reload();
  // }, []);

  const updateLogo = async () => {
    setIsLoadingUpdateFinish(true);
    try {
      const updateLogo = await instance.post("/companies/update-company-web", {
        email,
        merchantAuthFile: newMerchantAuth.file,
        merchantAuthId: newMerchantAuth.id,
        merchantState: "review",
      });
      const jj = updateLogo.data.message.includes("done");
      if (jj) {
        setIsLoadingUpdateFinish(false);
        setAlertImageMerchantAuth(false);
        setIsUpdateImage(true);
        setNewMerchantAuth({
          id: "",
          file: "",
          merchantState: "review",
        });
        handleNotification();
        // window.location.reload();
      }
    } catch (error) {
      setIsLoadingUpdateFinish(false);
      console.log("data: ", error.message);
    }
  };

  return (
    <Transition.Root show={alertImageMerchantAuth} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          newMerchantAuth.file.length > 0
            ? newMerchantAuth?.file.length === 0 && deleteImage(0)
            : console.log("here ...");
          // : updateLogo();
          console.log(
            "newMerchantAuth.file.length > 0: ",
            newMerchantAuth.file.length > 0
          );
          setAlertImageMerchantAuth(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-3000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-start my-20 justify-center p-3 text-center sm:items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="transform ease-out duration-500 transition"
              enterFrom="translate-y-0 opacity-100 sm:translate-x-0"
              enterTo="opacity-100 translate-y-2 translate-x-0"
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-0 pb-4 pt-0 rtl:text-right ltr:text-left shadow-xl transition-all sm:my-8 sm:max-w-2xl  sm:p-6 w-full">
                <div>
                  <div className="mt-0 text-center sm:mt-0">
                    <div className="mt-0 mb-4 w-full text-right">
                      <div>
                        <div className="flex flex-row justify-between w-full bg-indigo-200 sm:rounded-md sm:mx-auto">
                          <p
                            htmlFor="url"
                            className="block text-sm font-medium leading-6 text-gray-600  px-2 py-2"
                          >
                            {t("authMerchant")}
                          </p>
                          <XMarkIcon
                            onClick={() => {
                              deleteImage(0);
                              console.log("dd", newMerchantAuth.file);
                              newMerchantAuth.file.length > 0
                                ? newMerchantAuth.file.length === 0 &&
                                  deleteImage(0)
                                : console.log("here ...");
                              // : updateLogo();
                              setAlertImageMerchantAuth(false);
                              //   setNewObject({ url: "" });
                            }}
                            className=" h-5 w-5 text-gray-600 mt-2.5 mx-4"
                            aria-hidden="true"
                          />
                        </div>

                        <div className="m-4 sm:my-4 sm:mx-0 ">
                          <label
                            htmlFor="id"
                            className="block text-sm font-medium leading-6 text-blue-900"
                          >
                            {t("addFileForBusiness")}
                          </label>
                        </div>
                        <div className="m-4 sm:my-4 sm:mx-0 ">
                          <label
                            htmlFor="id"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            رقم التوثيق{" "}
                            <strong className="text-red-500">*</strong>
                          </label>
                          <div className="mt-2">
                            <input
                              type="id"
                              name="id"
                              id="id"
                              value={newMerchantAuth.id
                                .toString()
                                .replace(/[٠-٩]/g, (d) =>
                                  "٠١٢٣٤٥٦٧٨٩".indexOf(d)
                                )}
                              className="bg-gray-50 px-2 block w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="اكتب رمز الوثيقة من المركز السعودي للأعمال"
                              onChange={(e) => handleFields(e)}
                            />
                          </div>
                        </div>

                        {loadingImage && (
                          <div className="w-24 h-24 mt-7 flex py-7">
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline h-10 text-indigo-500 animate-spin w-full"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                        )}

                        {newMerchantAuth?.file.length > 0 ? (
                          <div className="grid grid-cols-2 sm:grid-cols-3 items-start mx-5 sm:mx-0 ">
                            <Tab.Group
                              as="div"
                              className="flex flex-col-reverse"
                            >
                              {/* hidden */}
                              <div className="mx-auto container mt-6 w-full max-w-2xl sm:block lg:max-w-none">
                                <Tab.List className="grid grid-cols-2">
                                  {newMerchantAuth.file
                                    .split(",")
                                    .map((image, index) => (
                                      <Tab
                                        key={index}
                                        className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50  "
                                      >
                                        {({ selected }) => (
                                          <div className="">
                                            <span className="absolute inset-0 overflow-hidden rounded-md">
                                              <>
                                                <XCircleIcon
                                                  className="h-7 w-7 absolute mt-0 ml-0  text-red-600 hover:bg-red-50 rounded-2xl  "
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    deleteImage(index)
                                                  }
                                                />
                                                <DocumentCheckIcon
                                                  className="h-full w-full object-cover object-center text-indigo-600"
                                                  aria-hidden="true"
                                                />
                                              </>
                                            </span>

                                            <span
                                              className={classNames(
                                                selected
                                                  ? "ring-indigo-600"
                                                  : "ring-transparent",
                                                "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                              )}
                                              aria-hidden="true"
                                            />
                                          </div>
                                        )}
                                      </Tab>
                                    ))}
                                </Tab.List>
                              </div>

                              {/* <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                              {newMerchantAuthFile.image
                                ? newMerchantAuthFile.image
                                    .split(",")
                                    .map((image, key) => (
                                      <Tab.Panel key={key}>
                                        <img
                                          src={image}
                                          alt={key}
                                          className="h-full w-full object-cover object-center sm:rounded-lg"
                                        />
                                      </Tab.Panel>
                                    ))
                                : ""}
                            </Tab.Panels> */}
                            </Tab.Group>
                          </div>
                        ) : null}

                        {newMerchantAuth?.file.length === 0 && (
                          <div className="col-span-full my-5 mx-5 sm:mx-0">
                            <div
                              className="mt-5 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                              onDragOver={handleDragOver}
                              onDrop={handleDrop}
                            >
                              <div className="text-center">
                                <DocumentIcon
                                  className="mx-auto h-12 w-12 text-gray-300"
                                  aria-hidden="true"
                                />
                                <div className="mt-4 flex text-sm leading-6 text-gray-600 outline-none">
                                  <label
                                    htmlFor="file"
                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600   hover:text-indigo-500 outline-none"
                                  >
                                    <span className=" outline-none">
                                      {t("addFile")}
                                    </span>
                                    <input
                                      id="file"
                                      name="file"
                                      type="file"
                                      className="sr-only"
                                      onChange={onChange}
                                      ref={inputRef}
                                      // onDragOver={handleDragOver}
                                      // onDrop={handleDrop}
                                    />
                                  </label>
                                  <p className="pl-2 pr-2">
                                    {t("or")} {t("addFileTitle1")}{" "}
                                  </p>
                                </div>
                                <p className="text-xs leading-5 text-gray-600">
                                  PDF up to 10MB
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* ) : null} */}

                        {newMerchantAuth?.file.length > 0 &&
                        newMerchantAuth?.id.length > 0 &&
                        loadingImage === false ? (
                          <div className=" w-full flex justify-end mt-5 -mb-4 items-center ">
                            <button
                              type="button"
                              className="my-0 mx-4 w-24 sm:mx-0 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline "
                              onClick={updateLogo}
                            >
                              {isLoadiingUpdateFinish ? (
                                <>
                                  <svg
                                    aria-hidden="true"
                                    role="status"
                                    className="inline h-5 text-white animate-spin rtl:ml-3 ltr:mr-3 w-full"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="#E5E7EB"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </>
                              ) : (
                                <>
                                  <PaperAirplaneIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  إرسال
                                </>
                              )}
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
