import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";

import List from "@editorjs/list";
import Marker from "@editorjs/marker";
import InlineCode from "@editorjs/inline-code";

const DEFAULT_INITIAL_DATA = {
  time: new Date().getTime(),
  blocks: [
    {
      type: "header",
      data: {
        text: "This is my awesome editor!",
        level: 1,
      },
    },
  ],
};

const EditorComponent = ({ description, setNewObject, newOject }) => {
  const ejInstance = useRef();
  const [cont, setCont] = useState("");

  useEffect(() => {
    setNewObject({
      ...newOject,
      description: cont,
    });
  }, [cont]);

  const initEditor = () => {
    const editor = new EditorJS({
      i18n: {
        /**
         * Text direction
         */
        direction: "rtl",
      },
      holder: "editorjs",
      onReady: () => {
        ejInstance.current = editor;
      },
      autofocus: true,

      // data: DEFAULT_INITIAL_DATA,
      onChange: async () => {
        let content = await editor.saver.save();
        const jj = content.blocks.map((c) => {
          let textD = "";
          textD += c.data.text + "\n";

          return textD;
        });
        setCont(jj.join(" "));

        const jjd = await editor.save().then(({ blocks }) => {
          console.log("Output: " + JSON.stringify(blocks));
        });
      },

      tools: {
        list: List,
        marker: Marker,
        inlineCode: InlineCode,
        quote: {
          class: Quote,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+O",
          config: {
            quotePlaceholder: "Enter a quote",
            captionPlaceholder: "Quote's author",
          },
        },

        header: {
          class: Header,
          shortcut: "CMD+SHIFT+H",
          config: {
            placeholder: "Enter a header",
            levels: [1, 2, 3, 4, 5, 6],
            defaultLevel: 1,
          },
        },
      },
    });
  };

  // This will run only once
  useEffect(() => {
    if (ejInstance.current === null) {
      initEditor();
    }

    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, []);

  return (
    <>
      <div className="text-bold  text-black " id="editorjs"></div>
    </>
  );
};

export default EditorComponent;

// https://blog.logrocket.com/best-text-editors-react/
//https://codesandbox.io/p/sandbox/react-editorjs-example-ng6qzo?file=%2Fsrc%2Fcomponents%2Feditor-parser%2FEditorTextParser.js%3A7%2C25-7%2C41
//https://editorjs.io/getting-started/
