import { Fragment, useContext, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Context } from "../context/ContextProvider";
import {
  ArchiveBoxArrowDownIcon,
  ClipboardIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Branded({ kind }) {
  const {
    selectedBranded,
    setSelectedBranded,
    publishingOptions,
    selected,
    setSelected,
  } = useContext(Context);

  useEffect(() => {
    // setSelectedBranded(publishingOptions[kind]);
    // console.log("kind: ", kind, selectedBranded);

    setSelected(publishingOptions[selectedBranded]);
  }, [selectedBranded]);

  return (
    <Listbox
      value={selected}
      onChange={(e) => {
        setSelected(e);
        setSelectedBranded(e.id);
      }}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">
            Change published status
          </Listbox.Label>
          <div className="relative">
            <Listbox.Button className="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
              <div className="inline-flex items-center gap-x-1.5 bg-indigo-600 px-3 py-2 text-white shadow-sm ltr:rounded-l-md rtl:rounded-r-md">
                <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                <p className="text-sm font-semibold">{selected.title}</p>
              </div>
              <div className="inline-flex items-center bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50 ltr:rounded-l-none ltr:rounded-r-md rtl:rounded-l-md rtl:rounded-r-none">
                <span className="sr-only">Change published status</span>
                <ChevronDownIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ltr:right-0 rtl:left-0">
                {publishingOptions.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "cursor-default select-none p-4 text-sm",
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <div className="flex flex-row items-center justify-start gap-2">
                            {option.icon}
                            <p
                              className={
                                selected ? " font-bold" : "font-normal"
                              }
                            >
                              {option.title}
                            </p>
                          </div>
                          {selected ? (
                            <span
                              className={
                                active ? "text-white" : "text-indigo-600"
                              }
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                        <p
                          className={classNames(
                            active ? "text-indigo-200" : "text-gray-500",
                            "mt-2",
                          )}
                        >
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
