/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState, useContext } from "react";
import { useAxios } from "../api/api";
import instance from "../api/api";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Register() {
  const { response } = useAxios({
    method: "GET",
    url: `/companies/get-company-name/${localStorage.getItem("CN")}`,
    headers: {
      accept: "*/*",
    },
  });

  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [statusError, setStatusError] = useState("");
  const [handleError, setHandleError] = useState("");
  const [newOject, setNewObject] = useState({
    email: "",
    password: "",
    companyName: "",
    phone: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleFields = (e) => {
    // console.log("error :", e.target.name);
    if (e.target.name === "password") {
      setIsError(
        newOject.password.length >= 0 && newOject.password.length <= 5
      );
      setNewObject({ ...newOject, [e.target.name]: e.target.value });
    } else if (e.target.name === "phone") {
      setNewObject({
        ...newOject,
        [e.target.name]: e.target.value.replace(/[^0-9]+/g, ""),
      });
    } else {
      setHandleError("");
      setNewObject({ ...newOject, [e.target.name]: e.target.value });
    }
  };

  const formLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (newOject.phone.length !== 12) {
      setStatusError(12);
      setHandleError("رقم الجوال ليس بالصورة المطلوبة");
      setIsLoading(false);
      return;
    }

    try {
      console.log("data: ", newOject);
      const res = await instance.post(`/companies/register`, newOject);

      if (res) {
        // localStorage.setItem("Token", request.data.accessToken);
        // console.log("jj :", request.data);
        // setTimeout(() => {
        //   setNewObject({ companyName: "", email: "", password: "", phone:""});
        //   setIsLoading(false);
        //   navigate("/login");
        // }, 2000);

        const { data } = await instance.post(
          `/companies/send-verification-otp`,
          {
            number: newOject.phone,
          }
        );
        // console.log("data otp: ", data);
        if (data.message === "Success") {
          const req = await instance.post(`/companies/login`, {
            email: newOject.email,
            password: newOject.password,
          });
          localStorage.setItem("TokenNAEM", req.data.accessToken);
          setIsLoading(false);
          setNewObject({ companyName: "", email: "", password: "", phone: "" });
          navigate(`/verify-otp/${data.code}/${newOject.phone}`,{
            state: { otp: data },
          })
        } else {
          setIsLoading(false);
          setHandleError("You can't access this page.");
        }
      }
    } catch (error) {
      // console.log("error", typeof error.response.status);
      setStatusError(error.response.status);
      setHandleError(error.response.data.error);
      setIsLoading(false);
      // setNewObject({ companyName: "", email: "", password: "", phone: "" });
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center my-20 sm:px-6 lg:px-8 container mx-auto">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto rounded"
            src={require("../images/logo512.png")}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-2xl sm:text-3xl  font-bold tracking-tight text-gray-900">
            سجل وابدأ تجارتك في لحظات
          </h2>
          {/* <p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                start your 14-day free trial
              </a>
            </p> */}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full ">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10 mx-5">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={formLogin}
            >
              <div>
                <label
                  htmlFor="companyName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  اسم المتجر
                </label>
                <div className="mt-2">
                  <input
                    id="companyName"
                    name="companyName"
                    type="companyName"
                    value={newOject.companyName}
                    onChange={(e) => handleFields(e)}
                    autoComplete="companyName"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  البريد الإلكتروني
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={newOject.email}
                    onChange={(e) => handleFields(e)}
                    autoComplete="email"
                    placeholder="you@example.com"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                {statusError === 402 && handleError?.length > 0 && (
                  <span className="flex items-center font-medium  tracking-wide text-red-500 text-xs mt-1 ltr:ml-1 rtl:mr-1 ">
                    {handleError}
                  </span>
                )}
              </div>

              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  رقم الجوال
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 rtl:left-3  flex items-center rtl:pr-3 ltr:pl-3">
                    {/* <span className="text-gray-500 sm:text-sm">SA</span> */}
                    <img
                      src={
                        "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"
                      }
                      alt=""
                      className="block h-auto w-5 flex-shrink-0"
                    />
                  </div>
                  <input
                    id="phone"
                    name="phone"
                    type="phone"
                    value={newOject.phone}
                    onChange={(e) => handleFields(e)}
                    placeholder="9665xxxxxxxx"
                    className="rtl:text-left caret-inherit  p-12 rtl:pr-2 pt-2 block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-10 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                    aria-describedby="price-currency"
                  />
                </div>
                {statusError === 12 && handleError?.length > 0 && (
                  <span className="flex items-center font-medium  tracking-wide text-red-500 text-xs mt-1 ltr:ml-1 rtl:mr-1 ">
                    {handleError}
                  </span>
                )}
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  كلمة المرور
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={newOject.password}
                    onChange={(e) => handleFields(e)}
                    placeholder="********"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                {isError && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs ltr:ml-1 rtl:mr-1 mt-1">
                    لابد من كلمة المرور تكون أكثر من ٦ حروف أو أرقام
                  </span>
                )}
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="remember-me"
                    className="rtl:mr-2 ltr:ml-2 block text-sm text-gray-900"
                  >
                    ذكرني
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="/login"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    لديك حساب ؟
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={
                    newOject.password.length <= 5 ||
                    newOject.email.length === 0 ||
                    newOject.companyName.length === 0
                  }
                  className={classNames(
                    newOject.password.length <= 5 ||
                      newOject.email.length === 0 ||
                      newOject.companyName.length === 0
                      ? "bg-gray-400 hover:bg-gray-400"
                      : "focus:ring-indigo-600 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600",
                    "flex w-full border my-3  justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                  )}
                >
                  {isLoading ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 text-white animate-spin rtl:ml-3 ltr:mr-3"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    "تسجيل جديد"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
