import React, { useContext, useEffect, useState } from "react";
import { Disclosure, Transition, Dialog } from "@headlessui/react";

import {
  CheckIcon,
  MagnifyingGlassIcon,
  ChevronDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import axios from "axios";
import instance, { useNaemApi } from "../api/api";
import { Context } from "../context/ContextProvider";
import { useLocation } from "react-router-dom";
import {
  BookmarkIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Notifications from "../components/Notifications";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SearchDomain({ t, i18n }) {
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    setCompany,
    show,
    setShow,
  } = useContext(Context);
  const navigate = useNavigate();

  const { state } = useLocation();

  // useEffect(() => {
  //   console.log("state: ", state);
  // }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [people, setPeople] = useState([
    // { domainName: "Amira Alexander", purchasePrice: 22.22 },
  ]);
  const [newOject, setNewObject] = useState({
    url: "",
  });

  const { naemData, errorNaem, loadingNaem } = useNaemApi({
    method: "GET",
    url: `/companies/company-limits?companyUrl=naem&limit=20`,
    headers: {
      accept: "*/*",
    },
  });

  const handleFields = (e) => {
    setNewObject({ ...newOject, [e.target.name]: e.target.value });
  };

  const searchFunc = async () => {
    if (localStorage.getItem("Order") != null) {
      localStorage.removeItem("Order");
      localStorage.removeItem("CountCart");
      setCartItems([]);
    }

    setIsLoading(true);
    try {
      const d = await instance.post("/push/search", {
        search: newOject.url,
      });
      // console.log("data: ", d.data.results);
      setPeople(
        d.data.results.filter(
          (p) => p.purchasable === true && p.purchasePrice < 13,
        ),
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error: ", error.message);
    }
  };

  const addToCart = (person) => {
    // console.log("key: ", {
    //   ...naemData[0],
    //   unit: person.purchasePrice,
    //   name: person.domainName,
    // });
    // console.log("jj: ",person);
    if (loadingNaem === false) {
      handleNotification();
      handleAddProduct({
        ...naemData[0],
        unit: (person.purchasePrice * 3.75).toFixed(2),
        name: person.domainName,
        kind: 3,
      }) &
        (cartItmes?.length > 0) &&
        localStorage.setItem("Order", JSON.stringify(cartItmes));
    }
  };

  const questionAboutDomain = [
    {
      id: 1,
      quesiton: "تنبيهات مهمة",
      answer: "اتبع الخطوات التالية بالضغط على الرابط",
      icon: CheckIcon,
      link: "/domain",
      //   change: "122",
      //   changeType: "increase",
    },
  ];

  const handleNotification = () => {
    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  return (
    <div className="mx-5 mt-5 sm:container sm:mx-auto">
      <Notifications title={t("addedToCart")} />
      <div className="my-4">
        <div className=" container mx-auto my-5 flex flex-row">
          <button
            type="button"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="flex  h-10 w-10 items-center  justify-center rounded-md bg-indigo-500  text-4xl text-white drop-shadow-lg hover:bg-indigo-700 hover:drop-shadow-2xl"
            id="btn-back-to-top"
            onClick={() => {
              navigate("/domain");
              // scrollTop, clientHeight, scrollHeight
            }}
          >
            <ChevronRightIcon
              className="h-6  w-6 ltr:rotate-180 "
              aria-hidden="true"
            />
          </button>
          <p
            htmlFor="url"
            className="text-md mx-5 mt-2 block font-semibold leading-6 text-gray-900 sm:mt-1 sm:text-xl"
          >
            ابحث عن دومين
          </p>
        </div>

        <div className="my-5 rounded-2xl bg-white">
          {questionAboutDomain.map((item, key) => (
            <Disclosure as="div" className="mt-2" key={key}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-gray-100 px-2 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                    <div className="flex flex-row gap-1">
                      <BookmarkIcon
                        className={` h-5 w-5 origin-center font-bold text-gray-800`}
                      />
                      <span>{item.quesiton}</span>
                    </div>

                    <ChevronDownIcon
                      className={`${
                        open
                          ? "rotate-180 transition duration-300 ease-in"
                          : "rotate-0 transition duration-300 ease-in-out"
                        // open ? "animate-wiggle  rotate-180" : " rotate-0 animate-nonwiggle"
                      } h-5 w-5 origin-center font-bold text-gray-800`}
                    />
                  </Disclosure.Button>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      {/* setIsOpen */}
                      <div className="flex flex-col">
                        <p
                          htmlFor="url"
                          className="mx-5 mt-2 block  text-xs font-semibold leading-6 text-black sm:mt-1 sm:text-sm"
                        >
                          - احصل عليه مجاناً عند الاشتراك في الباقة المميزة .
                          <a href="#" className="text-blue-500 underline">
                            للاشتراك اضغط هنا
                          </a>{" "}
                        </p>
                        <p
                          htmlFor="url"
                          className="mx-5 mt-2 block  text-xs font-semibold leading-6 text-black sm:mt-1 sm:text-sm"
                        >
                          - يمكنك شراء دومين من خلالنا واستخدامه خارج خدماتنا.
                        </p>
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          ))}
        </div>
        <div>
          <div className="mt-2 flex flex-row gap-2">
            {i18n.dir() === "rtl" && (
              <button
                onClick={searchFunc}
                className="h-9 w-28 rounded-md bg-indigo-500 p-1 px-8 text-sm text-white hover:bg-indigo-700"
              >
                {isLoading ? (
                  <div className="flex ">
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline h-5 w-full  animate-spin text-white "
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                ) : (
                  "ابحث"
                )}
              </button>
            )}
            <div className="relative w-full rounded-md ">
              <input
                id="url"
                name="url"
                type="url"
                value={newOject.url}
                onChange={(e) => handleFields(e)}
                autoComplete="current-url"
                placeholder="domain.com"
                required
                className="block h-9 w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-3 text-sm text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 rtl:text-left sm:text-sm"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>

            {i18n.dir() === "ltr" && (
              <button className="h-9 w-32 rounded-md bg-indigo-500 p-1 px-8 text-sm text-white hover:bg-indigo-700">
                {isLoading ? (
                  <div className="flex ">
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline h-5 w-full animate-spin text-white "
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                ) : (
                  "Search"
                )}
              </button>
            )}
          </div>
        </div>
        <div className="my-5 mt-10 rounded-md bg-gray-300 py-0 text-sm">
          {people.length > 0 ? (
            people.map((person, key) => (
              <div
                className={classNames(
                  key === people.length - 1 ? "" : "divide-y divide-gray-400",
                  "flex flex-col ",
                )}
                key={key}
              >
                <div className="flex cursor-pointer justify-start px-2  py-2  text-gray-700 ">
                  <span className="m-1 mt-3.5 hidden h-2 w-2 rounded-full bg-green-600 sm:block"></span>

                  <div className="xs-text mt-2 flex-grow flex-row px-2 ">
                    <span>{"   " + person.domainName + "   "}</span>
                  </div>
                  <div className="ml-2 flex flex-row items-center  gap-2 divide-x divide-gray-400  rtl:divide-x-reverse">
                    <div className="text-xs tracking-wide text-gray-600   ">
                      التجديد:
                      {" " + (person.renewalPrice * 3.75).toFixed(2) + t("sar")}
                    </div>
                    <div
                      className={classNames(
                        state?.response?.active ? " line-through" : "",
                        "pr-2 text-xs tracking-wide  text-green-600",
                      )}
                      // className="text-xs text-green-600 tracking-wide  pr-2"
                    >
                      {(person.purchasePrice * 3.75).toFixed(2) + t("sar")}
                    </div>
                  </div>
                  {state?.response?.active ? (
                    <button
                      onClick={() => addToCart(person)}
                      className="flex flex-row rounded-md bg-green-500 p-2 text-white hover:bg-green-700"
                    >
                      <PlusIcon
                        className=" mt-0 h-5 w-5 ltr:mr-1 rtl:ml-1 "
                        aria-hidden="true"
                      />
                      مجاناً
                    </button>
                  ) : (
                    <button
                      onClick={() => addToCart(person)}
                      className="rounded-md bg-blue-500 p-2 text-white hover:bg-blue-700"
                    >
                      شراء
                    </button>
                  )}
                </div>
                <div className=" divide-red-600 "></div>
              </div>
            ))
          ) : (
            <div className="my-2 flex cursor-pointer justify-start rounded-md px-2 py-2 text-gray-700">
              <span className="m-2 h-2 w-2 rounded-full bg-red-400"></span>
              <div className="mt-0.5 flex-grow px-2 font-medium">
                {t("nothingWrittenInSearch")}
              </div>
              <div className="text-sm font-normal tracking-wide text-gray-500"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchDomain;
