import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import {
  CheckIcon,
  DocumentCheckIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import instance, { useAxios } from "../api/api";
import {
  ArrowPathIcon,
  DocumentIcon,
  MagnifyingGlassIcon,
  PhotoIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
// import { Listbox, Tab, Transition } from "@headlessui/react";
import axios from "axios";
import TableCards from "./TableCards";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// alertDigitalCard,
// setAlertDigitalCard,
export default function AlertDigitalCard({
  alertDigitalCard,
  setAlertDigitalCard,
  t,
  currentCards,
  setCurrentCards,
  isEdit,
  editCard,
  product_id,
}) {
  const cancelButtonRef = useRef(null);

  const { response, loading, setLoading, error } = useAxios({
    method: "GET",
    url: `/cards`,
    headers: {
      accept: "*/*",
    },
  });

  useEffect(() => {
    // console.log("eidited: ", isEdit, editCard);
    setCurrentCards(editCard);
    // if (isEdit) {
    //   console.log("herer ...");
    // }

    // else if (response && isEdit === false) setCurrentCards(response);
  }, [loading]);

  return (
    <Transition.Root show={alertDigitalCard} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setAlertDigitalCard(false);
          // console.log("response: " + JSON.stringify(currentCards));
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-3000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="my-20 flex min-h-full items-start justify-center p-3 text-center sm:items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="transform ease-out duration-500 transition"
              enterFrom="translate-y-0 opacity-100 sm:translate-x-0"
              enterTo="opacity-100 translate-y-2 translate-x-0"
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel className="relative mx-1 w-full transform overflow-hidden rounded-lg bg-white px-0 pb-4 pt-0 shadow-xl transition-all ltr:text-left rtl:text-right sm:mx-2 sm:my-8 sm:max-w-2xl  sm:p-6 lg:max-w-4xl">
                <div>
                  <div className="mt-0 sm:mt-0 ">
                    <div className="mb-4 mt-10 w-full ">
                      {loading && isEdit === false ? (
                        <div className="flex w-full items-center justify-center py-20">
                          <div className="flex items-center justify-center space-x-1 text-sm text-gray-700 rtl:space-x-reverse">
                            <svg
                              fill="none"
                              className="h-20 w-20 animate-spin"
                              viewBox="0 0 32 32"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                clipRule="evenodd"
                                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                                fill="currentColor"
                                fillRule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                      ) : (
                        <TableCards
                          cards={response}
                          setLoading={setLoading}
                          loading={loading}
                          currentCards={currentCards}
                          setCurrentCards={setCurrentCards}
                          isEdit={isEdit}
                          product_id={product_id}
                          t={t}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
