/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon as XMarkIconOutline,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowUturnRightIcon,
  CheckIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  XMarkIcon as XMarkIconMini,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Context } from "../context/ContextProvider";
import instance, { useAxios, useAxiosSeconde } from "../api/api";
import Notifications from "../components/Notifications";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
// import {  } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Cart({ t }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    company,
    user,
    setUser,
    show,
    setShow,
  } = useContext(Context);

  const handleNotification = () => {
    setShow(true);

    setTimeout(() => {
      setShow(false);
      // JSON.parse(localStorage.getItem("Order")).length === 0 &&
      //   navigate(`/${localStorage.getItem("CN")}`);
    }, 2000);
  };

  // const [select, setSelect] = useState(0);

  // const handleFields = (e,i) => {
  //   console.log("e: ", e.target.name == i , e.target.value);
  //   if (e.target.name == i) {
  //     setSelect(e.target.value);
  //   } else {
  //     console.log("jj");
  //   }
  //   // setHandleError("");
  //   // setNewObject({ ...newOject, [e.target.name]: e.target.value });
  // };
  const { data, loadingSeconde, errorSeconde } = useAxiosSeconde({
    method: "GET",
    url: `/companies/get-company-name/${localStorage.getItem("CN")}`,
    headers: {
      accept: "*/*",
    },
  });

  // useEffect(() => {
  //   console.log("order: ", JSON.parse(localStorage.getItem("Order"))[0]._id);
  // }, []);

  const cartFull =
    JSON.parse(localStorage.getItem("Order")).length > 0
      ? JSON.parse(localStorage.getItem("Order"))
          // .filter((p) => p.company_id === data?._id)
          .map((item) => item.qty)
          .reduce((previous, item) => previous + item, 0)
      : 0;

  const [isCopy, setIsCopy] = useState(false);

  const updateCart = async (_id) => {
    setIsCopy(false);
    // console.log("here dd .....", _id,data);
    const filter = JSON.parse(localStorage.getItem("Order")).filter(
      (order) => order._id !== _id && order.company_id === data?._id,
    );
    // console.log("filter: ", filter);
    try {
      await instance.post("/orders/update", {
        _id: localStorage.getItem("OrderId"),
        orders: filter,
      });
      localStorage.setItem("Order", JSON.stringify(filter));

      filter.length === 0
        ? localStorage.setItem("CountCart", 0)
        : localStorage.setItem("CountCart", cartFull);

      handleNotification();
    } catch (error) {
      console.log("error: ", error.message);
    }
  };

  const { response, loading, error } = useAxios({
    method: "POST",
    url: `/orders/update`,
    headers: {
      accept: "*/*",
    },
    data: {
      _id: localStorage.getItem("OrderId"),
      orders: JSON.parse(localStorage.getItem("Order")),
    },
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log(
  //       "jj: "
  //       // response[0]
  //       // .orders.some((o) => o._id === "644c3918d64ebf3a0cda5d51")
  //     );
  //   }, 2000);
  // }, []);

  const textCutFunc = (text) =>
    `${text
      .trim()
      .split(" ")
      .splice(0, text?.length > 25 ? 6 : 5)
      .join(" ")}  ${text?.length > 25 ? "..." : ""}`;

  const [phone, setPhone] = useState("");

  const updateClipboard = (newClip) => {
    setIsCopy(true);
    navigator.clipboard.writeText(newClip).then(
      () => {
        handleNotification();
        setPhone("");
      },
      () => {
        console.log("clipboard write failed");
      },
    );
  };

  const copyCartLink = async () => {
    console.log("kk", jwt_decode(localStorage.getItem("TokenNAEM")));
    // console.log(
    //   "link payment: ",
    //   `http://localhost:8081/checkout?id=${localStorage.getItem(
    //     "OrderId"
    //   )}&phone=${phone}&cid=${
    //     jwt_decode(localStorage.getItem("TokenNAEM")).company._id
    //   }`
    // );
    const { company } = jwt_decode(localStorage.getItem("TokenNAEM"));
    const orderId = localStorage.getItem("OrderId");
    console.log("company: ", company);

    try {
      await instance
        .post(`/carts/creat-web`, {
          email: company.email,
          displayName: "customer",
          phone: phone,
          displayCompany: company.companyName,
          orders: localStorage.getItem("OrderId"),
          company_id: company._id,
        })
        .then(({ data }) => {
          // console.log("create cart done", data);
          updateClipboard(
            `https://naem.store/checkout?companyUrl=${company.companyUrl}&id=${orderId}&phone=${phone}&cid=${company._id}`,
          );

          setTimeout(() => {
            navigate(`/${localStorage.getItem("CN")}`);
            localStorage.setItem("Order", JSON.stringify([]));
            localStorage.removeItem("OrderId");
          }, 2000);

          // localStorage.setItem("CountCart", 0);
          // setTimeout(() => {
          // }, 500);
        })
        .catch((e) => console.log("error: ", e.message));
      // .then(() => localStorage.setItem("OrderId", data._id));
    } catch (error) {
      console.log("error: ", error.message);
    }

    // updateClipboard(
    //   `https://naem.store/checkout?id=${localStorage.getItem(
    //     "OrderId"
    //   )}&phone=${phone}&cid=${
    //     jwt_decode(localStorage.getItem("TokenNAEM")).company._id
    //   }`
    // );
  };

  return (
    <div className="bg-white">
      {isCopy ? (
        <Notifications title={t("copyUrl")} />
      ) : (
        <Notifications title="تم الحذف من السلة!" />
      )}
      {loadingSeconde ? (
        <div className="flex w-full items-center justify-center py-20">
          <div className="flex items-center justify-center space-x-1 text-sm text-gray-700 rtl:space-x-reverse">
            <svg
              fill="none"
              className="h-20 w-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <main className="mx-auto max-w-2xl px-4 pb-24 pt-5 sm:px-6 lg:max-w-5xl lg:px-8">
          {JSON.parse(localStorage.getItem("Order")).length === 0 ? (
            <div className="flex flex-row justify-between rounded bg-gray-200 p-3 px-5">
              <h1 className="text-1xl mt-0.5 font-bold tracking-tight text-gray-900 sm:text-xl">
                لا توجد منتجات في السلة :(
              </h1>
              <button
                // href={`/${localStorage.getItem("CN")}`}
                onClick={() => {
                  // navigate(`/${localStorage.getItem("CN")}`);
                  navigate(`/${localStorage.getItem("CN")}`);
                  // setCartCount(0);
                }}
                className="flex flex-row rounded bg-gray-600 px-2 text-white "
              >
                <span className="text-1xl sm:text-1xl pb-1  pl-3 pr-3 font-bold tracking-tight sm:mt-0.5">
                  رجوع
                </span>
                <ArrowUturnRightIcon
                  className="mt-0.5 h-5 w-5 sm:mt-1.5"
                  aria-hidden="true"
                />
              </button>
            </div>
          ) : (
            <>
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                سلة المشتريات
              </h1>
              <div className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
                <section
                  aria-labelledby="cart-heading"
                  className="lg:col-span-7"
                >
                  <h2 id="cart-heading" className="sr-only">
                    Items in your shopping cart
                  </h2>

                  <ul className="divide-y divide-gray-200 border-b border-t border-gray-200">
                    {JSON.parse(localStorage.getItem("Order"))
                      // .filter((p) => p.company_id === data?._id)
                      .map((product, productIdx) => (
                        <li key={product._id} className="flex py-6 sm:py-10">
                          <div className="flex-shrink-0">
                            <img
                              src={product.image.split(",")[0]}
                              alt={"image0"}
                              className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                            />
                          </div>

                          <div className="flex flex-1 flex-col justify-between ltr:ml-4 rtl:mr-4 ltr:sm:ml-6 rtl:sm:mr-6">
                            <div className="relative ltr:pr-9 rtl:pl-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0 rtl:sm:pl-0">
                              <div>
                                <div className="flex justify-between">
                                  <h3 className="text-sm">
                                    <a
                                      href={product.href}
                                      className="font-medium text-gray-700 hover:text-gray-800"
                                    >
                                      {textCutFunc(product.name)}
                                    </a>
                                  </h3>
                                </div>
                                <div className="mt-1 flex text-sm">
                                  <p className="text-gray-500">
                                    {product.color}
                                  </p>
                                  {product.size ? (
                                    <p className="border-gray-200 text-gray-500 ltr:ml-4 ltr:border-l ltr:pl-4 rtl:mr-4 rtl:border-r rtl:pr-4">
                                      {product.size}
                                    </p>
                                  ) : null}
                                </div>
                                <p className="mt-1 text-sm font-medium text-gray-900">
                                  {product._id === "644c3918d64ebf3a0cda5d51"
                                    ? "مجاناً"
                                    : product.unit.toFixed(2) + " ر.س "}
                                </p>
                              </div>

                              <div className="mt-4 sm:mt-0 ltr:sm:pr-9 rtl:sm:pl-9">
                                <label
                                  htmlFor={`quantity-${productIdx}`}
                                  className="sr-only"
                                >
                                  Quantity, {product.qty}
                                </label>
                                <p className="text-gray-500">
                                  الكمية: {product.qty}
                                </p>
                                {/* <select
                                id={product.qty}
                                name={productIdx}
                                value={select}
                                onChange={(e) => handleFields(e, productIdx)}
                                // px-6
                                //   className=" max-w-full rounded-md border border-gray-300 py-1.5 ltr:text-left rtl:text-right text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                className="max-w-full px-6 rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                              >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                              </select> */}

                                {/* <div className="absolute right-0 top-0">
                          <button
                            type="button"
                            className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                          >
                            <span className="sr-only">Remove</span>
                            <XMarkIconMini
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                        </div> */}

                                <div className="absolute top-0 ltr:right-0 rtl:left-0">
                                  <button
                                    onClick={() => updateCart(product._id)}
                                    type="button"
                                    className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                                  >
                                    <span className="sr-only">Remove</span>
                                    <XMarkIconMini
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>

                            <p className="mt-4 flex space-x-2 text-sm text-gray-700 rtl:space-x-reverse">
                              {product.stock > 0 ? (
                                <CheckIcon
                                  className="h-5 w-5 flex-shrink-0 text-green-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <ClockIcon
                                  className="h-5 w-5 flex-shrink-0 text-gray-300"
                                  aria-hidden="true"
                                />
                              )}

                              <span>
                                {product.stock > 0 ? "متوفر" : `غير متوفر`}
                              </span>
                            </p>
                          </div>
                        </li>
                      ))}
                  </ul>
                </section>

                {/* Order summary */}
                <section
                  aria-labelledby="summary-heading"
                  className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
                >
                  <h2
                    id="summary-heading"
                    className="text-lg font-medium text-gray-900"
                  >
                    ملخص الطلب
                  </h2>

                  <dl className="mt-6 space-y-4">
                    {JSON.parse(localStorage.getItem("Order"))[0]._id !==
                    "6488add2feae75d6ff1801d7" ? (
                      <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                        <dt className="flex items-center text-sm text-gray-600">
                          <span> رسوم الخدمة </span>
                          <button
                            onClick={() => {}}
                            className="flex-shrink-0 text-gray-400 hover:text-gray-500 ltr:ml-2 rtl:mr-2"
                          >
                            <span className="sr-only">
                              Learn more about how shipping is calculated
                            </span>
                            <InformationCircleIcon
                              className="h-5 w-5 "
                              aria-hidden="true"
                            />
                          </button>
                        </dt>
                        <dd className="text-sm font-medium text-gray-900">
                          9.99 ر.س
                        </dd>
                      </div>
                    ) : null}
                    <div className="flex items-center justify-between">
                      <dt className="text-sm text-gray-600">المجموع</dt>
                      <dd className="text-sm font-medium text-gray-900">
                        {response?.orders.some(
                          (o) => o._id === "644c3918d64ebf3a0cda5d51",
                        )
                          ? (
                              9.99 +
                              totalPrice -
                              response.orders.find(
                                (o) => o._id === "644c3918d64ebf3a0cda5d51",
                              ).unit
                            ).toFixed(2) + " ر.س "
                          : totalPrice.toFixed(2) + " ر.س "}
                      </dd>
                    </div>

                    {/* <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                      <dt className="flex text-sm text-gray-600">
                        <span>الضريبة</span>
                        <a
                          href="#"
                          className="flex-shrink-0 text-gray-400 hover:text-gray-500 ltr:ml-2 rtl:mr-2"
                        >
                          <span className="sr-only">
                            Learn more about how tax is calculated
                          </span>

                        </a>
                      </dt>
                      <dd className="text-sm font-medium text-gray-900">
                        0.00 ر.س
                      </dd>
                    </div> */}
                    <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                      <dt className="text-base font-medium text-gray-900">
                        المجموع الكلي
                      </dt>
                      <dd className="text-base font-medium text-gray-900">
                        {response?.orders.some(
                          (o) => o._id === "644c3918d64ebf3a0cda5d51",
                        )
                          ? (
                              9.99 +
                              totalPrice -
                              response.orders.find(
                                (o) => o._id === "644c3918d64ebf3a0cda5d51",
                              ).unit
                            ).toFixed(2) + " ر.س "
                          : totalPrice.toFixed(2) + " ر.س "}
                      </dd>
                    </div>
                  </dl>

                  {response?.orders.every((o) => o.kind === 3) && (
                    <div className="mt-4  border-t-2 border-gray-200 pt-6">
                      <button
                        type="button"
                        className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                        onClick={() => navigate("/checkout", { shallow: true })}
                      >
                        {t("continuation")}
                      </button>
                    </div>
                  )}

                  {response?.orders.every((o) => o.kind !== 3) && (
                    <div>
                      <div className="mt-4  border-t-2 border-gray-200 pt-3 ">
                        <div className="my-2 text-sm text-indigo-600 ">
                          اكتب رقم جوال المشتري:
                        </div>
                        <input
                          id="phone"
                          name="phone"
                          type="text"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                          autoComplete="current-phone"
                          placeholder="966xxxxxxxxx"
                          required
                          className="w-full rounded-md border   border-gray-200 bg-white px-4 py-2 text-left  text-sm font-medium text-gray-900 placeholder-gray-400 shadow-sm hover:bg-gray-100 focus:border-gray-500 focus:bg-white focus:outline-none focus:ring-offset-gray-50  sm:text-sm"

                          // className="rtl:text-left block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-3 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm text-sm h-9"
                        />
                      </div>

                      <div className="mt-3">
                        <button
                          type="button"
                          disabled={phone.length < 11 || phone.length > 12}
                          className={classNames(
                            phone.length > 11 && phone.length < 13
                              ? "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                              : "bg-gray-500 hover:bg-gray-700  ",
                            "w-full rounded-md border border-transparent  px-4 py-2 text-center font-medium text-white shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2 focus:ring-offset-gray-50",
                          )}
                          onClick={copyCartLink}
                        >
                          {t("copyCartLink")}
                        </button>
                      </div>
                    </div>
                  )}
                </section>
              </div>
            </>
          )}
        </main>
      )}
    </div>
  );
}
