import { useContext, useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/20/solid";
import { useNaemApi } from "../api/api";
import { Context } from "../context/ContextProvider";
import AlerteChangePackage from "../components/AlertChangePackage";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const frequencies = [
  //   {
  //     value: "monthly",
  //     label: `${t("monthlyC")}`,
  //     priceSuffix: `/${t("month")}`,
  //   },
  //   {
  //     value: "annually",
  //     label: `${t("annuallyC")}`,
  //     priceSuffix: `/${t("year")}`,
  //   },
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually", priceSuffix: "/year" },
];

export default function Pricing({ t }) {
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    setCompany,
    show,
    setShow,
  } = useContext(Context);

  const [selectProduct, setSelectProduct] = useState({});


  const tiers = [
    {
      name: t("pricingName1"),
      id: "tier-basic",
      href: "#",
      price: { monthly: "0", annually: "0" },
      description: t("pricingDescription1"),
      features: [
        t("pricingFeature11"),
        t("pricingFeature12"),
        t("pricingFeature13"),
        t("pricingFeature14"),
      ],
    },
    {
      name: t("pricingName2"),
      id: "tier-essential",
      href: "#",
      price: { monthly: "49.99", annually: "499.99" },
      description: t("pricingDescription2"),
      features: [
        t("pricingFeature21"),
        t("pricingFeature22"),
        t("pricingFeature23"),
        t("pricingFeature24"),
      ],
    },
    {
      name: t("pricingName3"),
      id: "tier-growth",
      href: "#",
      price: {
        monthly: `${t("notAvailableMonthly")}`,
        // monthly: `999.99 ${t("sar")}`,
        annually: "999.99",
      },
      description: t("pricingDescription3"),
      features: [
        t("pricingFeature35"),
        t("pricingFeature36"),
        t("pricingFeature31"),
        t("pricingFeature32"),
        t("pricingFeature33"),
        t("pricingFeature34"),
      ],
    },
    // {
    //   name: "Basic",
    //   id: "tier-basic",
    //   href: "#",
    //   price: { monthly: "SAR 0", annually: "SAR 0" },
    //   description: "Everything necessary to get started.",
    //   features: [
    //     "5 products",
    //     "15% fee for each transaction",
    //     "Advanced analytics",
    //     "48-hour support response time",
    //   ],
    // },
    // {
    //   name: "Essential",
    //   id: "tier-essential",
    //   href: "#",
    //   price: { monthly: "SAR "49.99"", annually: "SAR 499.99" },
    //   description:
    //     "Everything in Basic, plus essential tools for growing your business.",
    //   features: [
    //     "Unlimited products",
    //     "0% fee for each transaction",
    //     "Advanced analytics",
    //     "24-hour support response time",
    //   ],
    // },
    // {
    //   name: "Growth",
    //   id: "tier-growth",
    //   href: "#",
    //   price: { monthly: "غير متاحة شهرياً", annually: "SAR 999.99" },
    //   description:
    //     "Everything in Essential, plus collaboration tools and deeper insights.",
    //   features: [
    //     "Unlimited products",
    //     "0% fee for each transaction",
    //     "Advanced analytics",
    //     "1-hour, dedicated support response time",
    //     "A special app for iPhone and Android",
    //   ],
    // },
  ];
  const [frequency, setFrequency] = useState(frequencies[0]);

  const { naemData, errorNaem, loadingNaem } = useNaemApi({
    method: "GET",
    url: `/companies/company-limits?companyUrl=naem&limit=20`,
    headers: {
      accept: "*/*",
    },
  });

  const addToCart = (tier) => {
    console.log("key: ", {
      ...naemData[1],
      unit:
        frequency.value === "monthly"
          ? Number(tier.price.monthly)
          : Number(tier.price.annually),
      // unit: tier.purchasePrice,
      name: tier.name,
    });

    console.log("jj: ", cartItmes.length, localStorage.getItem("CountCart"));
    if (cartItmes.length == 1) {
      setOpenAlerteChange(true);
      console.log("1");
    } else if (loadingNaem === false && tier.id !== "tier-basic") {
      console.log("2");
      setSelectProduct({
        ...naemData[1],
        unit:
          frequency.value === "monthly"
            ? Number(tier.price.monthly)
            : Number(tier.price.annually),
        name: tier.name,
      });

      handleAddProduct({
        ...naemData[1],
        unit:
          frequency.value === "monthly"
            ? Number(tier.price.monthly)
            : Number(tier.price.annually),
        name: tier.name,
      }) &
        (cartItmes?.length > 0) &&
        localStorage.setItem("Order", JSON.stringify(cartItmes));
    } else {
      console.log("jjj");
    }
  };

  useEffect(() => {
    console.log(
      "jj: ",
      tiers[2].price.monthly === "غير متاحة شهرياً",
      tiers[2].id === "tier-growth"
    );
  }, [frequency]);

  const [openAlerteChange, setOpenAlerteChange] = useState(false);
  const [isSendAlerteMore, setIsSendAlerteMore] = useState(false);

  return (
    <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <AlerteChangePackage
        openAlerteChange={openAlerteChange}
        setOpenAlerteChange={setOpenAlerteChange}
        isSendAlerteMore={isSendAlerteMore}
        setIsSendAlerteMore={setIsSendAlerteMore}
        t={t}
        handleAddProduct={handleAddProduct}
        cartItmes={cartItmes}
        setCartItems={setCartItems}
        handleRemoveProduct={handleRemoveProduct}
        selectProduct={selectProduct}
      />
      <div
        className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
        aria-hidden="true"
      >
        <div
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            {t("pricing")}
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {t("pricingTitle")}
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          {t("pricingDescription")}
        </p>

        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">
              Payment frequency
            </RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? "bg-indigo-600 text-white" : "text-gray-500",
                    "cursor-pointer rounded-full px-2.5 py-1"
                  )
                }
              >
                <span>
                  {option.value === "monthly"
                    ? `${t("monthlyC")}`
                    : `${t("annuallyC")}`}
                </span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "ring-2 ring-indigo-600"
                  : "ring-1 ring-gray-200",
                "rounded-3xl p-8 xl:p-10"
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? "text-indigo-600" : "text-gray-900",
                    "text-lg font-semibold leading-8"
                  )}
                >
                  {tier.name}
                </h3>
                {tier.mostPopular ? (
                  <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                    Most popular
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span
                  className={classNames(
                    // tier.price[frequency.value].startsWith("غير")
                    (tier.price?.monthly?.includes("غير") &&
                      frequency.value === "monthly") ||
                      (tier.price?.monthly?.includes("not") &&
                        frequency.value === "monthly")
                      ? "text-xl text-red-700"
                      : "text-2xl text-gray-900",
                    " font-bold tracking-tight  "
                  )}
                  // className="text-4xl font-bold tracking-tight text-gray-900"
                >
                  {(tier.price?.monthly?.includes("غير") &&
                    frequency.value === "monthly") ||
                  (tier.price?.monthly?.includes("not") &&
                    frequency.value === "monthly")
                    ? `${t("notAvailableMonthly")}`
                    : tier.price[frequency.value] + `${t("sar")}`}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-600">
                  {
                    //   tier.price[frequency.value].startsWith("غير")
                    (tier.price?.monthly?.includes("غير") &&
                      frequency.value === "monthly") ||
                    (tier.price?.monthly?.includes("not") &&
                      frequency.value === "monthly")
                      ? ""
                      : frequency.value === "monthly"
                      ? `/${t("month")}`
                      : `/${t("year")}`
                  }
                </span>
              </p>
              {(tier.price?.monthly?.includes("غير") &&
                tiers[2].id === "tier-growth" &&
                frequency.value === "monthly") ||
              (tier.price?.monthly?.includes("not") &&
                tiers[2].id === "tier-growth" &&
                frequency.value === "monthly") ? (
                ""
              ) : (
                <button
                  // href={tier.href}
                  onClick={() => addToCart(tier)}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.id !== "tier-basic"
                      ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                      : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                    "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  )}
                >
                  {tier.id === "tier-basic"
                    ? t("currentPackage")
                    : t("buylPlan")}
                </button>
              )}
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
