import React, { useEffect, useState, useContext, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import instance from "../api/api";
import { Context } from "../context/ContextProvider";
import { Dialog, Transition } from "@headlessui/react";
import { QrCodeIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function VerifyOTP({ i18n }) {
  let navigate = useNavigate();
  const { code, phone } = useParams();
  const { state } = useLocation();

  const [open, setOpen] = useState(true);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [reloadId, setReloadId] = useState(null);
  const [otpResend, setOtpResend] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [newOject, setNewObject] = useState({
    otp: "",
  });

  const handleFields = (e) => {
    setNewObject({ ...newOject, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = async () => {
    setError("");
    const { data } = await instance.post(`/companies/send-verification-otp`, {
      number: phone,
    });
    // console.log("jlj: ", newRegister.data.isRegister, unmounted);
    if (data.message === "Success") {
      setLoading(false);
      setReloadId(data.id);
      // setOtpResend(data.code);
      // console.log("otp: ", data);

      // navigate(`/verify-otp/${data.id}&${phone}`);
    } else {
      setLoading(false);
      setError("الكود خطأ حاول مرة اخرى");
    }
    setMinutes(1);
    setSeconds(30);
  };

  const verifyOTPSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const code = newOject.otp;

    try {
      // console.log("type: ",typeof otp, "type2: ",typeof otpResend.toString());
      // if (otp === state.otp.toString() || otp === otpResend.toString()) {
      //   // await instance.post(`/companies/verify-otp`, {
      //   //   code: reloadId != null ? reloadId : code,
      //   //   otp,
      //   // });
      //   navigate(`/merchant-id/${phone}`);
      //   setLoading(false);
      //   setNewObject({ otp: "" });
      // } else {
      //   setError("الكود خطأ حاول مرة اخرى");
      //   setLoading(false);
      //   setNewObject({ otp: "" });
      // }
      if (reloadId == null) {
        await instance
          .post(`/companies/verify-otp`, {
            id: state.otp.id,
            code: code,
          })
          .then(({data}) => {
            if (data.message === "Success") {
              navigate(`/merchant-id/${phone}`, { replace: true });
              setLoading(false);
              setNewObject({ otp: "" });
            } else {
              setError("الكود خطأ حاول مرة اخرى");
            }
          });
      } else {
        await instance
          .post(`/companies/verify-otp`, {
            id: reloadId,
            code: code,
          })
          .then(({data}) => {
            if (data.message === "Success") {
              navigate(`/merchant-id/${phone}`, { replace: true });
              setLoading(false);
              setNewObject({ otp: "" });
            } else {
              setError("الكود خطأ حاول مرة اخرى");
            }
          });
      }
    } catch (error) {
      // console.log("jjj", error.message);
      setError("الكود خطأ حاول مرة اخرى");
      setNewObject({ otp: "" });
      setLoading(false);
      setError(error.message);
      console.clear();
    }
  };

  return (
    <div className="h-full ">
      <Transition.Root show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 container mx-auto">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full  sm:p-6">
                  <form
                    className=""
                    action="#"
                    method="POST"
                    onSubmit={verifyOTPSend}
                  >
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 mb-5">
                        <QrCodeIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          كود التفعيل
                        </Dialog.Title>
                        {/* <div className="mt-2">
                      <p className="text-sm text-gray-500">
                      الرجاء ادخال رمز التحقق المرسل لجوالك

                      </p>
                    </div> */}
                      </div>
                    </div>
                    <div>
                      <div className="relative mt-2 flex items-center">
                        <input
                          type="number"
                          id="search"
                          label="رمز التحقق"
                          name="otp"
                          value={newOject.otp}
                          onChange={(e) => handleFields(e)}
                          placeholder="الرجاء ادخال رمز التحقق المرسل لجوالك"
                          // autoComplete="otp"
                          className="block w-full rounded-md border py-1.5 ltr:pr-14 rtl:pl-14 rtl:pr-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
                        />

                        <div className="absolute inset-y-0 ltr:right-0 rtl:left-0  flex py-1.5 ltr:pr-1.5 rtl:pl-1.5">
                          <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
                            {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </kbd>
                        </div>
                      </div>
                      {newOject.otp.length === 0 && error.length === 0 && (
                        <span className="flex items-center font-medium  tracking-wide text-red-500 text-xs mt-1 ltr:ml-1 rtl:mr-1 ">
                          لم يتم كتابة الكود
                        </span>
                      )}
                      {error.length > 0 && (
                        <span className="flex items-center font-medium  tracking-wide text-red-500 text-xs mt-1 ltr:ml-1 rtl:mr-1 ">
                          {error}
                        </span>
                      )}
                    </div>
                    <div className="mt-2 sm:mt-3">
                      <button
                        type="button"
                        className={classNames(
                          (seconds !== 0 && minutes === 0) ||
                            (seconds === 0 && minutes === 1) ||
                            (seconds !== 0 && minutes === 1)
                            ? " bg-gray-200 text-gray-400 "
                            : "bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600",
                          "inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                        )}
                        onClick={resendOTP}
                        disabled={
                          (seconds !== 0 && minutes === 0) ||
                          (seconds === 0 && minutes === 1) ||
                          (seconds !== 0 && minutes === 1)
                        }
                      >
                        إعادة إرسال رمز التحقق
                      </button>
                    </div>
                    <div className="mt-2 sm:mt-3">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => setOpen(false)}
                      >
                        إرسال
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default VerifyOTP;
