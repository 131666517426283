/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect, useContext, useRef } from "react";
import jwt_decode from "jwt-decode";
import { Disclosure } from "@headlessui/react";
import {
  LockClosedIcon,
  ShieldCheckIcon,
  CreditCardIcon,
} from "@heroicons/react/20/solid";
import { Context } from "../context/ContextProvider";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// import parse from "html-react-parser";

import instance, { StringToHtml, useAxios, useAxiosSeconde } from "../api/api";
import { ArrowUturnRightIcon } from "@heroicons/react/24/outline";
import Test from "../components/Test";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import Notifications from "../components/Notifications";
import Banner from "../components/Banner";
import i18n from "../i18n/i18n";
import MerchantAuth from "../components/MerchantAuth";

const subtotal = "$210.00";
const discount = { code: "CHEAPSKATE", amount: "$24.00" };
const taxes = "$23.68";
const shipping = "$22.00";
const total = "$341.68";
const products = [
  {
    id: 1,
    name: "Micro Backpack",
    href: "#",
    price: "$70.00",
    color: "Moss",
    size: "5L",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/checkout-page-04-product-01.jpg",
    imageAlt:
      "Moss green canvas compact backpack with double top zipper, zipper front pouch, and matching carry handle and backpack straps.",
  },
  // More products...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

var isSafari = (navigator.vendor.match(/apple/i) || "").length > 0;

export default function Checkout({ t }) {
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    totalProfit,
    setCategories,
    company,
    show,
    setShow,
    // setCartCount,
  } = useContext(Context);
  var decoded = jwt_decode(localStorage.getItem("TokenNAEM"));
  const isFree =
    JSON.parse(localStorage.getItem("Order"))[0]._id ===
    "644c3918d64ebf3a0cda5d51";

  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoadingApple, setIsLoadingApple] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTabby, setIsLoadingTabby] = useState(false);

  const handleNotification = () => {
    setShow(true);
    const timing = state != null ? 3000 : 500;

    setTimeout(() => {
      setShow(false);
    }, timing);
  };

  useEffect(() => {
    state != null && handleNotification();
  }, []);

  // useEffect(() => {
  //   async function checkOrder() {
  //     try {
  //       await fetch(
  //         "https://fatora-sa.herokuapp.com/api/v1/push/fetchClickPay",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-type": "application/json",
  //           },
  //         }
  //       )
  //         .then((response) => response.json())
  //         .then((d) => console.log("data jjj: ", d))
  //         .catch((error) => console.log("error: ", error.message));
  //     } catch (error) {
  //       console.log("error: ", error.message);
  //     }
  //   }

  //   checkOrder();
  // }, []);

  // const [isLoading, setIsLoading] = useState(true);

  // const jj = [
  //   {
  //     colors: [],
  //     _id: "644c3918d64ebf3a0cda5d51",
  //     name: "alsobhi.com",
  //     key: 1,
  //     marker: "دومين",
  //     unit: "41.21",
  //     before_vat: 99.99,
  //     after_vat: 99.99,
  //     qty: 1,
  //     category: "دومين",
  //     description: "اشتر دومين بكل سهولة وابدأ تجارتك الإلكترونية بكل موثوقية",
  //     basicPrice: 99.99,
  //     category_id: 1,
  //     stock: 1000000,
  //     image:
  //       "https://fatora-sa.herokuapp.com/api/v1/products/1682716796132-fatora-cashier-buy-domain-by-naem.me.jpg",
  //     company_id: "644c35487639c982e73de02e",
  //     __v: 0,
  //     created_at: "2023-04-28T21:22:32.331Z",
  //     updatedAt: "2023-04-28T21:22:32.331Z",
  //     sizes: [],
  //   },
  // ];

  const { data, loadingSeconde, errorSeconde } = useAxiosSeconde({
    method: "GET",
    url: `/companies/get-company-name/naem`,
    headers: {
      accept: "*/*",
    },
  });

  const cartFull =
    JSON.parse(localStorage.getItem("Order")).length > 0
      ? JSON.parse(localStorage.getItem("Order"))
          // .filter((p) => p.company_id === data?._id)
          .map((item) => item.qty)
          .reduce((previous, item) => previous + item, 0)
      : 0;

  const textCutFunc = (text) =>
    `${text
      .trim()
      .split(" ")
      .splice(0, text?.length > 25 ? 6 : 5)
      .join(" ")}  ${text?.length > 25 ? "..." : ""}`;

  // useEffect(() => {
  //   console.log(
  //     "token: "
  //     // jwt_decode(localStorage.getItem("Token")).user.address
  //   );
  // }, []);

  const updateCart = async (_id) => {
    const filter = JSON.parse(localStorage.getItem("Order")).filter(
      (order) => order._id !== _id && order.company_id === data?._id,
    );
    try {
      await instance.post("/orders/update", {
        _id: localStorage.getItem("OrderId"),
        orders: filter,
      });
      localStorage.setItem("Order", JSON.stringify(filter));
      filter.length === 0
        ? localStorage.setItem("CountCart", 0)
        : localStorage.setItem("CountCart", cartFull);
      setTimeout(() => {
        // navigate("/checkout")
      }, 700);
      handleNotification();
    } catch (error) {
      console.log("error: ", error.message);
    }
  };

  // const { response, loading, error } = useAxios({
  //   method: "POST",
  //   url: `/orders/update`,
  //   headers: {
  //     accept: "*/*",
  //   },
  //   data: {
  //     _id: localStorage.getItem("OrderId"),
  //     orders: JSON.parse(localStorage.getItem("Order")),
  //   },
  // });

  const oneFetch = useRef(true);
  useEffect(() => {
    if (oneFetch.current || loadingSeconde === false) {
      // console.log("back from check payment ", state);
      state != null && handleNotification();
      oneFetch.current = false;
    }
  }, [loadingSeconde]);

  const [itemNo, setItemNo] = useState(Math.floor(Math.random() * 1000000000));

  const createInvoice = async (orderId, ChoosePay, Price, Profit) => {
    // ChoosePay === "CARD" ? setIsLoading(true) : setIsLoadingApple(true);
    try {
      const newInvoic = await instance.post(
        `/invoices/create-casheir-invoice`,
        {
          itemNo: orderId, // orderId from noonpayment
          time: new Date()
            .toTimeString()
            .replace(/^[^:]*([0-2]\d:[0-5]\d).*$/, "$1"),
          date: new Date().toISOString().split("T")[0],
          title:
            localStorage.getItem("CN") !== null &&
            localStorage.getItem("CN").toUpperCase(),
          // user_id: jwt_decode(localStorage.getItem("TokenNAEM")).user._id,
          merchant_id: jwt_decode(localStorage.getItem("TokenNAEM")).company,
          orders: localStorage.getItem("OrderId"),
          company_id: data._id,
          token_id: {
            _id: "638a8cc11dbde0961bb169b9",
            type: "ios",
            data: "27b6fbf996b28fde2599dcbe09a2d532da8dfaa2a14cdee96a13249bc6930325",
            app: "cashier",
            __v: 0,
          },
          paymentType: ChoosePay,
          price: Price,
          profit: Profit,
          isShipped: false,
          isDelivered: false,
          orderId: orderId, // orderId from noonpayment
        },
      );
      if (newInvoic) {
        // const data = await newInvoic.data;
        // console.log("data: ", data);
        setIsLoadingApple(false);
        navigate(`/order/${orderId}`);
        // setTimeout(() => {
        // }, 2000);
      } else {
        console.log("error");
        setIsLoadingApple(false);
      }
    } catch (error) {
      setIsLoadingApple(false);
      console.error("error: ", error.message);
    }
  };

  const createInvoiceIsPaid = async (
    tran_ref,
    redirect_url,
    ChoosePay,
    Price,
    Profit,
  ) => {
    // ChoosePay === "CARD" ? setIsLoading(true) : setIsLoadingApple(true);
    // const data_test = {
    //   itemNo: itemNo, // orderId from noonpayment
    //   time: new Date()
    //     .toTimeString()
    //     .replace(/^[^:]*([0-2]\d:[0-5]\d).*$/, "$1"),
    //   date: new Date().toISOString().split("T")[0],
    //   title:
    //     localStorage.getItem("CN") !== null &&
    //     localStorage.getItem("CN").toUpperCase(),
    //   // user_id: jwt_decode(localStorage.getItem("TokenNAEM")).user._id,
    //   merchant_id: jwt_decode(localStorage.getItem("TokenNAEM")).company,
    //   orders: localStorage.getItem("OrderId"),
    //   company_id: data._id,
    //   token_id: {
    //     _id: "638a8cc11dbde0961bb169b9",
    //     type: "ios",
    //     data: "27b6fbf996b28fde2599dcbe09a2d532da8dfaa2a14cdee96a13249bc6930325",
    //     app: "cashier",
    //     __v: 0,
    //   },
    //   paymentType: ChoosePay,
    //   price: Price,
    //   profit: Profit,
    //   isShipped: false,
    //   isDelivered: false,
    //   orderId: 123456789,
    //   tran_ref: tran_ref,
    //   isPaid: ChoosePay === "CARD" ? false : true,
    //   // merchant_id: "648884fcc4fc2a065f7893a2",
    // };
    // console.log("data: ", data_test);
    try {
      await instance
        .post(`/invoices/create-casheir-invoice-is-paid`, {
          itemNo: itemNo, // orderId from noonpayment
          time: new Date()
            .toTimeString()
            .replace(/^[^:]*([0-2]\d:[0-5]\d).*$/, "$1"),
          date: new Date().toISOString().split("T")[0],
          title:
            localStorage.getItem("CN") !== null &&
            localStorage.getItem("CN").toUpperCase(),
          // user_id: jwt_decode(localStorage.getItem("TokenNAEM")).user._id,
          merchant_id: jwt_decode(localStorage.getItem("TokenNAEM")).company,
          orders: localStorage.getItem("OrderId"),
          company_id: data._id,
          token_id: {
            _id: "638a8cc11dbde0961bb169b9",
            type: "ios",
            data: "27b6fbf996b28fde2599dcbe09a2d532da8dfaa2a14cdee96a13249bc6930325",
            app: "cashier",
            __v: 0,
          },
          paymentType: ChoosePay,
          price: Price,
          profit: Profit,
          isShipped: false,
          isDelivered: false,
          orderId: 123456789,
          tran_ref: tran_ref,
          isPaid: ChoosePay === "CARD" ? false : true,
          // merchant_id: "648884fcc4fc2a065f7893a2",
        })
        .then(() => {
          if (ChoosePay === "CARD") {
            window.location.replace(redirect_url);
          } else {
            navigate(`/check-payment-card/${redirect_url}/${tran_ref}`);
          }
          // setIsLoading(false);
        });
    } catch (error) {
      // setIsLoadingApple(false);
      setIsLoading(false);
      console.error("error: ", error.message);
    }
  };

  const paymentClickPayCard = async (Price) => {
    setIsLoading(true);
    try {
      const requestPayment = await instance.post(`/push/click-pay-card`, {
        orderId: localStorage.getItem("OrderId"),
        amount: Price,
        title:
          localStorage.getItem("CN") !== null &&
          localStorage.getItem("CN").toUpperCase(),
        displayName: decoded.company.companyName,
        email: decoded.company.email,
        address: "batha",
        city: "makkah",
        country: "SA",
      });
      // console.log("data: ", requestPayment.data);
      createInvoiceIsPaid(
        requestPayment.data.tran_ref,
        requestPayment.data.redirect_url,
        "CARD",
        isFree ? 9.99 : 9.99 + totalPrice?.toFixed(2),
        isFree ? 9.99 : 9.99 + totalProfit?.toFixed(2),
      );
    } catch (error) {
      // setIsLoadingApple(false);
      setIsLoading(false);
      console.error("error: ", error.message);
    }
  };

  const [isCard, setIsCard] = useState(false);
  const [dataSrc, setDataSrc] = useState("");
  const [dataLink, setDataLink] = useState("");

  const paymentTabby = async () => {
    setIsLoadingTabby(true);
    // console.log(
    //   "(9.0 + totalPrice)?.toFixed(2): ",
    //   (9.0 + totalPrice)?.toFixed(2),
    // );

    const company = jwt_decode(localStorage.getItem("TokenNAEM")).company;
    try {
      const checkpayment = await instance.post("/push/check-payment", {
        amount: isFree ? 9.99 : 9.99 + totalPrice,
        // amount: (9.0 + totalPrice)?.toFixed(2),
        companyName: localStorage.getItem("CN"),
        items: JSON.parse(localStorage.getItem("Order")).map((order) => {
          console.log("order: " + JSON.stringify(order));
          return {
            name: order.name,
            quantity: order.qty,
            unitPrice: order.unit,
            code: "default",
            nvp: {
              productSku: order._id,
            },
          };
        }),
        billing: {
          address: {
            street: company.address,
            city: company.city,
            stateProvince:
              company.zipCode !== undefined ? company.zipCode : "T",
            postalCode:
              company.zipCode !== undefined ? company.zipCode : "123456",
            country: "SA",
          },
          contact: {
            firstName: company.companyName,
            lastName: company.companyName,
            phone: company.phone,
            email: company.email,
          },
        },
        lang: i18n.dir() === "rtl" ? "ar" : "en",
      });

      const data = await checkpayment.data;
      console.log("data: ", data);
      if (data.resultCode === 0) {
        localStorage.setItem(
          "Profit",
          Number(isFree ? 9.99 : 9.99 + totalProfit).toFixed(2),
        );
        window.location.replace(data.result.checkoutData.postUrl);
        // console.log("data: ", data);
        // setDataSrc(data.result.checkoutData.jsUrl);
        // setDataLink(data.result.configuration.returnUrl);
        // setIsLoadingTabby(false);
        // setTimeout(() => {
        //   setIsCard(true);
        // }, 1500);
        // https://checkout.tabby.ai/form?sessionId=6dcdc1e6-de8c-453f-8bd1-8cd7105f641e&apiKey=pk_test_a5cc9ffb-2505-4bb2-8aed-065bc22c500a&product=installments&merchantCode=sa
      }
    } catch (error) {
      setIsLoadingTabby(false);

      console.log("error: ", error.message);
    }
  };

  const fetchPaymentCardPageCheckOut = async () => {
    paymentClickPayCard((isFree ? 9.99 : totalPrice + 9.99).toFixed(2));
  };

  // const fetchPaymentCardPageCheckOut = async () => {
  //   setIsLoading(true);
  //   try {
  //     const req = await instance.get(
  //       `/push/fetchPaymentCard/${(9.000 + totalPrice)?.toFixed(2)}`
  //     );
  //     setDataSrc(req.data.result.checkoutData.jsUrl);
  //     setDataLink(req.data.result.configuration.returnUrl);
  //     console.log("link: ", req.data.result.configuration.returnUrl);
  //     console.log("status: ", req);

  //     if (req.status === 200) {
  //       setTimeout(() => {
  //         setIsCard(true);
  //       }, 1500);
  //     }
  //   } catch (error) {
  //     console.log("error: ", error.message);
  //     setIsLoading(false);
  //   }
  // };

  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const orderId = useRef(null);

  const fetchPayment = async (token, response) => {
    // console.log("id type: ", typeof orderId.current);
    const idd = Number(orderId.current);
    // console.log("id type: ", typeof idd);

    // const { version, data, signature, header } = token.paymentData;
    // const { ephemeralPublicKey, publicKeyHash, transactionId } = header;

    fetch(
      "https://fatora-sa.herokuapp.com/api/v1/push/fetchNoonPaymentProcess",
      {
        method: "POST",
        body: JSON.stringify({
          apiOperation: "PROCESS_AUTHENTICATION",
          order: {
            id: idd,
          },
          paymentData: {
            type: "ApplePay",
            data: {
              paymentInfo: JSON.stringify({ token: token }),
            },
          },
        }),
        headers: {
          "Content-type": "application/json",
        },
      },
    )
      .then((response) => response.json())
      // .then((data) => console.log("data noon: ", data))
      .then(async () => {
        // setIsLoadingApple(false);
        // console.log("success");
        const status = "success";
        await response.complete(status);
        createInvoice(
          idd,
          "APPLE PAY",
          isFree ? 9.99 : totalPrice?.toFixed(2) + 9.99,
          isFree ? 9.99 : totalProfit?.toFixed(2) + 9.99,
        );
      })
      .catch(() => (error) => {
        setIsLoadingApple(false);
        console.log("error dd: ", error.message);
      });
  };

  const [isLoadingAppleClickPay, setIsLoadingAppleClickPay] = useState(false);

  const paymentClickPayByApple = (token, response, Price) => {
    // console.log("data res: ", token);
    // console.log("data res: ", response);

    fetch("https://fatora-sa.herokuapp.com/api/v1/push/click-pay-apple", {
      method: "POST",
      body: JSON.stringify({
        token,
        orderId: localStorage.getItem("OrderId"),
        amount: Price,
        title:
          localStorage.getItem("CN") !== null &&
          localStorage.getItem("CN").toUpperCase(),
        companyName: decoded.company.companyName,
        email: decoded.company.email,
        address: "batha",
        city: "makkah",
        country: "SA",
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        // console.log("data id: ", data);

        if (data.payment_result.response_status === "A") {
          createInvoiceIsPaid(
            data.tran_ref,
            data.payment_result.response_status,
            "ApplePay",
            isFree ? 9.99 : 9.99 + totalPrice?.toFixed(2),
            isFree ? 9.99 : 9.99 + totalProfit?.toFixed(2),
          );
          const status = "success";
          await response.complete(status);
          setIsLoadingAppleClickPay(false);
        } else {
          const status = "fail";
          await response.complete(status);
          setIsLoadingAppleClickPay(false);
          navigate(
            `/check-payment-card/${data.payment_result.response_status}/${data.tran_ref}`,
          );
        }
      })
      .catch((err) => {
        setIsLoadingAppleClickPay(false);
        console.log("error: ", err);
      });

    setIsLoadingAppleClickPay(false);
    return;
  };

  async function onApplePayButtonClickPay() {
    setIsLoadingAppleClickPay(true);
    // Consider falling back to Apple Pay JS if Payment Request is not available.
    if (!PaymentRequest) {
      return;
    }

    try {
      // Define PaymentMethodData
      const paymentMethodData = [
        {
          supportedMethods: "https://apple.com/apple-pay",
          data: {
            version: 3,
            merchantIdentifier: "merchant.io.naem",
            merchantCapabilities: [
              "supports3DS",
              "supportsCredit",
              "supportsDebit",
            ],
            supportedNetworks: [
              "amex",
              "discover",
              "masterCard",
              "visa",
              "mada",
            ],
            countryCode: "SA",
          },
        },
      ];
      // Define PaymentDetails
      const paymentDetails = {
        total: {
          label: "NAEM",
          amount: {
            value: isFree ? 9.99 : (totalPrice + 9.99).toFixed(2),
            currency: "SAR",
          },
        },
      };
      // Define PaymentOptions
      const paymentOptions = {
        requestPayerName: false,
        requestBillingAddress: false,
        requestPayerEmail: false,
        requestPayerPhone: false,
        requestShipping: true,
        shippingType: "shipping",
      };

      // Create PaymentRequest
      const request = new PaymentRequest(
        paymentMethodData,
        paymentDetails,
        paymentOptions,
      );

      request.onmerchantvalidation = async (event) => {
        // Call your own server to request a new merchant session.
        // console.log("event: ", event);
        // console.log("event: ", event.validationURL);

        // event.complete(
        //   "https://apple-pay-gateway.apple.com/paymentservices/startSession"
        // );

        try {
          const response = await fetch(
            `https://fatora-sa.herokuapp.com/api/v1/push/merchant-session/new?validationURL=${event.validationURL}`,
          );
          const data = await response.json();
          event.complete(data.validationData);
        } catch (error) {
          console.log("error: ", error.message);
          setIsLoadingAppleClickPay(false);
        }
      };

      request.onpaymentmethodchange = (event) => {
        // console.log("event: 1", event.methodDetails);
        if (event.methodDetails.type !== undefined) {
          // console.log("event: 1", orderId);
          // Define PaymentDetailsUpdate based on the selected payment method.
          // No updates or errors needed, pass an object with the same total.
          const paymentDetailsUpdate = {
            total: paymentDetails.total,
          };
          event.updateWith(paymentDetailsUpdate);
        } else if (event.methodDetails.couponCode !== undefined) {
          // Define PaymentDetailsUpdate based on the coupon code.
          // console.log("event: ", event.methodDetails.couponCode);

          const total = 1;
          // const total = calculateTotal(event.methodDetails.couponCode);
          const displayItems = "NEW ITEM";
          const shippingOptions = [
            {
              id: "ground",
              label: "Ground Shipping",
              amount: { value: "5.00", currency: "SAR" },
              selected: true,
            },
            {
              id: "express",
              label: "Express Shipping",
              amount: { value: "10.00", currency: "SAR" },
            },
          ];
          const error = null;

          event.updateWith({
            total: total,
            displayItems: displayItems,
            shippingOptions: shippingOptions,
            modifiers: [
              {
                data: {
                  additionalShippingMethods: shippingOptions,
                },
              },
            ],
            error: error,
          });
        }
      };

      request.onshippingoptionchange = (event) => {
        // console.log("event: 2");

        // Define PaymentDetailsUpdate based on the selected shipping option.
        // No updates or errors needed, pass an object with the same total.
        const paymentDetailsUpdate = {
          total: paymentDetails.total,
        };
        event.updateWith(paymentDetailsUpdate);
      };

      request.onshippingaddresschange = (event) => {
        // console.log("event: 3", orderId);

        // Define PaymentDetailsUpdate based on a shipping address change.
        const paymentDetailsUpdate = {};
        event.updateWith(paymentDetailsUpdate);
      };

      // request.canMakePayment().then(async (canMakePayment) => {
      //   console.log("data make: ", request);

      // });
      const response = await request.show();
      const { token } = response.details;

      // setData1(JSON.stringify({ token: token }));
      // fetchPayment(token, response);
      paymentClickPayByApple(
        token,
        response,
        isFree ? 9.99 : (totalPrice + 9.99).toFixed(2),
      );
    } catch (e) {
      // Handle errors
      setIsLoadingAppleClickPay(false);
      console.log("error: ", e.message);
    }
  }

  async function onApplePayButtonClicked() {
    setIsLoadingApple(true);
    // Consider falling back to Apple Pay JS if Payment Request is not available.
    if (!PaymentRequest) {
      return;
    }

    try {
      // Define PaymentMethodData
      const paymentMethodData = [
        {
          supportedMethods: "https://apple.com/apple-pay",
          data: {
            version: 3,
            // merchantIdentifier: "merchant.com.adeb1991.explicationyourdream",
            merchantIdentifier: "merchant.me.naem",
            merchantCapabilities: [
              "supports3DS",
              "supportsCredit",
              "supportsDebit",
            ],
            supportedNetworks: [
              "amex",
              "discover",
              "masterCard",
              "visa",
              "mada",
            ],
            countryCode: "SA",
          },
        },
      ];
      // Define PaymentDetails
      const paymentDetails = {
        total: {
          label: "iphone 5",
          amount: {
            value: "1",
            currency: "SAR",
          },
        },
      };
      // Define PaymentOptions
      const paymentOptions = {
        requestPayerName: false,
        requestBillingAddress: false,
        requestPayerEmail: false,
        requestPayerPhone: false,
        requestShipping: true,
        shippingType: "shipping",
      };

      // Create PaymentRequest
      const request = new PaymentRequest(
        paymentMethodData,
        paymentDetails,
        paymentOptions,
      );

      request.onmerchantvalidation = (event) => {
        // Call your own server to request a new merchant session.
        // console.log("event: ", event);
        // console.log("event: ", event.validationURL);

        fetch("https://fatora-sa.herokuapp.com/api/v1/push/fetchNoonPayment", {
          method: "POST",
          body: JSON.stringify({
            validationUrl:
              "https://apple-pay-gateway.apple.com/paymentservices/startSession",
          }),
          headers: {
            "Content-type": "application/json",
          },
        })
          .then((res) => res.json())
          .then(
            (data) =>
              // console.log("data id: ", data.id, data.validationData) &
              setData2(JSON.parse(data.validationData)) &
              (orderId.current = data.id) &
              event.complete(JSON.parse(data.validationData)),
          )
          .catch((err) => {
            setIsLoadingApple(false);
            console.log("error: ", err);
          });
      };

      request.onpaymentmethodchange = (event) => {
        if (event.methodDetails.type !== undefined) {
          // console.log("event: 1", orderId);
          // Define PaymentDetailsUpdate based on the selected payment method.
          // No updates or errors needed, pass an object with the same total.
          const paymentDetailsUpdate = {
            total: paymentDetails.total,
          };
          event.updateWith(paymentDetailsUpdate);
        } else if (event.methodDetails.couponCode !== undefined) {
          // Define PaymentDetailsUpdate based on the coupon code.
          // console.log("event: ", event.methodDetails.couponCode);

          const total = 1;
          // const total = calculateTotal(event.methodDetails.couponCode);
          const displayItems = "fsd";
          const shippingOptions = [
            {
              id: "ground",
              label: "Ground Shipping",
              amount: { value: "5.00", currency: "USD" },
              selected: true,
            },
            {
              id: "express",
              label: "Express Shipping",
              amount: { value: "10.00", currency: "USD" },
            },
          ];
          const error = null;

          event.updateWith({
            total: total,
            displayItems: displayItems,
            shippingOptions: shippingOptions,
            modifiers: [
              {
                data: {
                  additionalShippingMethods: shippingOptions,
                },
              },
            ],
            error: error,
          });
        }
      };

      request.onshippingoptionchange = (event) => {
        // console.log("event: 2");

        // Define PaymentDetailsUpdate based on the selected shipping option.
        // No updates or errors needed, pass an object with the same total.
        const paymentDetailsUpdate = {
          total: paymentDetails.total,
        };
        event.updateWith(paymentDetailsUpdate);
      };

      request.onshippingaddresschange = (event) => {
        // console.log("event: 3", orderId);

        // Define PaymentDetailsUpdate based on a shipping address change.
        const paymentDetailsUpdate = {};
        event.updateWith(paymentDetailsUpdate);
      };

      // request.canMakePayment().then(async (canMakePayment) => {
      //   console.log("data make: ", request);

      // });
      const response = await request.show();
      const { token } = response.details;
      // console.log("data res: ", token, orderId);
      // console.log("data res: ", response);
      setData1(JSON.stringify({ token: token }));
      fetchPayment(token, response);
    } catch (e) {
      // Handle errors
      setIsLoadingApple(false);
      console.log("error: ", e.message);
    }
  }

  const [open, setOpen] = useState(false);

  const [isSend, setIsSend] = useState(false);

  const { response, loading, error } = useAxios({
    method: "GET",
    url: `/companies/login/${decoded.company._id}`,
    headers: {
      accept: "*/*",
    },
  });

  return (
    <>
      <Notifications
        title={
          state != null && state.payment === false
            ? `${t("cancelPayment")}`
            : `${t("removeFromCart")}`
        }
      />
      {/* <div>{responseAddress?.address}</div> */}

      {loading === false && (
        <MerchantAuth
          open={open}
          setOpen={setOpen}
          isSend={isSend}
          setIsSend={setIsSend}
          i18n={i18n}
          active={true}
          response={response}
          t={t}
        />
      )}

      {/* this is noon payment*/}
      {/* <HelmetProvider>
        <Helmet>
          <script type="text/javascript" src={dataSrc}></script>
          <script>
            {dataSrc.length > 0 &&
              `
              async function checkOrder(checkData) {
              console.log("data jjjj: " , checkData)
              try {
                await fetch(
                  "http://localhost:9000/api/v1/push/get-order-id?orderId=" + checkData.orderId,
                  {
                    method: "GET",
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                )
                  .then((response) => response.json())
                  .then(async(d) => {
                   console.log("data ff: ", d);
                    if (
                      (d.resultCode === 0 &&
                        d.result.order.status === "CAPTURED") ||
                      (d.resultCode === 0 &&
                        d.result.order.status === "3DS_ENROLL_INITIATED")
                    ) {
                  console.log("this payment is succeffelly");

                  await fetch("http://localhost:9000/api/v1/invoices/create-casheir-invoice",
                    {
                      method:"POST",
                      headers:{
                        "Content-type":"application/json",
                      },
                      body: JSON.stringify({
                        itemNo:checkData.orderId,
                        time: new Date()
                          .toTimeString()
                          .replace(/^[^:]*([0-2]d:[0-5]d).*$/, "$1"),
                        date: new Date().toISOString().split("T")[0],
                        user_id:"${
                          jwt_decode(localStorage.getItem("Token")).user?._id
                        }",
                        title:"ATER",
                        token_id:{
                          _id:"638a8cc11dbde0961bb169b9",
                          type:"ios",
                          data:"27b6fbf996b28fde2599dcbe09a2d532da8dfaa2a14cdee96a13249bc6930325",
                          app:"ater",
                          __v:0
                        },
                        orders: localStorage.getItem("OrderId") ,
                        paymentType: "CARD",
                        price:${totalPrice?.toFixed(2)},
                        profit:${totalProfit?.toFixed(2)},
                        isShipped: false,
                        isDelivered: false,
                        orderId: checkData.orderId,
                        company_id:"${data?._id}"
                      })
                    }
                  )
                  .then(()=>{
                    window.location.href = "/order/" + checkData.orderId;
                    // setTimeout(() => {
                    // // window.location.replace("${dataLink}" + checkData.orderId);
                    //   // window.location.href("/order/" + checkData.orderId);
                    // }, 1000);

                  })
                  .catch(error=>console.log("error: ",error.message))


                } else {
                  console.log("this payment is cancel or wrong");
                }})
              } catch (error) {
                console.log("error: ", error.message);
              }
            };




            function ResponseCallBack(checkData) {
              console.log("data: ",checkData.orderId);

              checkOrder(checkData)
              
            }


            ${isCard} && ProcessCheckoutPayment({
            Checkout_Method: 3,
            SecureVerification_Method: 1,
            Call_Back:ResponseCallBack,
            Frame_Id: "The_Frame_Id",
          })`}
          </script>
        </Helmet>
      </HelmetProvider> */}

      {/* <HelmetProvider>
        <Helmet>
          <script>
            {`
              async function checkOrder() {
              try {
                await fetch(
                  "https://fatora-sa.herokuapp.com/api/v1/push/fetchClickPay",
                  {
                    method: "POST",
                    headers: {
                      "Content-type": "application/json",
                    },
                  }
                ).then((response) => response.json())
                  .then((d) => console.log("data: ", d))
                  .catch(error=>console.log("error: ",error.message))

              } catch (error) {
                console.log("error: ", error.message);
              }
            };

            checkOrder()

          `}
          </script>
        </Helmet>
      </HelmetProvider> */}

      {/* <HelmetProvider>
        <Helmet>
          <script>
            {isCard &&
              `setTimeout(
              () =>  window.location.href ="https://secure.clickpay.com.sa/payment/wr/5FE756F782E5F54947CCB2C809C07EEB94276E6146176301C53A1171",
              1000
            )`}
          </script>
        </Helmet>
      </HelmetProvider> */}

      {/* <div
          className="bg-yellow-800 w-full h-100"
          dangerouslySetInnerHTML={{
            __html: `
            <script>
            var settings = {
              Checkout_Method: 1,
              SecureVerification_Method: 1,
              Call_Back: () => {},
              Frame_Id: "The_Frame_Id",
            };
      
            ProcessCheckoutPayment(settings);
          </script>`,
          }}
        ></div> */}

      {loadingSeconde ? (
        <div className="flex w-full items-center justify-center py-20">
          <div className="flex items-center justify-center space-x-1 text-sm text-gray-700 rtl:space-x-reverse">
            <svg
              fill="none"
              className="h-20 w-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : JSON.parse(localStorage.getItem("Order")).length === 0 ? (
        <div className=" container mx-auto  max-w-2xl px-4 pb-24 pt-5 sm:px-6 lg:max-w-5xl lg:px-8">
          <div className="flex flex-row justify-between rounded bg-gray-200 p-3 px-5">
            <h1 className="text-1xl mt-0.5 font-bold tracking-tight text-gray-900 sm:text-xl">
              {t("notProductsinCart")}
            </h1>
            <button
              onClick={() => {
                // navigate(`/${localStorage.getItem("CN")}`);
                navigate("/settings");
                // setCartCount(0);
              }}
              // href={`/${localStorage.getItem("CN")}`}
              className="flex flex-row rounded bg-gray-600 px-2 text-white "
            >
              <span className="text-1xl sm:text-1xl pb-1  pl-3 pr-3 font-bold tracking-tight sm:mt-0.5">
                {t("back")}
              </span>
              <ArrowUturnRightIcon
                className="mt-0.5 h-5 w-5 sm:mt-1.5"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      ) : (
        <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
          <h1 className="sr-only">{t("checkout")}</h1>
          <section
            aria-labelledby="order-heading"
            className="bg-gray-50 px-4 py-6 sm:px-6 lg:hidden"
          >
            <Disclosure as="div" className="mx-auto max-w-lg">
              {({ open }) => (
                <>
                  <div className="flex items-center justify-between pb-5">
                    <h2
                      id="order-heading"
                      className="text-lg font-medium text-gray-900"
                    >
                      {t("yourOrder")}
                    </h2>
                    <Disclosure.Button className="font-medium text-indigo-600 hover:text-indigo-500">
                      {open ? (
                        <span> {t("hide")} </span>
                      ) : (
                        <span> {t("show")} </span>
                      )}
                    </Disclosure.Button>
                  </div>

                  <Disclosure.Panel>
                    <ul
                      role="list"
                      className="divide-y divide-gray-200 border-b border-gray-200"
                    >
                      {JSON.parse(localStorage.getItem("Order"))
                        // .filter((p) => p.company_id === data?._id)
                        .map((product) => (
                          <li
                            key={product._id}
                            className="flex space-x-6 py-6 rtl:space-x-reverse"
                          >
                            <img
                              src={product.image.split(",")[0]}
                              alt={"image0"}
                              className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
                            />
                            <div className="flex flex-col justify-between space-y-4">
                              <div className="space-y-1 text-sm font-medium">
                                <h3 className="text-gray-900">
                                  {textCutFunc(product.name)}
                                </h3>
                                <p className="text-gray-900">
                                  {/* {product?.unit + " " + t("sar")} */}
                                  {product._id === "644c3918d64ebf3a0cda5d51"
                                    ? "مجاناً"
                                    : product.unit.toFixed(2) + " ر.س "}
                                </p>
                                <p className="text-gray-500">
                                  {t("quantity")}: {product.qty}
                                </p>
                                {false && product?.color?.length > 0 ? (
                                  <div className="flex flex-row gap-1">
                                    <p className="text-gray-500 ">
                                      {t("color") + ":"}
                                    </p>
                                    {product?.color?.map((color) => (
                                      <p
                                        className="mx-1 h-5 w-5 rounded-xl text-gray-500 ring-2"
                                        style={{ backgroundColor: color }}
                                      ></p>
                                    ))}
                                  </div>
                                ) : null}
                                {false && product.size?.length > 0 ? (
                                  <div className="flex flex-row gap-1">
                                    <p className="text-gray-500 ">
                                      {t("size")}:
                                    </p>
                                    {product.size.map((size, k) => (
                                      <p className="text-gray-500 ">
                                        {size}{" "}
                                        {k !== product.size?.length - 1 ? (
                                          <span>-</span>
                                        ) : (
                                          " "
                                        )}
                                      </p>
                                    ))}
                                  </div>
                                ) : null}
                              </div>
                              <div className="flex space-x-4 rtl:space-x-reverse">
                                <button
                                  // href={`/${localStorage.getItem("CN")}`}
                                  onClick={() => {
                                    navigate("/settings");
                                  }}
                                  className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                >
                                  {t("edite")}
                                </button>
                                <div className="flex border-gray-300 ltr:border-l ltr:pl-4 rtl:border-r rtl:pr-4">
                                  <button
                                    onClick={() => updateCart(product._id)}
                                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                  >
                                    {t("remove")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>

                    {/* <form className="mt-10">
                    <label
                      htmlFor="discount-code-mobile"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Discount code
                    </label>
                    <div className="mt-1 flex space-x-4 rtl:space-x-reverse">
                      <input
                        type="text"
                        id="discount-code-mobile"
                        name="discount-code-mobile"
                        className="block w-full border py-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <button
                        type="submit"
                        className="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                      >
                        Apply
                      </button>
                    </div>
                  </form> */}

                    <dl className="my-5 space-y-6 text-sm font-medium text-gray-500">
                      <div className="flex justify-between">
                        <dt>{t("serviceFees")}</dt>
                        <dd className="text-gray-900">
                          {9.99 + " " + t("sar")}
                        </dd>
                      </div>
                      <div className="flex justify-between">
                        <dt>{t("subTotal")}</dt>
                        <dd className="text-gray-900">
                          {isFree
                            ? "9.99 " + t("sar")
                            : totalPrice.toFixed(2) + " " + t("sar")}
                        </dd>
                      </div>
                      {/* <div className="flex justify-between">
                      <dt className="flex">
                        Discount
                        <span className="ltr:ml-2 rtl:mr-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs tracking-wide text-gray-600">
                          {discount.code}
                        </span>
                      </dt>
                      <dd className="text-gray-900">-{discount.amount}</dd>
                    </div> */}
                      {/* <div className="flex justify-between">
                        <dt>{t("vat")}</dt>
                        <dd className="text-gray-900">{"00.00 " + t("sar")}</dd>
                      </div> */}
                    </dl>
                  </Disclosure.Panel>
                  {/* <div className=" flex items-center justify-between border-t border-gray-200 py-6 text-gray-900">
                    <dt className="text-base">{t("shippingAddress")}</dt>
                    <div className="flex flex-row gap-5">
                      {response != null ? (
                        <button
                          onClick={() => setOpen(true)}
                          className="text-base text-indigo-600 hover:text-indigo-500"
                        >
                          تعديل
                        </button>
                      ) : (
                        <button className="text-base text-red-600 hover:text-red-500">
                          إضافة
                        </button>
                      )}
                    </div>
                  </div> */}

                  <p className=" flex items-center justify-between border-t border-gray-200 pt-6 text-sm font-medium text-gray-900">
                    <span className="text-base">{t("orderTotal")} </span>
                    <span className="text-base">
                      {isFree
                        ? "9.99 " + t("sar")
                        : (totalPrice + 9.0).toFixed(2) + " " + t("sar")}
                    </span>
                  </p>
                </>
              )}
            </Disclosure>
          </section>

          {/* Order summary */}
          <section
            aria-labelledby="summary-heading"
            className="hidden w-full max-w-md flex-col bg-gray-50 lg:flex"
          >
            <h2 id="summary-heading" className="sr-only">
              {t("yourOrder")}
            </h2>
            <ul
              // role="list"
              className="flex-auto divide-y divide-gray-200 overflow-y-auto px-6"
            >
              {JSON.parse(localStorage.getItem("Order"))
                // .filter((p) => p.company_id === data?._id)
                .map((product) => (
                  <li
                    key={product._id}
                    className="flex space-x-6 py-6 rtl:space-x-reverse"
                  >
                    <img
                      src={product.image.split(",")[0]}
                      alt={"image0"}
                      className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
                    />
                    <div className="flex flex-col justify-between space-y-4">
                      <div className="space-y-1 text-sm font-medium">
                        <h3 className="text-gray-900">
                          {textCutFunc(product.name)}
                        </h3>
                        <p className="text-gray-900">
                          {/* {product?.unit + " " + t("sar")} */}
                          {product._id === "644c3918d64ebf3a0cda5d51"
                            ? "مجاناً"
                            : product.unit.toFixed(2) + " ر.س "}
                        </p>

                        <p className="text-gray-500">
                          {t("quantity")}: {product.qty}
                        </p>
                        {false && product?.color?.length > 0 ? (
                          <div className="flex flex-row gap-1">
                            <p className="text-gray-500 ">{t("color") + ":"}</p>
                            {product?.color?.map((color) => (
                              <p
                                className="mx-1 h-5 w-5 rounded-xl text-gray-500 ring-2"
                                style={{ backgroundColor: color }}
                              ></p>
                            ))}
                          </div>
                        ) : null}
                        {false && product.size?.length > 0 ? (
                          <div className="flex flex-row gap-1">
                            <p className="text-gray-500 ">{t("size")}:</p>
                            {product.size.map((size, k) => (
                              <p className="text-gray-500 ">
                                {size}{" "}
                                {k !== product.size?.length - 1 ? (
                                  <span>-</span>
                                ) : (
                                  " "
                                )}
                              </p>
                            ))}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex space-x-4 rtl:space-x-reverse">
                        <button
                          // href={`/${localStorage.getItem("CN")}`}
                          onClick={() => {
                            navigate("/settings");
                          }}
                          className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          {t("edite")}
                        </button>
                        <div className="flex border-gray-300 ltr:border-l ltr:pl-4 rtl:border-r rtl:pr-4">
                          <button
                            onClick={() => updateCart(product._id)}
                            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            {t("remove")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>

            <div className="sticky bottom-0 flex-none border-t border-gray-200 bg-gray-50 p-6">
              {/* <form>
              <label
                htmlFor="discount-code"
                className="block text-sm font-medium text-gray-700"
              >
                Discount code
              </label>
              <div className="mt-1 flex space-x-4 rtl:space-x-reverse">
                <input
                  type="text"
                  id="discount-code"
                  name="discount-code"
                  className="block w-full rounded-md border py-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <button
                  type="submit"
                  className="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  Apply
                </button>
              </div>
            </form> */}

              <dl className="mt-0 space-y-6 text-sm font-medium text-gray-500">
                <div className="flex justify-between">
                  <dt>{t("serviceFees")}</dt>
                  <dd className="text-gray-900">{9.99 + " " + t("sar")}</dd>
                </div>
                <div className="flex justify-between">
                  <dt>{t("subTotal")}</dt>
                  <dd className="text-gray-900">
                    {isFree
                      ? "9.99 " + t("sar")
                      : totalPrice.toFixed(2) + " " + t("sar")}
                  </dd>
                </div>
                {/* <div className="flex justify-between">
                <dt className="flex">
                  Discount
                  <span className="ltr:ml-2 rtl:mr-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs tracking-wide text-gray-600">
                    {discount.code}
                  </span>
                </dt>
                <dd className="text-gray-900">-{discount.amount}</dd>
              </div> */}
                {/* <div className="flex justify-between">
                  <dt>{t("vat")}</dt>
                  <dd className="text-gray-900">{"00.00 " + t("sar")}</dd>
                </div> */}

                {/* <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                  <dt className="text-base">{t("shippingAddress")}</dt>
                  <div className="flex flex-row gap-5">
                    {response != null ? (
                      <button
                        onClick={() => setOpen(true)}
                        className="text-base text-indigo-600 hover:text-indigo-500"
                      >
                        تعديل
                      </button>
                    ) : (
                      <button className="text-base text-red-600 hover:text-red-500">
                        إضافة
                      </button>
                    )}
                  </div>
                </div> */}
                <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                  <dt className="text-base">{t("orderTotal")}</dt>
                  <dd className="text-base">
                    {isFree
                      ? "9.99 " + t("sar")
                      : (totalPrice + 9.0).toFixed(2) + " " + t("sar")}
                  </dd>
                </div>
              </dl>
            </div>
          </section>

          {/* Checkout form */}
          <section
            aria-labelledby="payment-heading"
            className="flex-auto overflow-y-auto px-4 pb-16 pt-12 sm:px-6 sm:pt-12 lg:px-8 lg:pb-24 lg:pt-0"
          >
            <div className="mx-auto max-w-5xl">
              {/* <div className="hidden pb-10 pt-0 lg:flex"> */}
              {/* <a href="#">
                <span className="sr-only">Your Company</span>
                <img
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                  className="h-8 w-auto"
                />
              </a> */}
              {/* </div> */}

              {(isSafari || true) && (
                <button
                  type="button"
                  className={classNames(
                    isCard ? "hidden" : "",
                    "flex w-full items-center justify-center rounded-md border border-transparent bg-black py-2 text-white hover:bg-gray-800 focus:outline-none focus:ring-gray-900 focus:ring-offset-2 lg:mt-12",
                  )}
                  onClick={onApplePayButtonClickPay}
                >
                  {isLoadingAppleClickPay ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline h-5 w-auto animate-spin text-white ltr:mr-3 rtl:ml-3"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <span className="sr-only">Pay with Apple Pay</span>
                      <svg
                        className="h-5 w-auto"
                        fill="currentColor"
                        viewBox="0 0 50 20"
                      >
                        <path d="M9.536 2.579c-.571.675-1.485 1.208-2.4 1.132-.113-.914.334-1.884.858-2.484C8.565.533 9.564.038 10.374 0c.095.951-.276 1.884-.838 2.579zm.829 1.313c-1.324-.077-2.457.751-3.085.751-.638 0-1.6-.713-2.647-.694-1.362.019-2.628.79-3.323 2.017-1.429 2.455-.372 6.09 1.009 8.087.676.99 1.485 2.075 2.552 2.036 1.009-.038 1.409-.656 2.628-.656 1.228 0 1.58.656 2.647.637 1.104-.019 1.8-.99 2.475-1.979.771-1.122 1.086-2.217 1.105-2.274-.02-.019-2.133-.828-2.152-3.263-.02-2.036 1.666-3.007 1.742-3.064-.952-1.408-2.437-1.56-2.951-1.598zm7.645-2.76v14.834h2.305v-5.072h3.19c2.913 0 4.96-1.998 4.96-4.89 0-2.893-2.01-4.872-4.885-4.872h-5.57zm2.305 1.941h2.656c2 0 3.142 1.066 3.142 2.94 0 1.875-1.142 2.95-3.151 2.95h-2.647v-5.89zM32.673 16.08c1.448 0 2.79-.733 3.4-1.893h.047v1.779h2.133V8.582c0-2.14-1.714-3.52-4.351-3.52-2.447 0-4.256 1.399-4.323 3.32h2.076c.171-.913 1.018-1.512 2.18-1.512 1.41 0 2.2.656 2.2 1.865v.818l-2.876.171c-2.675.162-4.123 1.256-4.123 3.159 0 1.922 1.495 3.197 3.637 3.197zm.62-1.76c-1.229 0-2.01-.59-2.01-1.494 0-.933.752-1.475 2.19-1.56l2.562-.162v.837c0 1.39-1.181 2.379-2.743 2.379zM41.1 20c2.247 0 3.304-.856 4.227-3.454l4.047-11.341h-2.342l-2.714 8.763h-.047l-2.714-8.763h-2.409l3.904 10.799-.21.656c-.352 1.114-.923 1.542-1.942 1.542-.18 0-.533-.02-.676-.038v1.779c.133.038.705.057.876.057z" />
                      </svg>
                    </>
                  )}
                </button>
              )}
              {/* {(isSafari || true) && (
                <button
                  type="button"
                  className={classNames(
                    isCard ? "hidden" : "",
                    "lg:mt-12 flex w-full items-center justify-center rounded-md border border-transparent bg-black py-2 text-white hover:bg-gray-800 focus:outline-none focus:ring-gray-900 focus:ring-offset-2"
                  )}
                  onClick={
                    () =>
                      onApplePayButtonClicked()
                  }
                >
                  {isLoadingApple ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-auto h-5 text-white animate-spin rtl:ml-3 ltr:mr-3"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <span className="sr-only">Pay with Apple Pay</span>
                      <svg
                        className="h-5 w-auto"
                        fill="currentColor"
                        viewBox="0 0 50 20"
                      >
                        <path d="M9.536 2.579c-.571.675-1.485 1.208-2.4 1.132-.113-.914.334-1.884.858-2.484C8.565.533 9.564.038 10.374 0c.095.951-.276 1.884-.838 2.579zm.829 1.313c-1.324-.077-2.457.751-3.085.751-.638 0-1.6-.713-2.647-.694-1.362.019-2.628.79-3.323 2.017-1.429 2.455-.372 6.09 1.009 8.087.676.99 1.485 2.075 2.552 2.036 1.009-.038 1.409-.656 2.628-.656 1.228 0 1.58.656 2.647.637 1.104-.019 1.8-.99 2.475-1.979.771-1.122 1.086-2.217 1.105-2.274-.02-.019-2.133-.828-2.152-3.263-.02-2.036 1.666-3.007 1.742-3.064-.952-1.408-2.437-1.56-2.951-1.598zm7.645-2.76v14.834h2.305v-5.072h3.19c2.913 0 4.96-1.998 4.96-4.89 0-2.893-2.01-4.872-4.885-4.872h-5.57zm2.305 1.941h2.656c2 0 3.142 1.066 3.142 2.94 0 1.875-1.142 2.95-3.151 2.95h-2.647v-5.89zM32.673 16.08c1.448 0 2.79-.733 3.4-1.893h.047v1.779h2.133V8.582c0-2.14-1.714-3.52-4.351-3.52-2.447 0-4.256 1.399-4.323 3.32h2.076c.171-.913 1.018-1.512 2.18-1.512 1.41 0 2.2.656 2.2 1.865v.818l-2.876.171c-2.675.162-4.123 1.256-4.123 3.159 0 1.922 1.495 3.197 3.637 3.197zm.62-1.76c-1.229 0-2.01-.59-2.01-1.494 0-.933.752-1.475 2.19-1.56l2.562-.162v.837c0 1.39-1.181 2.379-2.743 2.379zM41.1 20c2.247 0 3.304-.856 4.227-3.454l4.047-11.341h-2.342l-2.714 8.763h-.047l-2.714-8.763h-2.409l3.904 10.799-.21.656c-.352 1.114-.923 1.542-1.942 1.542-.18 0-.533-.02-.676-.038v1.779c.133.038.705.057.876.057z" />
                      </svg>
                    </>
                  )}
                </button>
              )} */}

              {/* {isSafari && (
                <div className="relative mt-8">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-4 text-sm font-medium text-gray-500">
                      أو
                    </span>
                  </div>
                </div>
              )} */}

              <div className="mt-4">
                {/* {isCard && (
                  <div className=" h-96 bg-white rounded ">
                    <iframe
                      title="CARD"
                      id="The_Frame_Id"
                      className="w-full h-full bg-white rounded"
                    ></iframe>
                  </div>
                )} */}

                {isCard && (
                  <div className="flex  w-full flex-col rounded bg-white">
                    <iframe
                      id={"myFrame"}
                      // src="https://secure.clickpay.com.sa/payment/wr/5FEA7D8A82E4CB40B60DA6789C03B27E4201B6E04E9E623F5F47DBB5"
                      title="clickpay"
                      className="aspect-video"
                    ></iframe>
                    <button
                      className={classNames(
                        isCard === false ? "hidden" : "",
                        "my-3   flex w-full justify-center rounded-md border bg-indigo-600 px-3 py-2 text-sm  font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-indigo-600 focus-visible:outline  focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ",
                      )}
                      onClick={() => {}}
                    >
                      طباعة الفاتورة
                    </button>
                  </div>
                )}

                {/* <button
                  onClick={
                    () => paymentTabby()
                  }
                  className={classNames(
                    isCard ? "hidden" : "",
                    "bg-gradient-to-r from-tamarafrom to-tamarafor hover:from-tamarafor hover:to-tamarafrom  flex w-full border my-3 justify-center rounded-md  px-3 py-2 text-sm font-semibold text-black shadow-sm  "
                  )}
                >
                  {isLoadingTabby ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-5 h-5 text-black animate-spin rtl:ml-3 ltr:mr-3"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#4b5563"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <span className="ml-2 text-black">
                        الدفع عن طريق تمارا
                      </span>
                    </>
                  )}
                </button> */}
                <button
                  onClick={
                    () => paymentTabby()
                    // () => fetchPaymentCardPageCheckOut()

                    // navigate("/order/891867150")
                  }
                  className={classNames(
                    isCard ? "hidden" : "",
                    "my-3 flex w-full justify-center rounded-md  border bg-gradient-to-r from-tabbyfrom to-tabbyfor px-3 py-2  text-sm font-semibold text-black shadow-sm hover:from-tabbyfor hover:to-tabbyfrom  ",
                  )}
                >
                  {isLoadingTabby ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline h-5 w-5 animate-spin text-black ltr:mr-3 rtl:ml-3"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#4b5563"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <span className="ml-2 text-black">{t("payByTabby")}</span>
                      <span className="pt-0.5">
                        <svg
                          className="hidden rtl:block"
                          width="50"
                          height="20"
                          viewBox="0 0 141 56"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M122.239 16.873L113.948 48.5272L113.929 48.5989H120.386L128.707 16.873H122.239Z"
                            fill="#000000"
                          ></path>
                          <path
                            d="M19.643 34.8883C18.6747 35.3651 17.6091 35.6112 16.5297 35.6072C14.2043 35.6072 12.8847 35.2358 12.7413 33.3563V33.2297C12.7413 33.1551 12.7328 33.089 12.7328 33.0018V27.528L12.7413 26.8837V23.0248H12.7328V21.3831L12.7413 20.7332V17.008L6.97342 17.7691C10.8744 17.0136 13.1126 13.9272 13.1126 10.8505V8.95557H6.63157V17.8183L6.26581 17.921V34.334C6.47964 38.9455 9.5183 41.6859 14.504 41.6859C16.2667 41.6859 18.2066 41.285 19.6922 40.6111L19.726 40.597V34.8391L19.643 34.8883Z"
                            fill="#000000"
                          ></path>
                          <path
                            d="M20.6657 15.6799L2.47736 18.4837V23.0951L20.6657 20.2914V15.6799Z"
                            fill="#000000"
                          ></path>
                          <path
                            d="M20.6657 22.427L2.47736 25.2307V29.6368L20.6657 26.8317V22.427Z"
                            fill="#000000"
                          ></path>
                          <path
                            d="M41.0782 24.5485C40.8208 19.425 37.6189 16.3892 32.4026 16.3892C29.4033 16.3892 26.9316 17.5469 25.2533 19.7401C23.575 21.9333 22.6971 25.1394 22.6971 29.0362C22.6971 32.933 23.5806 36.1489 25.2533 38.3336C26.9259 40.5184 29.4033 41.6846 32.4026 41.6846C37.6189 41.6846 40.8208 38.6333 41.0782 33.483V41.2021H47.5495V16.9294L41.0782 17.9282V24.5485ZM41.4159 29.0418C41.4159 33.5801 39.0328 36.5119 35.3456 36.5119C31.5472 36.5119 29.2753 33.7194 29.2753 29.0418C29.2711 24.3375 31.5402 21.5239 35.3414 21.5239C37.1899 21.5239 38.7345 22.2442 39.8079 23.606C40.856 24.9368 41.4116 26.8149 41.4116 29.0376L41.4159 29.0418Z"
                            fill="#000000"
                          ></path>
                          <path
                            d="M66.4581 16.3905C61.2389 16.3905 58.0356 19.4277 57.7824 24.5569V9.86157L51.3013 10.8618V41.2034H57.7824V33.4815C58.0356 38.6388 61.2389 41.693 66.4581 41.693C72.5649 41.693 76.2113 36.9647 76.2113 29.0445C76.2113 21.1243 72.5649 16.3905 66.4581 16.3905ZM63.5151 36.5075C59.8265 36.5075 57.4448 33.5758 57.4448 29.0375C57.4448 26.8148 57.999 24.9367 59.0485 23.6031C60.1219 22.2413 61.6665 21.521 63.5151 21.521C67.3134 21.521 69.5854 24.3346 69.5854 29.0375C69.5854 33.7151 67.3162 36.5075 63.5151 36.5075Z"
                            fill="#000000"
                          ></path>
                          <path
                            d="M93.8595 16.3905C88.6403 16.3905 85.4385 19.4277 85.1838 24.5569V9.86157L78.7028 10.8618V41.2034H85.1838V33.4815C85.4385 38.6388 88.6403 41.693 93.8595 41.693C99.9663 41.693 103.613 36.9647 103.613 29.0445C103.613 21.1243 99.9663 16.3905 93.8595 16.3905ZM90.9165 36.5075C87.2293 36.5075 84.8462 33.5758 84.8462 29.0375C84.8462 26.8148 85.4005 24.9367 86.4499 23.6031C87.5233 22.2413 89.068 21.521 90.9165 21.521C94.7148 21.521 96.9868 24.3346 96.9868 29.0375C96.9868 33.7151 94.7176 36.5075 90.9165 36.5075Z"
                            fill="#000000"
                          ></path>
                          <path
                            d="M103.628 16.873H110.548L116.17 41.1584H109.966L103.628 16.873Z"
                            fill="#000000"
                          ></path>
                        </svg>
                      </span>
                    </>
                  )}
                </button>
                <button
                  onClick={
                    () => fetchPaymentCardPageCheckOut()

                    // navigate("/order/891867150")
                  }
                  className={classNames(
                    isCard ? "hidden" : "",
                    "my-3   flex w-full justify-center rounded-md border bg-indigo-600 px-3 py-2 text-sm  font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-indigo-600 focus-visible:outline  focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ",
                  )}
                >
                  {isLoading ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline h-5 w-5 animate-spin text-white ltr:mr-3 rtl:ml-3"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <CreditCardIcon
                        className="h-5 w-5 text-white ltr:mr-1.5 rtl:ml-1.5"
                        aria-hidden="true"
                      />
                      {t("payByCard")}
                    </>
                  )}
                </button>
                {/* <button
                  onClick={
                    () => fetchPaymentCardPageCheckOut()

                    // navigate("/order/891867150")
                  }
                  className={classNames(
                    isCard ? "hidden" : "",
                    "focus:ring-indigo-600   bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 flex w-full border my-3 justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                  )}
                >
                  {isLoading ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-5 h-5 text-white animate-spin rtl:ml-3 ltr:mr-3"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <CreditCardIcon
                        className="ltr:mr-1.5 rtl:ml-1.5 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      {t("payByCard")}
                    </>
                  )}
                </button> */}

                <p className="mt-6 flex justify-center text-sm font-medium text-gray-500 ">
                  <LockClosedIcon
                    className="h-5 w-5 text-green-600 ltr:mr-1.5 rtl:ml-1.5"
                    aria-hidden="true"
                  />
                  {/* <ShieldCheckIcon
                    className="ltr:mr-1.5 rtl:ml-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  /> */}
                  {t("secureOnlineShopping")}
                </p>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  );
}
