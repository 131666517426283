import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import instance from "../api/api";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AlerteChangePackage({
  openAlerteChange,
  setOpenAlerteChange,
  isSendAlerteMore,
  setIsSendAlerteMore,
  t,
  handleAddProduct,
  cartItmes,
  setCartItems,
  handleRemoveProduct,
  selectProduct
}) {
  const cancelButtonRef = useRef(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [handleError, setHandleError] = useState("");
  const [newOject, setNewObject] = useState({
    email: "",
  });

  const navigate = useNavigate();

  const handleFields = (e) => {
    setHandleError("");
    setNewObject({ ...newOject, [e.target.name]: e.target.value });
  };

  //   const sendLinkPassword = async (e) => {
  //     e.preventDefault();
  //     setIsLoading(true);
  //     // console.log("email: ", newOject);
  //     // setIsSend(false);

  //     try {
  //       const res = await instance.post(`/users/reset-password-request-naem`, {
  //         email: newOject.email,
  //       });
  //       if (res) {
  //         // localStorage.setItem("Token", request.data.accessToken);
  //         // console.log("jj :", request.data);
  //         setTimeout(() => {
  //           setIsLoading(false);
  //           setIsSend(true);
  //           // navigate("/login");
  //           setNewObject({ email: "" });
  //         }, 2000);
  //       }
  //     } catch (error) {
  //       setTimeout(() => {
  //         setHandleError(error.response.data.error);
  //         setIsSend(false);
  //         setIsLoading(false);
  //         setNewObject({ email: "" });
  //       }, 2000);
  //     }
  //   };

  return (
    <Transition.Root show={openAlerteChange} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpenAlerteChange}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-10 pb-4 pt-5 rtl:text-right ltr:text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl  sm:p-6">
                <form
                  className=""
                  action="#"
                  method="POST"
                  //   onSubmit={sendLinkPassword}
                >
                  <div>
                    <div
                      className={classNames(
                        isSendAlerteMore ? "bg-green-100" : "bg-red-100",
                        "mx-auto flex h-12 w-12 items-center justify-center rounded-full"
                      )}
                    >
                      {isSendAlerteMore ? (
                        <PaperAirplaneIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      ) : (
                        <ExclamationCircleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      {/* <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      تم الإرسال بنجاح
                    </Dialog.Title> */}
                      <div className="my-2 w-full rtl:text-right ltr:text-left">
                        <p className="text-sm text-gray-500">
                          {/* {`${t("alertMoreSizeAndColor")}`} */}
                          لا يمكن اختيار اكثر من باقة في وقت واحد{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  {isSendAlerteMore === false && (
                    <>
                      {/* <input
                        id="email"
                        name="email"
                        type="email"
                        value={newOject.email}
                        onChange={(e) => handleFields(e)}
                        placeholder="test@gmail.com"
                        autoComplete="email"
                        required
                        className="block w-full px-2 rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 my-0 "
                      />

                      {handleError?.length > 0 && (
                        <span className="flex items-center font-medium  tracking-wide text-red-500 text-xs mt-1 ltr:ml-1 rtl:mr-1 ">
                          {t("youDontHaveEmail")}
                        </span>
                      )} */}

                      <div className="mt-1 sm:mt-3 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-gray-100  px-3 py-2 text-sm font-semibold  text-gray-900 hover:text-white shadow-sm ring-1 ring-inset ring-gray-100 hover:ring-blue-300 hover:bg-indigo-600  ltr:sm:col-start-1 rtl:col-start-1 sm:mt-0"
                          onClick={() => {
                            // localStorage.removeItem("Order")
                            // setCartItems([])
                            handleRemoveProduct(selectProduct)
                            setOpenAlerteChange(false);
                            // navigate(`/detail/${selectProduct._id}`);
                          }}
                          ref={cancelButtonRef}
                        >
                          {t("changePackage")}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold  text-gray-900 hover:text-white shadow-sm ring-1 ring-inset ring-gray-100 hover:ring-blue-300 hover:bg-indigo-600  ltr:sm:col-start-2 rtl:col-start-2 sm:mt-0"
                          onClick={() => {
                            setOpenAlerteChange(false);
                            // handleAddProduct(selectProduct) &
                            //   (cartItmes?.length > 0) &&
                            //   localStorage.setItem(
                            //     "Order",
                            //     JSON.stringify(cartItmes)
                            //   );
                          }}
                          ref={cancelButtonRef}
                        >
                          {t("later")}
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
