import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { isNumber } from "lodash";

import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  ShoppingBagIcon,
  BanknotesIcon,
  PresentationChartLineIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useAxiosSeconde, useNaemApi } from "../api/api";
import { useAxiosDublecate } from "../api/api";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { faker } from "@faker-js/faker";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
const fpPromise = FingerprintJS.load();

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
];

export default function Dashboard({ t }) {
  const navigate = useNavigate();

  // const { naemData, errorNaem, loadingNaem } = useNaemApi({
  //   method: "GET",
  //   url: `/companies/company-limits?companyName=NAEM&limit=20`,
  //   headers: {
  //     // no need to stringify
  //     accept: "*/*",
  //   },
  // });

  const { data1, data2, loading, error } = useAxiosDublecate(
    "",
    {
      method: "GET",
      url: `/companies/get-company-name/${
        localStorage.getItem("CN") !== null && localStorage.getItem("CN")
      }`,
      headers: {
        accept: "*/*",
      },
    },
    {
      method: "GET",
      url: "/invoices/get-casheir-invoices/",
      headers: {
        accept: "*/*",
      },
    },
  );

  const month = new Date().getMonth();
  const day = new Date().getDate();

  const arr = data2
    ?.filter((m) => Number(m.date.slice(5, 7)) === month + 1)
    .map((d) => d.date.slice(8, 10));

  const uniqueElements = [...new Set(arr)];
  const count = uniqueElements.map((element, key) => [
    element,
    arr.filter((el) => el === element).length,
  ]);

  function Arrayd(arr) {
    var a = [];
    for (var i = 1; i < 31; i++) {
      // eslint-disable-next-line no-loop-func
      if (arr.filter((elemet) => Number(elemet[0]) === i).length > 0) {
        // eslint-disable-next-line no-loop-func
        a.push(...arr.filter((elemet) => Number(elemet[0]) === i));
      } else {
        a.push([i.toString(), 0]);
      }
    }
    return a;
  }

  const totalPriceForDay = (arr) => {
    var a = [];
    for (var i = 1; i < 31; i++) {
      a.push(
        arr?.length > 0
          ? arr
              // ?.map((m) => m.price > 0  )
              ?.filter(
                (m) =>
                  Number(m.date.slice(5, 7)) === month + 1 &&
                  Number(m.date.slice(8, 10)) == i,
              )
              .reduce((price, item) => price + item.price + 9.0, 0)
              .toFixed(2)
          : 0,
      );
    }
    return a;
  };

  const totalPrice =
    data2?.length > 0
      ? data2
          ?.filter(
            (m) => m.profit != null && Number(m.date.slice(5, 7)) === month + 1,
          )
          .reduce((price, item) => price + item.price + 9.0, 0)
      : 0;
  const totalProfits =
    data2?.length > 0
      ? data2
          ?.filter(
            (m) => m.profit != null && Number(m.date.slice(5, 7)) === month + 1,
          )
          .reduce((price, item) => price + item.profit, 0)
      : 0;

  const datae = {
    labels,
    datasets: [
      {
        label: "عدد الطلبات",
        data: Arrayd(count),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      // {
      //   label: "الأرباح",
      //   data: totalProfitForDay(data2),
      //   borderColor: "rgb(1, 300, 235)",
      //   backgroundColor: "rgba(1, 300, 235, 0.5)",
      // },
      {
        label: "إجمالي المبيعات",
        data: totalPriceForDay(data2),
        borderColor: "rgb(76, 203, 90)",
        backgroundColor: "rgba(76, 203, 90, 0.5)",
      },
    ],
  };

  const stats = [
    {
      id: 1,
      name: t("visits"),
      stat: data1?.numberVisits,
      icon: UsersIcon,
      //   change: "122",
      //   changeType: "increase",
    },
    {
      id: 2,
      name: t("orders"),
      stat: data2?.filter((m) => Number(m.date.slice(5, 7)) === month + 1)
        .length,
      icon: ShoppingBagIcon,
      //   change: "5.4%",
      //   changeType: "increase",
    },
    {
      id: 3,
      name: t("sales"),
      stat: loading === false && totalPrice.toFixed(2) + " " + t("sar"),
      icon: PresentationChartLineIcon,
      // //   change: "3.2%",
      //   changeType: "increase",
    },
    {
      id: 4,
      name: t("profits"),
      stat:
        loading === false && totalPrice - totalProfits - 9.0 > 0
          ? (totalPrice - totalProfits - 9.0).toFixed(2) + " " + t("sar")
          : "0.00 " + t("sar"),
      icon: BanknotesIcon,
      // //   change: "3.2%",
      //   changeType: "increase",
    },
  ];

  return (
    <div className="mx-5 my-5  sm:container sm:mx-auto ">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {t("last30days")}
      </h3>
      <div className="my-5  h-auto">
        <Line options={options} data={datae} />
      </div>

      <dl className="mt-0 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="truncate text-sm font-medium text-gray-500 ltr:ml-16 rtl:mr-16">
                {item.name}
              </p>
            </dt>
            <dd className="flex items-baseline pb-6 ltr:ml-16 rtl:mr-16 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">
                {item.stat}
              </p>
              <p
                className={classNames(
                  item.changeType === "increase"
                    ? "text-green-600"
                    : "text-red-600",
                  "rtl:rl-2 flex items-baseline text-sm font-semibold ltr:ml-2",
                )}
              >
                {/* {item.changeType === "increase" ? (
                  <ArrowUpIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowDownIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )} */}

                {/* <span className="sr-only">
                  {" "}
                  {item.changeType === "increase"
                    ? "Increased"
                    : "Decreased"}{" "}
                  by{" "}
                </span>
                {item.change} */}
              </p>
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  {item.name !== t("visits") ? (
                    <a
                      href="/orders"
                      className="font-medium text-indigo-600 hover:text-indigo-500 focus:underline"
                    >
                      {t("viewAll")}{" "}
                      <span className="sr-only"> {item.name} stats</span>
                    </a>
                  ) : (
                    <label
                      //   href="/orders"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {t("numberOfVisits")}
                      <span className="sr-only"> {item.name} stats</span>
                    </label>
                  )}
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>

      <div className="my-5 overflow-hidden shadow sm:rounded-lg">
        <div className="mt-5 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                الطلبات
              </h1>
            </div>
          </div>
          <div className="mt-2 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="mx-4 inline-block min-w-full py-2 align-middle sm:mx-0 sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300 ">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 text-sm font-semibold text-gray-900 ltr:pl-0 ltr:pr-3 ltr:text-left rtl:pl-3 rtl:pr-0 rtl:text-right sm:pl-0"
                      >
                        رقم الطلب
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-sm font-semibold text-gray-900 ltr:text-left rtl:text-right"
                      >
                        تاريخ الطلب
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-sm font-semibold text-gray-900 ltr:text-left rtl:text-right"
                      >
                        حالة الطلب
                      </th>
                      {/* <th
                      scope="col"
                      className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th> */}
                      <th
                        scope="col"
                        className="relative py-3.5 ltr:pl-3 ltr:pr-4  rtl:pl-4 rtl:pr-3 sm:pr-0"
                      >
                        <span className="sr-only">تفاصيل</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data2
                      ?.filter((o, key) => key < 6)
                      .map((order, key) => (
                        <tr key={key}>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm ltr:pl-0 rtl:pr-0 sm:pl-0">
                            <div className="flex items-center">
                              {/* <div className="h-10 w-10 flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={order.image} alt="" />
                          </div> */}
                              <div className="ltr:ml-0 rtl:mr-0">
                                <div className="font-medium text-gray-900">
                                  {order.itemNo}
                                </div>
                                {/* <div className="text-gray-500">{order.email}</div> */}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              {new Date(order.created_at).getDate()}-
                              {new Date(order.created_at).getMonth()}-
                              {new Date(order.created_at).getFullYear()}
                            </div>
                            <div className="text-gray-500">
                              {/* {order.create_At} */}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span
                              className={classNames(
                                order.isCancel
                                  ? "bg-red-100 text-red-800 "
                                  : order.isShipped &&
                                      order.isRefund === false &&
                                      order.isEndRefund === false
                                    ? "bg-green-100 text-green-800 "
                                    : order.isShipped &&
                                        order.isRefund &&
                                        order.isEndRefund === false
                                      ? "bg-orange-100 text-orange-500"
                                      : order.isEndRefund === true
                                        ? "bg-gray-100 text-gray-500"
                                        : "bg-yellow-100 text-yellow-500",
                                "inline-flex rounded-full  px-2 text-xs font-semibold  leading-5 ",
                              )}
                            >
                              {order.isCancel
                                ? "تم الإلغاء"
                                : order.isShipped &&
                                    order.isRefund === false &&
                                    order.isEndRefund === false
                                  ? "تم الشحن"
                                  : order.isShipped &&
                                      order.isRefund &&
                                      order.isEndRefund === false
                                    ? "جارٍ الإرجاع"
                                    : order.isEndRefund === true
                                      ? "تم الإرجاع"
                                      : "جارٍ التجهيز"}
                            </span>
                          </td>
                          <td className="relative whitespace-nowrap py-4 text-sm font-medium ltr:pl-3 ltr:pr-4 ltr:text-left rtl:pl-4 rtl:pr-3 rtl:text-right sm:pr-0">
                            <button
                              onClick={() =>
                                isNumber(order.itemNo)
                                  ? navigate(
                                      `/order/search?itemNo=${order.itemNo}`,
                                      {
                                        state: {
                                          invoice: order,
                                        },
                                      },
                                    )
                                  : navigate(`/order/${order.itemNo}`, {
                                      state: {
                                        invoice: order,
                                      },
                                    })
                              }
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              تفاصيل
                              <span className="sr-only">, {order.name}</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className=" bg-gray-50 px-4 py-4 sm:px-8">
          <div className="text-sm ">
            <a
              href="/orders"
              className="font-medium text-indigo-600 hover:text-indigo-500 focus:underline	 "
            >
              {t("viewAll")} <span className="sr-only"> stats</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
