import React, { useContext, useEffect, useState } from "react";
import { useAxios } from "../api/api";
import {
  MagnifyingGlassIcon,
  GlobeAltIcon,
  //   ShareIcon,
  //   DocumentDuplicateIcon,
  ClipboardDocumentCheckIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronUpIcon,
  PlusCircleIcon,
  PlusIcon,
  ChevronDownIcon,
  BookmarkIcon,
} from "@heroicons/react/20/solid";
import { Disclosure, Transition, Dialog } from "@headlessui/react";
import {
  ShareIcon,
  DocumentDuplicateIcon,
  ShoppingBagIcon,
  BanknotesIcon,
  PresentationChartLineIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { ChevronRightIcon, MinusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import ForgetPassword from "../components/ForgetPassword";
import AddDomain from "../components/AddDomain";
import AlertBuy from "../components/AlertBuy";
import Notifications from "../components/Notifications";
import { Context } from "../context/ContextProvider";

import AlertShare from "../components/AlertShare";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Domain({ t, i18n }) {
  const { show, setShow } = useContext(Context);

  const navigate = useNavigate();

  const { response, loading, error } = useAxios({
    method: "GET",
    // url: `/companies/get-company-name/ater`,
    url: `/companies/get-company-name/${localStorage.getItem("CN")}`,
    headers: {
      accept: "*/*",
    },
  });
  let [isOpen, setIsOpen] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const handleNotification = () => {
    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 1500);
  };

  //   useEffect(() => {
  //     setTimeout(() => {
  //       console.log(
  //         "data: ",
  //         loading,
  //         error,
  //         response,
  //         typeof localStorage.getItem("CN")
  //       );
  //     }, 5000);
  //   }, []);

  const stats = [
    {
      id: 1,
      name: "دومين مجاني",
      icon: CheckIcon,
      link: response?.url,
      active: response?.active === false,
      expireDate: "ليس له تاريخ انتهاء",
      //   change: "122",
      //   changeType: "increase",
    },
    {
      id: 2,
      name: "دومين خاص",
      icon: CheckIcon,
      link: response?.active
        ? response?.domain?.domainName
        : "https://domain.com",
      active: response?.active === true,
      expireDate: response?.active
        ? new Date(response?.domain?.expireDate)
            .toLocaleDateString()
            .replace(/\//g, "-")
        : "لا يوجد دومين خاص",
    },
  ];

  const questionAboutDomain = [
    {
      id: 1,
      quesiton: " لديك دومين؟",
      answer: "اتبع الخطوات التالية بالضغط على الرابط",
      icon: CheckIcon,
      link: "/domain",
      //   change: "122",
      //   changeType: "increase",
    },
    {
      id: 2,
      quesiton: "ترغب بحجز دومين جديد؟",
      answer:
        "يمكنك ذلك بالضغط على الرابط وسيتم تفعيله بشكل تلقائي على متجرك خلال ٢٤ ساعة",
      icon: CheckIcon,
      link: "/search-domain",

      //   change: "5.4%",
      //   changeType: "increase",
    },
  ];

  const [frequency, setFrequency] = useState(1);
  const [open, setOpen] = useState(false);
  const [alertBuy, setAlertBuy] = useState(false);
  const [alertShare, setAlertShare] = useState(false);
  const [isSend, setIsSend] = useState(false);

  // setTimeout(() => {
  //   navigator.clipboard.writeText("").then(
  //     function () {
  //       /* Successfully cleared clipboard */
  //     },
  //     function () {
  //       /* Failed to clear clipboard */
  //     }
  //   );
  // }, 60000);

  const updateClipboard = (newClip) => {
    navigator.clipboard.writeText(newClip).then(
      () => {
        handleNotification();
      },
      () => {
        console.log("clipboard write failed");
      }
    );
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log("ff: ", response.active &&
  //     Object.keys(response).includes("domain") ==);
  //   }, 5000);
  // }, []);

  return (
    <div className="sm:mx-auto sm:container my-4 mx-5">
      <Notifications title={t("copyUrl")} />

      <AddDomain
        open={open}
        setOpen={setOpen}
        isSend={isSend}
        setIsSend={setIsSend}
        i18n={i18n}
        active={response?.active}
      />
      <AlertBuy
        alertBuy={alertBuy}
        setAlertBuy={setAlertBuy}
        active={response?.active}
        i18n={i18n}
      />
      <AlertShare
        alertShare={alertShare}
        setAlertShare={setAlertShare}
        i18n={i18n}
        shareLink={`رابط متجر ${response?.url} | ${response?.companyName}`}
      />
      {loading ? (
        <div className="flex items-center justify-center w-full py-20">
          <div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
            <svg
              fill="none"
              className="w-20 h-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <>
          {response?.active ? (
            <div className="ltr:border-l-4 rtl:border-r-4 border-green-400 bg-green-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ltr:ml-3 rtl:mr-3">
                  <h3 className="text-xs md:text-md font-medium text-green-800 outline-none">
                    أنت الآن على الباقة المميزة
                  </h3>
                </div>
              </div>
            </div>
          ) : (
            <div className="ltr:border-l-4 rtl:border-r-4 border-yellow-400 bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ltr:ml-3 rtl:mr-3 flex flex-row justify-between w-full">
                  <p className="text-xs md:text-md  text-yellow-700">
                    الدومين الخاص متوفر على باقة الإنطلاقة
                  </p>
                  <a
                    href="/pricing"
                    className="font-medium text-yellow-700 underline hover:text-yellow-600 text-xs md:text-md outline-none"
                  >
                    ترقية الباقة
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="mt-5">
            <div className=" container mx-auto my-5 flex flex-row">
              <button
                type="button"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                className="flex  bg-indigo-500 hover:bg-indigo-700 w-10  h-10 rounded-md drop-shadow-lg  justify-center items-center text-white text-4xl hover:drop-shadow-2xl"
                id="btn-back-to-top"
                onClick={() => {
                  // navigate(-1);
                  navigate("/settings");
                  // scrollTop, clientHeight, scrollHeight
                }}
              >
                <ChevronRightIcon
                  className="ltr:rotate-180  h-6 w-6 "
                  aria-hidden="true"
                />
              </button>
              <p
                htmlFor="url"
                className="block text-md sm:text-xl mx-5 mt-2 sm:mt-1 font-semibold leading-6 text-gray-900"
              >
                إعدادات الدومين
              </p>
            </div>

            {response?.active && response?.domain?.domainName !== undefined ? (
              <div className="relative w-full mt-0 flex rounded-md  overflow-hidden ">
                <div className="absolute inset-y-0 left-2   flex py-2 z-5 ">
                  <GlobeAltIcon
                    className="-ml-0.5 h-5 w-5 text-black"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  id="search"
                  label="url"
                  name="code"
                  value={
                    response?.active
                      ? response?.domain?.domainName
                      : response?.url
                  }
                  disabled={true}
                  autoComplete="code"
                  className="cursor-not-allowed md:cursor-auto pl-7 block w-full text-left rounded-md border-0 border-gray-200 bg-gray-50 py-1.5  rtl:pr-2 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6 outline-none"
                />

                <div className="absolute inset-y-0 ltr:right-0 rtl:right-0  flex py-1.5 z-3 bg-white">
                  <kbd className="inline-flex items-center  font-sans text-xs text-gray-400">
                    <button
                      type="button"
                      className="relative -ml-px inline-flex items-center gap-x-1.5 rtl:rounded-r-md px-3 py-2 text-sm font-semibold text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => setAlertShare(true)}
                    >
                      <ShareIcon
                        className=" h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <p className="hidden sm:block">share</p>
                    </button>
                    <button
                      onClick={() =>
                        updateClipboard(
                          response?.active
                            ? response?.domain?.domainName
                            : response?.url
                        )
                      }
                      type="button"
                      className={classNames(
                        show ? "text-green-600 bg-green-100" : "text-gray-400",
                        "relative -ml-px inline-flex items-center gap-x-1.5 ltr:rounded-r-md px-3 py-2 text-sm font-semibold text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      )}
                    >
                      {show ? (
                        <ClipboardDocumentCheckIcon
                          className=" h-5 w-5 text-green-600 animate-bounce"
                          aria-hidden="true"
                        />
                      ) : (
                        <DocumentDuplicateIcon
                          className=" h-5 w-5 text-gray-400"
                          aria-hidden="true"
                          // onClick={() => copyToClipboard(response.url)}
                        />
                      )}
                      <p className="hidden sm:block">copy</p>
                    </button>
                  </kbd>
                </div>
              </div>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={() => navigate("/search-domain")}
                  // disabled={
                  //   newOject.password.length <= 5 || newOject.email.length === 0
                  // }
                  className={
                    "flex w-full border my-3 justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus:ring-indigo-600  bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 "
                  }
                >
                  {false ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 text-white animate-spin rtl:ml-3 ltr:mr-3"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    "احجز دومين مجاناً"
                  )}
                </button>
              </div>
            )}
          </div>
          <div className="my-4">
            <dl className="mt-0 grid grid-cols-1 gap-5 sm:grid-cols-2 ">
              {stats
                .filter((c) =>
                  response.active && Object.keys(response).includes("domain")
                    ? c
                    : c.id === 1
                )
                .map((item) => (
                  <div
                    key={item.id}
                    className={classNames(
                      item.active
                        ? "border-indigo-600 shadow "
                        : "border-gray-200",
                      "relative overflow-hidden border  rounded-lg bg-white px-4 pb-12 pt-5 sm:px-6 sm:pt-6"
                    )}
                  >
                    <dt className="flex flex-row justify-between">
                      <p className="  text-sm font-bold text-gray-500 w-full  mt-3.5">
                        تاريخ انتهاء الدومين: {item.expireDate}
                      </p>
                      <p className="  text-sm font-bold text-gray-500   mt-3.5">
                        {item.link}
                      </p>
                    </dt>
                    <dd className="ltr:ml-16 rtl:mr-16 flex items-baseline pb-6 sm:pb-7 mt-0">
                      <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                        <div className="text-sm">
                          <div
                            onClick={() =>
                              item.active === false && setAlertBuy(true)
                            }
                            className=" flex flex-row gap-2 w-32 "
                          >
                            <item.icon
                              className={classNames(
                                item.active
                                  ? "text-indigo-600 border-indigo-600"
                                  : "text-gray-50 border-gray-500",
                                "h-5 w-5  border border-gray-500 rounded-md mt-0 "
                              )}
                              aria-hidden="true"
                            />
                            {/* frequency === item.id */}

                            <button
                              className={classNames(
                                item.active
                                  ? "text-indigo-600 "
                                  : "text-gray-500",
                                "font-medium hover:text-indigo-500"
                              )}
                            >
                              {item.name}
                              <span className="sr-only">
                                {" "}
                                {item.name} stats
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </dd>
                  </div>
                ))}
            </dl>
          </div>

          <div className="mt-9">
            <p>خدمات متوفرة في الباقة المميزة:</p>
          </div>

          <div className="rounded-2xl bg-white">
            {questionAboutDomain.map((item, key) => (
              <Disclosure as="div" className="mt-2" key={key}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-gray-100 px-2 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      <div className="flex flex-row gap-1">
                        <BookmarkIcon
                          className={` h-5 w-5 text-gray-800 font-bold origin-center`}
                        />
                        <span>{item.quesiton}</span>
                      </div>

                      <ChevronDownIcon
                        className={`${
                          open
                            ? "transition ease-in duration-300 rotate-180"
                            : "transition ease-in-out duration-300 rotate-0"
                          // open ? "animate-wiggle  rotate-180" : " rotate-0 animate-nonwiggle"
                        } h-5 w-5 text-gray-800 font-bold origin-center`}
                      />
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                        {/* setIsOpen */}
                        {item.answer}{" "}
                        <button
                          className=" underline text-blue-500"
                          onClick={() => {
                            // console.log(
                            //   "jj: ",
                            //   typeof questionAboutDomain[key].id,
                            //   typeof item.id
                            // );
                            questionAboutDomain[key].id === 2
                              ? navigate(item.link, {
                                  state: { response },
                                })
                              : setOpen(true);
                          }}
                        >
                          اضغط هنا
                        </button>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Domain;
