import React, { useRef, useCallback, useState } from "react";
import Paragraph from "@editorjs/paragraph";

// import tools for editor config
import { EDITOR_JS_TOOLS } from "../tools/tools";

// create editor instance
import { createReactEditorJS } from "react-editor-js";
import exampleData from "../data/exampleData";
import i18n from "../i18n/i18n";
function classNames(...classes) {
  return classes?.filter(Boolean).join(" ");
}

const ReactEditorJS = createReactEditorJS();
export default function Editor({ data, setData, readOnly }) {
  // const RTL = i18n.dir();
  const editorCore = useRef(null);
  const handleInitialize = useCallback((instance) => {
    // await instance._editorJS.isReady;
    instance._editorJS.isReady
      .then(() => {
        // set reference to editor
        editorCore.current = instance;
      })
      .catch((err) => console.log("An error occured", err));
  }, []);

  // console.log("i18n.language: ", RTL === "rtl", i18n.language === "ar", RTL);

  const handleSave = useCallback(async () => {
    // retrieve data inserted
    const savedData = await editorCore.current.save();
    // console.log("savedData: " + JSON.stringify(savedData));
    setData(JSON.stringify(savedData));
  }, [setData]);

  return (
    <div
      className={classNames(readOnly ? "" : "mx-20", "text-container -mb-56")}
    >
      <ReactEditorJS
        readOnly={readOnly}
        i18n={{
          direction: i18n.language === "ar" && "rtl",
        }}
        onInitialize={handleInitialize}
        tools={{
          ...EDITOR_JS_TOOLS,
          paragraph: {
            class: Paragraph,
            config: {
              placeholder:
                i18n.language === "ar" && readOnly === false
                  ? "اكتب هنا وصف المنتج..."
                  : i18n.language === "en" && readOnly === false
                    ? "Write product description here..."
                    : "",
            },
          },
        }}
        onChange={handleSave}
        defaultValue={data}
      />
    </div>
  );
}
