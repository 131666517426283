import axios from "axios";
import { useState, useEffect, useRef } from "react";
import jwt_decode from "jwt-decode";

const instance = axios.create({
  // baseURL: "http://192.168.8.101:9000/api/v1",
  // baseURL: "http://localhost:9000/api/v1",
  baseURL: "https://fatora-sa.herokuapp.com/api/v1",
  headers: {
    Authorization: "Bearer " + localStorage.getItem("TokenNAEM"),
    // Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NzkyODU5NTcsInVzZXIiOnsiX2lkIjoiNjQwOWY3Y2M0ZTM5Yzg0MWMzZjAwNjU1IiwiZGlzcGxheU5hbWUiOiJJdWx1IiwiZW1haWwiOiJsdWx1QGdtYWlsLmNvbSIsImFkbWluIjpmYWxzZSwiYWRkcmVzcyI6IiIsImNpdHkiOiIiLCJuZWlnaGJvcmhvb2QiOiIiLCJwaG9uZSI6IiJ9LCJpYXQiOjE2Nzg0MjE5NTd9.EjjZWHRzcKns8d1Pcq45gDIdkeVcUcAg5pKpzarj5pk",
    // Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzk0OTU2NzUsImNvbXBhbnkiOnsiX2lkIjoiNjIxNjBiYzgxYzVjZWVjMTI1NzgwNzk0IiwiY29tcGFueU5hbWUiOiJhdGVyICIsImVtYWlsIjoiYXRlckBnbWFpbC5jb20iLCJhY3RpdmUiOmZhbHNlLCJhZG1pbiI6dHJ1ZSwic3VwZXJBZG1pbiI6ZmFsc2UsImNpdHkiOiJKZWRkYWgiLCJhZGRyZXNzIjoi2KfZhNio2YjYp9iv2YoiLCJwaG9uZSI6IjA1NjExODc4NTYiLCJ1cmwiOiJodHRwczovL2F0ZXItYXBwLndlYi5hcHAiLCJ0YXhOdW1iZXIiOiIifSwiaWF0IjoxNjc4NjMxNjc1fQ.EQHoU8uWIIcDG7stJPIDoi4NLcnW9BfxBbdp5WkhkKA",
  },
});

instance.defaults.headers.post["Content-Type"] = "application/json";

export default instance;

export const useAxios = (axiosParams) => {
  const [response, setResponse] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const oneFetch = useRef(true);

  const fetchData = async (params) => {
    try {
      const result = await instance.request(params);
      setResponse(result.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      // setTimeout(() => {
      // }, 3000);
    }
  };

  useEffect(() => {
    if (oneFetch.current) {
      oneFetch.current = false;
      fetchData(axiosParams);
    }
    // setTimeout(() => {

    // }, 1000);
  }, [loading]); // execute once only

  return { response, error, loading, setLoading };
};

export const useNaemApi = (axiosParams) => {
  const [naemData, setNaemData] = useState(undefined);
  const [errorNaem, setErrorNaem] = useState("");
  const [loadingNaem, setLoadingNaem] = useState(true);
  const oneFetch = useRef(true);

  const fetchData = async (params) => {
    try {
      const result = await instance.request(params);
      setNaemData(result.data);
    } catch (error) {
      setErrorNaem(error);
    } finally {
      setLoadingNaem(false);
    }
  };

  useEffect(() => {
    if (oneFetch.current) {
      oneFetch.current = false;
      fetchData(axiosParams);
    }
    // setTimeout(() => {

    // }, 1000);
  }, []); // execute once only

  return { naemData, errorNaem, loadingNaem };
};

export const useAxiosDublecate = (...args) => {
  const [data1, setData1] = useState(undefined);
  const [data2, setData2] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const oneFetch = useRef(true);
  // console.log("arr: ", args);

  const fetchData = async (params1, params2) => {
    try {
      const result1 = await instance.request(params1);
      setData1(result1.data);

      let updateParam = {
        ...params2,
        url: params2.url + result1.data._id + "" + args[0],
      };
      const result2 = await instance.request(updateParam);
      setData2(result2.data);
      // console.log("data2: ", params2.url + result1.data._id, data2);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (oneFetch.current) {
      oneFetch.current = false;
      fetchData(args[1], args[2]);
    }
  }, []);

  return { data1, data2, error, loading };
};

// export const useAxiosInviceOrder = (...args) => {
//   const [data1, setData1] = useState(undefined);
//   const [data2, setData2] = useState(undefined);
//   const [error, setError] = useState("");
//   const [loading, setLoading] = useState(true);
//   const oneFetch = useRef(true);
//   // console.log("arr: ", args);

//   const fetchData = async (params1, params2) => {
//     try {
//       const result1 = await instance.request(params1);
//       setData1(result1.data[0]);
//       let updateParam = {
//         ...params2,
//         url: params2.url + result1.data[0].orders,
//       };
//       // console.log("params url: ",result1.data[0]);
//       const result2 = await instance.request(updateParam);
//       console.log("params url: ", result2.data[0]);
//       setData2(result2.data[0]);
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (oneFetch.current) {
//       oneFetch.current = false;
//       fetchData(args[0], args[1]);
//     }
//   }, []);

//   return { data1, data2, error, loading };
// };

export const useAxiosInviceOrder = (...args) => {
  const [data1, setData1] = useState(undefined);
  const [data2, setData2] = useState(undefined);
  const [data3, setData3] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const oneFetch = useRef(true);

  const fetchData = async (params1, params2, params3) => {
    try {
      const result1 = await instance.request(params1);
      console.log("d1: ", params1);
      console.log("d1: ", result1.data, result1.data.orders);
      setData1(result1.data);
      let updateParam2 = {
        ...params2,
        url: params2.url + result1.data.orders,
      };

      const result2 = await instance.request(updateParam2);
      // console.log("data: ", result2.data[0].orders.kind);
      setData2(result2.data[0]);

      let updateParam3 = {
        ...params3,
        url: params3.url + result1.data.user_id,
      };

      const result3 = await instance.request(updateParam3);
      // console.log("d3: ", result3.data);
      setData3(result3.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (oneFetch.current) {
      oneFetch.current = false;
      fetchData(args[0], args[1], args[2]);
    }
  }, []);

  return { data1, data2, data3, error, loading };
};

export const useAxiosSeconde = (axiosParams) => {
  const [data, setData] = useState(undefined);
  const [errorSeconde, setErrorSeconde] = useState("");
  const [loadingSeconde, setLoadingSeconde] = useState(true);
  const oneFetch = useRef(true);

  const fetchData = async (params) => {
    try {
      const result = await instance.request(params);
      setData(result.data);
    } catch (error) {
      setErrorSeconde(error);
    } finally {
      setLoadingSeconde(false);
    }
  };

  useEffect(() => {
    if (oneFetch.current) {
      oneFetch.current = false;
      fetchData(axiosParams);
    }
    // setTimeout(() => {

    // }, 1000);
  }, []); // execute once only

  return { data, errorSeconde, loadingSeconde };
};

export const useAxiosThried = (...args) => {
  const [data1, setData1] = useState(undefined);
  const [data2, setData2] = useState(undefined);
  const [data3, setData3] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const oneFetch = useRef(true);
  console.log("arr: ", args);

  const fetchData = async (params1, params2, params3) => {
    try {
      const result1 = await instance.request(params1);
      setData1(result1.data[0]);
      let updateParam = {
        ...params2,
        url: params2.url + result1.data[0].orders,
      };
      const result2 = await instance.request(updateParam);
      console.log("params url: ", result2.data[0]);
      setData2(result2.data[0]);
      let updateParam1 = {
        ...params3,
        url: params3.url + result1.data[0].user_id,
      };
      console.log("params url: ", result1.data[0]);
      const result3 = await instance.request(updateParam1);
      console.log("params jj: ", updateParam1, result3);
      setData3(result3.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (oneFetch.current) {
      oneFetch.current = false;
      fetchData(args[0], args[1], args[2]);
    }
  }, []);

  return { data1, data2, data3, error, loading };
};

export const useAxiosThirdCompany = (...args) => {
  const [data1, setData1] = useState(undefined);
  const [data2, setData2] = useState(undefined);
  const [data3, setData3] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const oneFetch = useRef(true);
  // console.log("arr: ", args);

  const fetchData = async (params1, params2, params3) => {
    try {
      const result1 = await instance.request(params1);
      setData1(result1.data);

      let updateParam = {
        ...params2,
        url: params2.url + result1.data.orders,
      };
      const result2 = await instance.request(updateParam);
      setData2(result2.data[0]);
      let updateParam1 = {
        ...params3,
        url: params3.url + result1.data[0].merchant_id,
      };
      // console.log("params url: ",result1.data[0]);
      const result3 = await instance.request(updateParam1);
      // console.log("result3 url: ", result3.data);

      // console.log("params jj: ", updateParam1);
      setData3(result3.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (oneFetch.current) {
      oneFetch.current = false;
      fetchData(args[0], args[1], args[2]);
    }
  }, []);

  return { data1, data2, data3, error, loading };
};
export const useAxiosThriedSaveDomain = (...args) => {
  const [infoOrder, setInfoOrder] = useState(undefined);
  const [infoDomain, setInfoDomain] = useState(undefined);

  const [errorSaveDomain, setErrorSaveDomain] = useState("");
  const [loadingSaveDomain, setLoadingSaveDomain] = useState(true);
  const oneFetch = useRef(true);
  // console.log("arr: ", args);

  const fetchData = async (params1, params2, params3, params4) => {
    try {
      const result1 = await instance.request(params1);
      setInfoOrder(result1.data[0]);
      let updateParam = {
        ...params2,
        url: params2.url + result1.data[0].orders,
      };
      const result2 = await instance.request(updateParam);
      // console.log("params url: ", result2.data[0]);
      setInfoDomain(result2.data[0]);
      let updateParam1 = {
        ...params3,
        data: {
          domainName: result2?.data[0].orders[0].name,
          purchasePrice: result2?.data[0].orders[0].unit / 3.75,
          // purchasePrice: 12.99,
        },
      };
      // console.log(
      //   "params url 1: ",
      //   result2.data[0].orders[0].name,
      //   updateParam1
      // );
      const result3 = await instance.request(updateParam1);
      // const result3 = await instance.post("/push/buy-domain", {
      //   domainName: result2.data[0].orders[0].name,
      //   purchasePrice: result2.data[0].orders[0].unit / 3.75,
      // });
      // console.log("params jj: ", result3);
      // // setData3(result3.data);
      let updateParam2 = {
        ...params4,
        data: {
          domainName: result3.data.domainName,
          nameservers: result3.data.nameservers,
          locked: result3.data.locked,
          autorenewEnabled: result3.data.autorenewEnabled,
          expireDate: result3.data.expireDate,
          createDate: result3.data.createDate,
          renewalPrice: result3.data.renewalPrice,
          company_id: result1.data[0].merchant_id,
        },
      };

      // console.log("params url 2: ", updateParam2);
      if (result3?.data.message === "Internal Error") {
        console.log("not buy domain error from server");
      } else {
        const result4 = await instance.request(updateParam2);
        // console.log("DATA: ", result4.data);
      }
    } catch (error) {
      console.log("error: ", error.message);
      setErrorSaveDomain(error);
    } finally {
      setLoadingSaveDomain(false);
    }
  };

  useEffect(() => {
    if (oneFetch.current) {
      oneFetch.current = false;
      fetchData(args[0], args[1], args[2], args[3]);
    }
  }, []);

  return { infoOrder, infoDomain, loadingSaveDomain, errorSaveDomain };
};

export const useScript = (resourceUrl) => {
  const [data, setData] = useState();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = resourceUrl;
    script.async = true;
    // script.onload = () => this.scriptLoaded();
    document.body.appendChild(script);
    setData(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl]);

  var settings = {
    Checkout_Method: 1,
    SecureVerification_Method: 1,
    Call_Back: () => {},
    Frame_Id: "The_Frame_Id",
  };

  const payment = window.checkout.ProcessCheckoutPayment(settings);

  return { payment };
};

// import { useEffect, useState } from "react";
export const StringToHtml = () => {
  const [html, setHtml] = useState(`<!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <script
        type="text/javascript"
        src="https://checkout-stg.noonpayments.com/en/scripts/checkout?url=https%3A%2F%2Fcheckout-stg.noonpayments.com%2Fen%2Fdefault%2Findex%3Finfo%3Dn2RUDrYIUbB4a1yNmGhiViTLDHgV1p5%252FQc%252BQ5rqVnOxnIM4pjAaMTCNoRnhhvQ%253D%253D"
      ></script>
      <title>Document</title>
    </head>
    <body>
      <script>
        var settings = {
          Checkout_Method: 1,
          SecureVerification_Method: 1,
          Call_Back: () => {},
          Frame_Id: "The_Frame_Id",
        };
  
        ProcessCheckoutPayment(settings);
      </script>
    </body>
  </html>`);
  // useEffect(() => {
  //   setHtml(`<!DOCTYPE html>
  //   <html lang="en">
  //     <head>
  //       <meta charset="UTF-8" />
  //       <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //       <script
  //         type="text/javascript"
  //         src="https://checkout-stg.noonpayments.com/en/scripts/checkout?url=https%3A%2F%2Fcheckout-stg.noonpayments.com%2Fen%2Fdefault%2Findex%3Finfo%3Dn2RUDrYIUbB4a1yNmGhiViTLDHgV1p5%252FQc%252BQ5rqVnOxnIM4pjAaMTCNoRnhhvQ%253D%253D"
  //       ></script>
  //       <title>Document</title>
  //     </head>
  //     <body>
  //       <script>
  //         var settings = {
  //           Checkout_Method: 1,
  //           SecureVerification_Method: 1,
  //           Call_Back: () => {},
  //           Frame_Id: "The_Frame_Id",
  //         };

  //         ProcessCheckoutPayment(settings);
  //       </script>
  //     </body>
  //   </html>`);
  // }, [html]);
  // return <>{parse(html)}</>;

  console.log("data : ", html);
  return { html };
};
